export const apiEndPoints = {
  session: "/session",
  updateCurrentUser: "/update_current_user",
  // Home
  dashboard: "/dashboard/home",
  // Orders
  orders: {
    root: "orders",
    collect: "orders_for_collect",
    order(id) {
      return `${this.root}/${id}`
    },
    orderForEdit(orderId) {
      return `${this.order(encodeURIComponent(orderId))}/show_for_edit`
    },
    orderItems(orderId, id = "") {
      return `${this.order(orderId)}/order_items/${id}`
    },
    budgetStockVerify(id) {
      return `${this.order(id)}/budget_stock_verify`
    },
    budgetApproval(id) {
      return `${this.order(id)}/budget_approval`
    },
    budgetToOrder(id) {
      return `${this.order(id)}/budget_to_order`
    },
    orderToBudget(id) {
      return `${this.order(id)}/order_to_budget`
    },
    sendOrderEmail(id) {
      return `${this.order(id)}/send_order_email`
    },
  },
  // Proformas
  proformas: {
    root: "proformas",
    proforma(id) {
      return `${this.root}/${id}`
    },
    items(proformaId, id = "") {
      return `${this.proforma(proformaId)}/proforma_items/${id}`
    },
    containers(proformaId) {
      return `${this.root}/${proformaId}/containers`
    },
    copyProforma(id) {
      return `${this.proforma(id)}/copy`
    },
    releaseProforma(id) {
      return `${this.proforma(id)}/proforma_release`
    },
  },
  // Customers
  customers: {
    root: "customers",
    sheet: (id) => `customers/${id}/customer_sheets`,
    attachments: (id) => `customers/${id}/customer_attachments`,
  },
  companyReferences: {
    root: "company_ref_info",
  },
  companyAddress: {
    root: "company_address",
  },
  // CustomerServices
  customerServices: {
    root: "customer_services",
    service(id) {
      return `${this.root}/${id}`
    },
    serviceAtachments(id) {
      return `${this.service(id)}/list_attachments`
    },
    upload(id) {
      return `${this.service(id)}/upload`
    },
    download(attachmentId) {
      return `${this.service(attachmentId)}/download`
    },
  },
  // Bulletins
  bulletins: {
    root: "bulletins",
    attachments(id, attachmentId = "") {
      return `${this.root}/${id}/attachments/${attachmentId}`
    },
    readBulletin(id) {
      return `${this.root}/${id}/read`
    },
    get unreadedBulletins() {
      return `${this.root}/unreaded_bulletins`
    },
  },
  // Miscellaneous
  miscellaneous: {
    root: "miscellaneous",
    get me() {
      return `${this.root}/me`
    },
    get filterCompanies() {
      return `${this.root}/filter_companies`
    },
    get status() {
      return `${this.root}/status`
    },
    get classifications() {
      return `${this.root}/classifications`
    },
    get currentEvent() {
      return `${this.root}/current_event`
    },
    get customers() {
      return `${this.root}/customers`
    },
    get simplifiedCustomers() {
      return `${this.root}/simplified_customers`
    },
    get simplifiedDealers() {
      return `${this.root}/simplified_customers/1`
    },
    get grouts() {
      return `${this.root}/grout`
    },
    get gauges() {
      return `${this.root}/gauge`
    },
    get hues() {
      return `${this.root}/hue`
    },
    get defects() {
      return `${this.root}/defects`
    },
    get companies() {
      return `${this.root}/billing_companies`
    },
    get carriers() {
      return `${this.root}/carriers`
    },
    // get carriersWithOrders() {
    //   return `${this.root}/carriers/1`
    // },
    get bearers() {
      return `${this.root}/bearer`
    },
    get cardCompanies() {
      return `${this.root}/credit_card_companies`
    },
    get cardDueDates() {
      return `${this.root}/credit_card_due_dates`
    },
    paymentConditions(customerId) {
      return `${this.root}/payment_conditions?customer_id=${customerId}`
    },
    priceTables(customerId) {
      return `${this.root}/price_tables?customer_id=${customerId}`
    },
    tableTypes(customer_id) {
      return `${this.root}/table_types?customer_id=${customer_id}`
    },
    get billingTypes() {
      return `${this.root}/billing_types`
    },
    get deliveryWeeks() {
      return `${this.root}/delivery_weeks`
    },
    get customerProducts() {
      return `${this.root}/customer_products`
    },
    get customerProductsExport() {
      return `${this.root}/customer_products_ex`
    },
    get samples() {
      return `${this.root}/samples`
    },
    get customerSamples() {
      return `${this.root}/customer_sample_products`
    },
    get customerServiceProducts() {
      return `${this.root}/customer_service_products`
    },
    get productFormats() {
      return `${this.root}/product_format`
    },
    get productGroups() {
      return `${this.root}/product_group`
    },
    get productRefs() {
      return `${this.root}/product_reference`
    },
    get ratings() {
      return `${this.root}/ratings`
    },
    get comissionDates() {
      return `${this.root}/comission_account_dates`
    },
    get orderMinimumValue() {
      return `${this.root}/order_minimum_value`
    },
    get references() {
      return `${this.root}/company_ref_infos`
    },
    get states() {
      return `${this.root}/states`
    },
    cities(state) {
      return `${this.root}/cities/${state}`
    },
    zipCode(cep) {
      return `${this.root}/zip_code/${cep}`
    },
    availablePalletTags(product) {
      return `${this.root}/available_pallet_tags/${product}`
    },
    // Proforma apiEndPoints
    adminCompany(id) {
      return `${this.root}/company/${id}`
    },
    languages(id) {
      return `${this.root}/language/${id}`
    },
    get currencies() {
      return `${this.root}/currency`
    },
    get freights() {
      return `${this.root}/freight`
    },
    get documentTypes() {
      return `${this.root}/doc_type`
    },
    get transportations() {
      return `${this.root}/transportation`
    },
    get harbours() {
      return `${this.root}/harbour`
    },
  },
  // Reports
  reports: {
    root: "reports",
    get issuedOrders() {
      return `${this.root}/issued_orders`
    },
    get inventory() {
      return `${this.root}/inventory`
    },
    get cancelledOrders() {
      return `${this.root}/cancelled_orders`
    },
    get walletOrders() {
      return `${this.root}/portfolio_orders`
    },
    get customers() {
      return `${this.root}/customer_list`
    },
    get commercialCondition() {
      return `${this.root}/commercial_condition`
    },
    get commissions() {
      return `${this.root}/commissions`
    },
    get duplicatesToReceive() {
      return `${this.root}/duplicates_to_receive`
    },
    get billedOrders() {
      return `${this.root}/billed_orders`
    },
    get currentAccount() {
      return `${this.root}/current_account`
    },
  },
}

export default apiEndPoints
