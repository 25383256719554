import styled from "styled-components"
import MuiTable from "@material-ui/core/Table"

export const StyledTable = styled(MuiTable)`
  .MuiTableCell-root {
    padding: 0.8rem;
    border-bottom-color: ${(props) => props.theme.palette.grey[200]};
  }

  .MuiTableRow-root:last-child .MuiTableCell-root:not(.MuiTableCell-head) {
    border-bottom-color: transparent;
  }

  .MuiTableCell-company {
    white-space: nowrap;
  }

  .MuiTableCell-decimal {
    width: 10%;
  }

  .MuiTableCell-progress-bar {
    width: 20%;
    min-width: 100px;
  }

  // CUSTOM HEAD
  .MuiTableCell-head {
    white-space: nowrap;
    font-weight: bold;
    color: ${(props) => props.theme.palette.text.primary};
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) => props.theme.palette.grey[200]};

    &.primary {
      color: ${(props) => props.theme.palette.primary.main};
      border-bottom-color: ${(props) => props.theme.palette.primary.main};
    }

    &.info {
      color: ${(props) => props.theme.palette.info.main};
      border-bottom-color: ${(props) => props.theme.palette.info.main};
    }

    &.success {
      color: ${(props) => props.theme.palette.success.main};
      border-bottom-color: ${(props) => props.theme.palette.success.main};
    }

    &.error {
      color: ${(props) => props.theme.palette.error.main};
      border-bottom-color: ${(props) => props.theme.palette.error.main};
    }
  }
`
