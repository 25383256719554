import React from "react"
import PropTypes from "prop-types"

export default function Logo({ svg, ...props }) {
  return (
    <div {...props}>
      <img
        src={svg ? "/assets/logo-empresa.svg" : "/assets/logo-empresa.svg"}
        alt="Savane"
        width={150}
      />
    </div>
  )
}

Logo.propTypes = {
  svg: PropTypes.bool,
}

Logo.defaultProps = {
  svg: true,
}
