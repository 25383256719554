export const EXTRACT_URL_PARAMS_REGEXP = /:[a-zA-Z?]+/

export const email = {
  get withoutUnicode() {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },
  get withUnicode() {
    return /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
  },
}

export const placa = {
  get array() {
    return [/[A-Z]/i, /[A-Z]/i, /[A-Z]/i, "-", /\d/, /[A-Z\d]/i, /\d/, /\d/]
  },
  get regex() {
    return /[A-Z][A-Z][A-Z]-\d[A-Z\d]\d\d/
  },
}

export const cnpj = {
  get array() {
    return [
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
    ]
  },
  get regex() {
    return /\d\d.\d\d\d.\d\d\d\/\d\d\d\d-\d\d/
  },
}

export const cpf = {
  get array() {
    return [
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
    ]
  },
  get regex() {
    return /\d\d\d.\d\d\d.\d\d\d-\d\d/
  },
}

export const cep = {
  get array() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]
  },
  get regexp() {
    return /\d\d\d\d\d-\d\d\d/
  },
}

export const telefoneFixo = {
  get array() {
    return [
      "(",
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  },
}

export const telefoneCelular = {
  get array() {
    return [
      "(",
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  },
}

export const pedido = {
  // 000203-21A
  get array() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /[A-Z]/]
  },
  get regex() {
    return /^\d{6}-\d{2}[A-Z]$/
  },
}

export const numeroCartao = {
  get array() {
    return [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  },
}
