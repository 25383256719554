import React, { useRef, useState, useCallback } from "react"
import { toast } from "react-toastify"

import { Box } from "@material-ui/core"
import { ChevronLeft, ChevronRight, Save } from "@material-ui/icons"

import api from "@/services/api"
import apiEndPoints from "@/consts/apiEndPoints"
import { unmask } from "@/services/masks"
import y, { handleValidationError } from "@/services/yup"
import { transposeToList } from "@/services/utils"

import { Tabs } from "@/components/Tabs"
import { Form, FormTab } from "@/components/Form"
import Button from "@/components/Button"

import { TabCustomer } from "./tabs/Customer"
import { TabAddress } from "./tabs/Address"
import { TabCompany } from "./tabs/Company"
import { TabSheet } from "./tabs/Sheet"
import { TabAttachments } from "./tabs/Attachments"

import { NewCustomerFormContainer } from "./styles"
import { confirmation } from "@/components/Confirmation"
import { uploadCustomerAttachments } from "@/api/customers"
import { LOADER_TYPES, Loader } from "./loader"

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const sheetInitialData = {
  predio_proprio: "2",
  tempo_ramo: "1",
  localizacao: "1",
  outras_linhas: "1",
  disp_apres: "1",
  limit_credito: "",
}

const initialData = {
  cod_empresa: "",
  emp_estadual_rg: "",
  cli_classific_cliente: "",
  dsc_empresa: "",
  emp_razao_social: "",
  emp_home_page: "",
  emp_telefone: "",
  emp_telefone2: "",
  emp_e_mail: "",
  cli_email: "",
  cli_email_cob: "",
  cli_consumidor: false,
  cli_nao_contribuinte: false,
  cli_reg_icms_st: false,
  commercial_brands: [],

  emp_cep: "",
  dsc_uf: "",
  emp_cidade: "",
  emp_bairro: "",
  emp_endereco: "",
  emp_compl_endereco: "",
  emp_endereco_num: "",

  corporate_structure: null,
  network_companies: null,
  banks_refs: null,
  commercial_refs: null,
  attachments: null,

  sheet: sheetInitialData,
}

const tabs = [
  { label: "Dados cadastrais" },
  { label: "Endereço" },
  { label: "Empresa" },
  { label: "Ficha Cadastral" },
  { label: "Anexos" },
]

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const networkCompanyScheme = y.object({
  cod_coligada: y.string().required(),
  dsc_empresa_col: y.string().required(),
})

const corporateStructureScheme = y.object({
  soc_cpf: y.string().required(),
  soc_nome: y.string().required(),
  soc_quota: y.string().required(),
})

const bankRefScheme = y.object({
  dsc_inf_referencia_banc: y.string().required(),
  eir_contato_banc: y.string().required(),
  eir_fone_banc: y.string().required(),
})

const commercialRefScheme = y.object({
  dsc_inf_referencia: y.string().required(),
  eir_dsc_uf: y.string().required(),
  eir_dsc_cidade: y.string().required(),
  eir_fone: y.string().required(),
})

const newCustomerFormValidationScheme = y.object({
  cod_empresa: y.string().required(),
  emp_estadual_rg: y.string().required(),
  cli_classific_cliente: y.string().required(),
  dsc_empresa: y.string().min(3).required(),
  emp_razao_social: y.string().min(3).required(),
  emp_home_page: y.string(),
  emp_telefone: y.string().required(),
  emp_telefone2: y.string().required(),
  emp_e_mail: y.string().email().required(),
  cli_email: y.string().email().required(),
  cli_email_cob: y.string().email().required(),
  cli_consumidor: y.boolean(),
  cli_nao_contribuinte: y.boolean(),
  cli_reg_icms_st: y.boolean(),
  commercial_brands: y.array().min(1, "Selecione uma marca").required(),

  emp_cep: y.string().required(),
  dsc_uf: y.string().required(),
  emp_cidade: y.string().required(),
  emp_bairro: y.string().required(),
  emp_endereco: y.string().required(),
  emp_compl_endereco: y.string(),
  emp_endereco_num: y.string().required(),

  network_companies: y.array().of(networkCompanyScheme),
  corporate_structure: y
    .array()
    .of(corporateStructureScheme)
    .min(1, "Preencha o quadro societário")
    .required(),
  banks_refs: y
    .array()
    .of(bankRefScheme)
    .min(1, "Preencha ao menos 1 referência bancária")
    .required(),
  commercial_refs: y
    .array()
    .of(commercialRefScheme)
    .min(3, "Preencha ao menos 3 referências comerciais")
    .required(),

  attachments: y.array().nullable(),
})

// --------------------------------------------

export default function NewCustomerForm() {
  const [currentTab, setCurrentTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingType, setLoadingType] = useState(LOADER_TYPES.save)

  const formRef = useRef(null)
  const [attachments, setAttachments] = useState([])

  const _createCustomer = useCallback(async (data) => {
    const {
      sheet,
      banks_refs: bankRefs,
      commercial_refs: commercialRefs,
      attachments,
      ...createCustomerRequestBody
    } = data

    const createSheetRequestBody = {
      ...sheet,
      banks_refs: bankRefs,
      commercial_refs: commercialRefs,
    }

    // Saving customer...
    setLoadingType(LOADER_TYPES.save)
    const response = await api.post(apiEndPoints.customers.root, {
      customer: createCustomerRequestBody,
    })

    const customer = response.data

    // Saving customer sheet...
    setLoadingType(LOADER_TYPES.send)
    await api.post(apiEndPoints.customers.sheet(customer.id), {
      customer_sheet: createSheetRequestBody,
    })

    // Saving attachments...
    setLoadingType(LOADER_TYPES.upload)
    await uploadCustomerAttachments(customer.id, attachments)
  }, [])

  const handlePrevious = useCallback(() => {
    setCurrentTab((previousTab) => previousTab - 1)
  }, [])

  const handleNext = useCallback(() => {
    setCurrentTab((previousTab) => previousTab + 1)
  }, [])

  const resetForm = useCallback(() => {
    formRef.current.reset()
    setCurrentTab(0)
    setAttachments([])
  }, [formRef])

  async function handleSubmit(data) {
    formRef.current.setErrors({})

    try {
      const requestBody = {
        ...data,
        cod_empresa: unmask(data.cod_empresa),
        emp_telefone: unmask(data.emp_telefone),
        emp_telefone2: unmask(data.emp_telefone2),
        emp_cep: unmask(data.emp_cep),
        corporate_structure: transposeToList(data.corporate_structure),
        network_companies: transposeToList(data.network_companies),
        banks_refs: transposeToList(data.banks_refs),
        commercial_refs: transposeToList(data.commercial_refs),
      }

      await newCustomerFormValidationScheme.validate(requestBody, {
        abortEarly: false,
      })

      const confirm = await confirmation.open(
        "Confirmação",
        "Confirmar cadastro de novo cliente?"
      )

      if (confirm) {
        setLoading(true)

        await _createCustomer(requestBody)
        toast.success("Cliente cadastrado com sucesso.")

        resetForm()
      }
    } catch (error) {
      const validationError = handleValidationError(formRef.current)
      validationError(error)
    } finally {
      setLoading(false)
    }
  }

  const showConfirmButton = currentTab === tabs.length - 1
  const showNextButton = !showConfirmButton
  const disablePreviousButton = currentTab === 0

  return (
    <NewCustomerFormContainer>
      <Form formRef={formRef} onSubmit={handleSubmit} initialData={initialData}>
        <Tabs
          TabComponent={FormTab}
          onChange={(_, newTab) => setCurrentTab(newTab)}
          value={currentTab}
          tabs={tabs}
        />

        <TabCustomer currentTab={currentTab} />

        <TabAddress currentTab={currentTab} />

        <TabCompany currentTab={currentTab} />

        <TabSheet currentTab={currentTab} />

        <TabAttachments
          currentTab={currentTab}
          attachments={attachments}
          setAttachments={setAttachments}
        />

        <Box display="flex" justifyContent="space-between" marginTop="1rem">
          <Button
            disabled={disablePreviousButton}
            onClick={handlePrevious}
            startIcon={<ChevronLeft />}
            size="medium"
            color="primary"
            type="button"
          >
            Voltar
          </Button>

          {showNextButton && (
            <Button
              onClick={handleNext}
              endIcon={<ChevronRight />}
              size="medium"
              color="primary"
              variant="outlined"
              type="button"
            >
              Próximo
            </Button>
          )}

          {showConfirmButton && (
            <Button
              endIcon={<Save />}
              size="medium"
              color="primary"
              variant="contained"
              type="submit"
              isLoading={loading}
            >
              Cadastrar
            </Button>
          )}
        </Box>
      </Form>

      <Loader loading={loading} type={loadingType} />
    </NewCustomerFormContainer>
  )
}
