import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import { extractLens, format } from "../../../services/utils"
import Checkbox from "@material-ui/core/Checkbox"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import Collapse from "@material-ui/core/Collapse"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"

import { StyledTableCell, StyledTableRow } from "../styles"

// ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
// ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const renderAction = (actionProps) => {
  const disabled = actionProps.getDisabled?.(actionProps.row)

  if (actionProps.content) {
    return actionProps.content({
      rowData: actionProps.row,
    })
  } else if (actionProps.icon) {
    const button = (
      <IconButton
        size={actionProps.size}
        disabled={disabled}
        onClick={(e) => {
          actionProps.onClick?.(actionProps.row, e)
        }}
      >
        {typeof actionProps.icon === "function"
          ? actionProps.icon(actionProps.row)
          : actionProps.icon}
      </IconButton>
    )

    // Fixes => MUI: You are providing a disabled `button` child to the Tooltip
    return disabled ? (
      button
    ) : (
      <Tooltip
        arrow
        title={
          <>
            {typeof actionProps.title === "function"
              ? actionProps.title(actionProps.row)
              : actionProps.title}
          </>
        }
      >
        {button}
      </Tooltip>
    )
  } else return null
}

export default function Row({
  data: row,
  columns,
  options,
  rowStyle,
  cellStyle,
  detailPanel,
  getShowDetailPanel,
  hover,
  actions,
  isMobile,
  onSelectRow,
  onOpenMenu,
  selected,
  size,
  ...props
}) {
  const [collapsed, setCollapsed] = useState(false)
  const isDetailed = detailPanel !== undefined

  const handleOpen = () => setCollapsed(!collapsed)

  const displayCells = () => {
    const isSelected = (row) => selected.indexOf(row) !== -1

    // =======================
    // ==== DEFAULT CELLS ====
    // =======================

    let cells = columns.map((column, cellIndex) => {
      const value = extractLens(column.field, row)
      const cellValue = column.type ? format(column.type, value) : value

      const content = column.render
        ? column.render({
            rowData: row,
            cellValue,
          })
        : cellValue

      return (
        <StyledTableCell
          role={column.role}
          align={column.align}
          key={cellIndex}
          style={cellStyle?.({ row, column })}
        >
          {content}
        </StyledTableCell>
      )
    })

    // =========================
    // ==== TABLE ACTIONS ====
    // =========================

    let cellActions =
      actions?.map((action, actionIndex) => {
        return (
          <StyledTableCell
            key={"action" + actionIndex}
            role="action"
            align="center"
          >
            {renderAction({
              ...action,
              row,
              size: action.size || size,
            })}
          </StyledTableCell>
        )
      }) || []

    // When is mobile, add a more button
    if (isMobile && actions) {
      cellActions = (
        <StyledTableCell key="action-more-vert" role="action" align="center">
          <IconButton size="small" onClick={(event) => onOpenMenu(event, row)}>
            <MoreVertIcon />
          </IconButton>
        </StyledTableCell>
      )
    }

    // =========================
    // ==== TABLE SELECTION ====
    // =========================

    if (options.selection) {
      const checked = isSelected(row)

      const showSelectRow = options.selectRow ? options.selectRow(row) : true

      cells.unshift(
        <StyledTableCell padding="checkbox" role="checkbox" key="checkbox">
          {showSelectRow && (
            <Checkbox
              color="primary"
              onChange={(event) => onSelectRow(event, row)}
              checked={checked}
              disabled={!showSelectRow}
            />
          )}
        </StyledTableCell>
      )
    }

    // ======================
    // ==== TABLE DETAIL ====
    // ======================

    if (isDetailed) {
      cells.unshift(
        <StyledTableCell key="cell-detail" className="MuiTableCell-detail">
          {getShowDetailPanel(row) && (
            <IconButton
              aria-label="expand row"
              size={size}
              onClick={handleOpen}
            >
              {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </StyledTableCell>
      )
    }

    cells = cells.concat(cellActions)
    return cells
  }

  const getColSpan = () => {
    const actionsSize = actions?.length || 0
    let colSpan = columns.length + actionsSize

    if (options.selection) {
      colSpan++
    }

    if (isDetailed) {
      colSpan++
    }

    return colSpan
  }

  const detail = isDetailed
    ? detailPanel({
        row: row,
        onSelect: onSelectRow,
        selected,
        options,
      })
    : null

  return (
    <React.Fragment {...props}>
      <StyledTableRow
        hover={hover}
        className={clsx({
          "TableRow-group": row.isGroupRow,
          "TableRow-subTotal": row.isSubTotalRow,
        })}
        style={rowStyle?.(row)}
      >
        {displayCells()}
      </StyledTableRow>
      {detail && (
        <StyledTableRow tabIndex={-1}>
          <StyledTableCell style={{ padding: 0 }} colSpan={getColSpan()}>
            <Collapse in={collapsed} timeout="auto" unmountOnExit>
              {detail}
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </React.Fragment>
  )
}

Row.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  columns: PropTypes.array,
  actions: PropTypes.array,
  selected: PropTypes.array,
  rowStyle: PropTypes.func,
  cellStyle: PropTypes.func,
  detailPanel: PropTypes.func,
  isMobile: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onOpenMenu: PropTypes.func,
  getShowDetailPanel: PropTypes.func,
}

Row.defaultProps = {
  getShowDetailPanel: () => true,
}
