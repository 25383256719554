import React from "react"
import PropTypes from "prop-types"
import TablePagination from "@material-ui/core/TablePagination"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useTheme from "@material-ui/styles/useTheme"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100]

export default function Pagination(props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { page, rowsPerPage, count, onPageChange, onRowsPerPageChange } = props

  return (
    <TablePagination
      size={props.size}
      component="div"
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      labelRowsPerPage={isMobile ? "R/P" : "Registros por página"}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      nextIconButtonText="Próxima página"
      backIconButtonText="Página anterior"
    />
  )
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
}

Pagination.defaultProps = {
  size: "small",
}
