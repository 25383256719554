import React from "react"
import PropTypes from "prop-types"
import { SUPPORTED_FILE_TYPES, formatFileSize } from "@/services/file"

import UploadIcon from "@material-ui/icons/CloudUpload"
import { Container, StyledInputLabel, UploadButton } from "./styles"

export default function FileInput({
  name,
  label,
  // file,
  multiple,
  onChange,
  ...props
}) {
  const read = (file) => {
    const readedFile = new Promise((response) => {
      const fileReader = new FileReader()

      fileReader.addEventListener("load", () => {
        response({
          id: crypto.randomUUID(),
          fileName: file.name,
          size: file.size,
          type: file.type,
          fileData: fileReader.result,
          formattedSize: formatFileSize(file.size),
          //
          file,
        })
      })

      fileReader.readAsDataURL(file)
    })

    return readedFile
  }

  const handleChange = async (e) => {
    const files = e.target.files

    const readedFiles = await Promise.all([...files].map((file) => read(file)))

    onChange({
      target: { name, files, readedFiles },
    })
  }

  return (
    <Container {...props}>
      <StyledInputLabel htmlFor={name}>
        <UploadButton
          variant="outlined"
          className="MuiButtonBase-root MuiButton-root MuiButton-outlined"
        >
          <span className="MuiButton-label">
            <span className="MuiButton-startIcon MuiButton-iconSizeLarge">
              <UploadIcon />
            </span>
            {label}
          </span>
          <input
            type="file"
            id={name}
            multiple={multiple}
            accept={String(SUPPORTED_FILE_TYPES)}
            onChange={handleChange}
          />
        </UploadButton>
      </StyledInputLabel>
    </Container>
  )
}

FileInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
}

FileInput.defaultProps = {
  multiple: true,
}
