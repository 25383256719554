import { groupWith, prop } from "ramda"
import { copyObject } from "./utils"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

export const DEFAULT_SUB_TOTAL_LABEL = "SUB:"
export const DEFAULT_TOTAL_LABEL = "TOTAL:"

/**
 *
 * @param {string} field
 * @param {(undefined | (provided: unknown) => string)} getValue
 */
export const createGroupLabel = (field, labelColumnField, getValue) => ({
  field,
  getValue: (provided) =>
    getValue ? getValue(provided) : { [labelColumnField]: provided.label },
})

export const extractSubTotals = (
  fields,
  group,
  field,
  label = DEFAULT_SUB_TOTAL_LABEL
) =>
  fields.reduce(
    (subTotalRow, subTotalCell) => {
      subTotalRow[subTotalCell.field] = subTotalCell.getValue({
        group,
      })

      return subTotalRow
    },
    {
      isSubTotalRow: true,
      [field]: label,
    }
  )

export function createTableData(
  data,
  labelColumnField,
  { subGroup, subTotal } = {}
) {
  // 1. Copy object
  let rows = [...data.map(copyObject)]

  if (subGroup) {
    const groupProp = prop(subGroup.field)

    const groups = groupWith(
      (a, b) => groupProp(a) === groupProp(b),
      rows.sort((a, b) => groupProp(a)?.localeCompare(groupProp(b)))
    )

    groups.forEach((group) => {
      group.unshift({
        isGroupRow: true,
        ...subGroup.getValue({
          label: group ? group[0][subGroup.field] : "",
          group: { ...group },
          labelColumnField,
        }),
      })
    })

    const rowsWithGroups = groups.flat()
    rows = rowsWithGroups
  }

  if (subTotal) {
    const byProp = prop(subTotal.by)
    const groups = groupWith((a, b) => byProp(a) === byProp(b), rows)

    const groupsWithTotal = groups.flatMap((group) => {
      const isGroupRow = group.length === 1 && group[0].isGroupRow

      if (isGroupRow) return group
      else
        return group.concat(
          extractSubTotals(subTotal.fields, group, labelColumnField)
        )
    })

    rows = groupsWithTotal
  }

  return rows
}

export const getGroupValue =
  (prop) =>
  ({ group }) =>
    group[0]?.[prop]

export const getMonthAndYear = (date) => {
  return [date.getMonth(), date.getFullYear()]
}

export const getMonthDays = (month, year) => {
  const date = new Date(year, month, 0)
  return date.getDate()
}

export const getTimeCourse = (date) => {
  const [_, month, year] = date.split("/")
  const monthDays = getMonthDays(month, year)

  const intial = ["01", month, year].join("/")
  const final = [monthDays, month, year].join("/")

  return [intial, final]
}

export const sliceColumns = (arr, fields) =>
  arr.filter((a) => !fields.includes(a.field))
