import React, { useMemo } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { copyObject } from "../../../services/utils"
import { formatter } from "../../../services/formatter"

import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import Print from "@material-ui/icons/Print"
import DoubleArrow from "@material-ui/icons/DoubleArrow"

import {
  Form,
  FormDatePicker,
  FormCheckBox,
  FormTextField,
  FormRadioGroup,
} from "../../Form"
import { Container } from "./styles"
import Button from "../../Button"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const MAX_GRID_SIZE = 12
const MIN_BUTTON_SIZE = 3

/**
 * Component used to create dynamic report filters
 *
 * Field
 * @typedef {{
 *     name: Required<string>
 *     label?: string
 *     type?: 'date' | 'checkbox' | 'radio' | 'number'
 *     variant?: 'outlined' | 'filled' | 'standard'
 *     size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto'
 *     defaultValue: Required<any>
 *     component: (props:any) => JSX.Element
 *     componentProps: any
 * }} Field
 *
 * @param {{
 *      onSubmit: (values: object) => void
 *      schema: AnyObjectSchema
 *      fields: Field[]
 * }} props
 * @returns {JSX.Element}
 */
export default function ReportFilter({ fields, onSubmit, ...props }) {
  // ==== HOOKS ====
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const initialData = useMemo(() => {
    return fields.reduce((acc, field) => {
      acc[field.name] = field.defaultValue
      return acc
    }, {})
  }, [fields])

  const renderFields = () =>
    fields.map((fieldProps, index) => {
      return (
        <Grid item xs={12} md={fieldProps.size} key={index}>
          <FormComponent {...fieldProps} />
        </Grid>
      )
    })

  const getButtonSpacing = () => {
    const sizes = fields.reduce((acc, field) => {
      acc = acc + field.size || MAX_GRID_SIZE
      return acc
    }, 0)

    const rest = sizes % MAX_GRID_SIZE
    let buttonSize = MAX_GRID_SIZE - rest
    buttonSize = buttonSize <= MIN_BUTTON_SIZE ? MIN_BUTTON_SIZE : buttonSize

    return buttonSize || "auto"
  }

  // ==== HANDLERS ====

  const handleSubmit = (values) => {
    const params = copyObject(values)

    // Fields auto-formating
    fields.forEach((field) => {
      if ([undefined, null, ""].includes(params[field.name])) {
        params[field.name] = initialData[field.name]
      }

      switch (field.type) {
        case "date":
          params[field.name] = formatter(params[field.name]).toSimpleDate()
          break
        case "checkbox":
          params[field.name] = Number(params[field.name])
          break
        default:
          break
      }
    })

    onSubmit(params)
  }

  const handlePrint = () => {
    window.print()
  }

  const buttonSpacing = getButtonSpacing()

  return (
    <Container>
      <Form initialData={initialData} onSubmit={handleSubmit} {...props}>
        <Grid container spacing={2} alignItems="center">
          {renderFields()}
          <Grid item xs={12} xl={buttonSpacing}>
            <div
              className={clsx("d-flex", {
                "justify-content-end": !xs,
                "justify-content-center": xs,
              })}
            >
              <div className="d-flex gap-2">
                <Button
                  type="submit"
                  color="primary"
                  size="medium"
                  variant="outlined"
                  startIcon={<DoubleArrow />}
                  fullWidth={xs}
                >
                  Gerar Relatório
                </Button>
                {!xs && (
                  <Button
                    type="button"
                    color="primary"
                    size="medium"
                    variant="contained"
                    startIcon={<Print />}
                    onClick={handlePrint}
                  >
                    Imprimir
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </Container>
  )
}

/**
 *
 * @param {Field} field
 */
const FormComponent = (field) => {
  let Component = null
  let props = { ...field.componentProps }
  props.fullWidth = true

  switch (field.type) {
    case "date":
      Component = FormDatePicker
      break
    case "checkbox":
      delete props.fullWidth
      Component = FormCheckBox
      break
    case "radio":
      delete props.fullWidth
      Component = FormRadioGroup
      break
    default:
      Component = FormTextField
      break
  }

  if (field.component) Component = field.component

  return (
    <Component
      name={field.name}
      label={field.label}
      variant={field.variant}
      {...props}
    />
  )
}

ReportFilter.propTypes = {
  schema: PropTypes.object, // Yup validation schema
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
      type: PropTypes.oneOf(["date", "checkbox", "radio", "number"]),
      size: PropTypes.number,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    })
  ),
}

ReportFilter.defaultProps = {
  fields: [],
}
