import styled from "styled-components"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Badge from "@material-ui/core/Badge"
import Panel from "../Panel"

export const StyledTabPanel = styled(Panel)`
  margin-top: 1rem;
  padding: 1rem;
  max-height: 380px;
  min-height: 200px;

  @media (max-width: 600px) {
    padding: 0;
    margin-top: 0.5rem;
  }
`

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};

  @media (max-width: 600px) {
    button.MuiTab-root {
      flex-grow: 1;
    }
  }
`

export const StyledTab = styled(Tab)`
  span {
    font-weight: bold;
  }

  &.error span {
    color: ${(props) => props.theme.palette.error.main};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 0.7rem;
  }

  @media (max-width: 600px) {
    button.MuiTab-root {
      flex-grow: 1;
    }
  }
`

export const StyledBadge = styled(Badge)``
