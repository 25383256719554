import React, { useRef, useState } from "react"
import { toast } from "react-toastify"

import apiEndPoints from "../../../../consts/apiEndPoints"
import api from "../../../../services/api"
import { Yup } from "../../../../services/yup"
import formatter from "../../../../services/formatter"
import { cep } from "../../../../consts/regexp"
import { toMask } from "../../../../services/masks"
import { handleUnprocessableEntity } from "../../../../services/utils"

import Grid from "@material-ui/core/Grid"

import { Form, FormTextField } from "../../../../components/Form"
import Button from "../../../../components/Button"
import ComboboxCustomers from "../../../../components/Combobox/Customers"
import ComboboxStates from "../../../../components/Combobox/States"
import ComboboxCities from "../../../../components/Combobox/Cities"

const schema = Yup.object().shape({
  emp_uf: Yup.string().required("Informe o Estado"),
  emp_cidade: Yup.string().required("Informe a Cidade"),
})

export default function BillingAddress() {
  const formRef = useRef(null)

  const [currentState, setCurrentState] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleBlurCep = async (event) => {
    const cep = event.target.value
    formRef.current.setErrors({})

    if (cep.trim().length === 9) {
      try {
        const response = await api.get(apiEndPoints.miscellaneous.zipCode(cep))

        const address = response.data
        setCurrentState(address.dsc_uf)

        formRef.current.setData({
          emp_uf: address.dsc_uf,
          emp_cidade: address.dsc_cidade?.trim(),
          emp_bairro: address.cep_bairro?.trim(),
          emp_endereco: address.cep_endereco?.trim(),
        })
      } catch (error) {
        const errors = handleUnprocessableEntity(error)
        formRef.current.setErrors(errors)
      }
    } else {
      setCurrentState(null)
      formRef.current.setData({
        emp_uf: null,
        emp_cidade: null,
        emp_bairro: null,
        emp_endereco: null,
        emp_endereco_num: null,
      })
    }
  }

  const handleChangeState = (_, newState) => {
    const state = newState?.value
    setCurrentState(state)
  }

  const handleLoadCustomerOption = (customer) => {
    const formattedId = formatter(customer.id).toCNPJorCPF()

    return {
      ...customer,
      value: customer.id,
      label: [customer.emp_razao_social, formattedId].join(" - "),
    }
  }

  const handleSubmit = async (values) => {
    setIsLoading(true)

    try {
      await api.post(apiEndPoints.companyAddress.root, {
        company_address: { ...values },
      })

      toast.success("Local de entrega cadastrado com sucesso")
      formRef.current.reset()
    } catch (error) {
      const errors = handleUnprocessableEntity(error)
      formRef.current.setErrors(errors)
    } finally {
      setCurrentState(null)
      setIsLoading(false)
    }
  }

  return (
    <Form
      formRef={formRef}
      onSubmit={handleSubmit}
      schema={schema}
      style={{ minHeight: "481px" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ComboboxCustomers
            fullWidth
            required
            name="customer.id"
            label="Cliente"
            variant="outlined"
            onLoadOptions={handleLoadCustomerOption}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormTextField
            fullWidth
            required
            name="emp_cep"
            label="CEP"
            variant="outlined"
            textMaskProps={toMask(cep.array)}
            onBlur={handleBlurCep}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ComboboxStates
            fullWidth
            required
            name="emp_uf"
            label="UF"
            variant="outlined"
            disabled
            onChange={handleChangeState}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <ComboboxCities
            fullWidth
            required
            name="emp_cidade"
            label="Cidade"
            variant="outlined"
            disabled
            state={currentState}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            fullWidth
            required
            name="emp_bairro"
            label="Bairro"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            fullWidth
            name="emp_compl_endereco"
            label="Complemento"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <FormTextField
            fullWidth
            required
            name="emp_endereco"
            label="Endereço"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormTextField
            fullWidth
            required
            name="emp_endereco_num"
            label="Nº"
            variant="outlined"
            inputMode="numeric"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            isLoading={isLoading}
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}
