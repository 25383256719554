import React from "react"
import PropTypes from "prop-types"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Checkbox, TextField, useTheme, Popper } from "@material-ui/core"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"

const DEFAULT_COLOR = "#202020"
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export function MultipleCombobox({
  value,
  options,
  name,
  onChange,
  onSearch,
  label,
  InputLabelProps,
  error,
  helperText,
  ...props
}) {
  const theme = useTheme()
  const popperZIndex = theme.zIndex.modal + 10000

  const handleChange = (_event, options) => {
    onChange({
      target: {
        name,
        value: options.map((option) => option.value || option),
      },
    })
  }

  const getOptionLabel = (option) => {
    switch (typeof option) {
      case "string":
        return option
      case "object":
      default:
        return option.label ?? option.value.toString()
    }
  }

  return (
    <Autocomplete
      PopperComponent={(props) => (
        <Popper
          {...props}
          style={{
            ...props.style,
            zIndex: popperZIndex,
          }}
        />
      )}
      multiple
      options={options}
      disableCloseOnSelect
      noOptionsText="Nenhuma opção encontrada."
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <span style={{ color: option.color ?? DEFAULT_COLOR }}>
            {option.label}
          </span>
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={props.variant}
          InputLabelProps={InputLabelProps}
          error={error}
          helperText={helperText}
        />
      )}
      {...props}
    />
  )
}

MultipleCombobox.propTypes = {
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onSearch: PropTypes.func,
}

export default MultipleCombobox
