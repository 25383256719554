import React from "react"
import PropTypes from "prop-types"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"

export default function Edit({ isEditable, ...props }) {
  return isEditable ? <EditIcon {...props} /> : <VisibilityIcon {...props} />
}

Edit.propTypes = {
  isEditable: PropTypes.bool.isRequired,
}

Edit.defaultProps = {
  isEditable: true,
}
