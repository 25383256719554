import { useEffect } from "react"

import client from "../../services/eventEmitter"
import { events } from "../../consts/events"

import { useAuth } from "../../contexts/Auth"

export default function SignOutChecker() {
  const { signOut } = useAuth()

  useEffect(() => {
    client.on(events.signout, signOut)

    return () => {
      client.removeEventListener(events.signout, signOut)
    }
  }, [signOut])

  return null
}
