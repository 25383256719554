import React from "react"
import { AuthProvider } from "./Auth"
import { DrawerStateProvider } from "./Drawer"
import { NotificationsProvider } from "./Notifications"
import { AppThemeProvider } from "./Theme"

export default function GlobalProvider({ children }) {
  return (
    <AppThemeProvider>
      <AuthProvider>
        <DrawerStateProvider>
          <NotificationsProvider>{children}</NotificationsProvider>
        </DrawerStateProvider>
      </AuthProvider>
    </AppThemeProvider>
  )
}
