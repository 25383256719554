import React, { useState } from "react"

import api from "../../../../services/api"
import apiEndPoints from "../../../../consts/apiEndPoints"
import { createTableData } from "../../../../services/reports"
import { useReportsContext } from "../../../../contexts/Reports"

import Table from "../../../../components/Table"
import Filter from "../../../../components/Reports/Filter"

import ComboboxFormats from "../../../../components/Combobox/Formats"
import ComboboxProductRefs from "../../../../components/Combobox/ProductRefs"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const initialState = {
  report: [],
}

//  ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
//  ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
//  ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

export default function InventoryReport() {
  const { setLoadingText } = useReportsContext()
  const [data, setData] = useState({ ...initialState })

  const fetchReport = async (values) => {
    setData({ ...initialState })
    setLoadingText("Montando relatório de estoque...")

    try {
      const response = await api.post(apiEndPoints.reports.inventory, {
        filtros: { ...values },
      })

      const report = createTableData(response.data.report)
      setData({ report })
    } catch (error) {
      setData({ ...initialState })
    }

    setLoadingText("")
  }

  const handleSubmit = (values) => {
    const params = { ...values }
    fetchReport(params)
  }

  return (
    <>
      <Filter
        onSubmit={handleSubmit}
        fields={[
          {
            name: "cod_produto",
            label: "Código do Produto",
            size: 2,
            defaultValue: "",
          },
          {
            name: "dsc_tamanho_produtos",
            label: "Tamanho",
            component: ComboboxFormats,
            size: 3,
            defaultValue: "Todos",
          },
          {
            name: "prd_referencia",
            label: "Referência",
            component: ComboboxProductRefs,
            size: 1,
            defaultValue: "A",
          },
          {
            name: "completo",
            label: "Completo",
            type: "checkbox",
            size: 1,
            defaultValue: 0,
          },
        ]}
      />

      {/* ==== ESTOQUE ==== */}
      {data.report.length > 0 && (
        <Table
          size="small"
          data={data.report}
          columns={[
            {
              title: "Produto",
              field: "dsc_abreviado",
              render: ({ rowData: product }) =>
                `${product.dsc_abreviado} - ${product.dsc_tamanho_produtos}`,
            },
            { title: "Ref.", field: "dsc_referencia" },
            { title: "Bit.", field: "bitola", align: "right" },
            { title: "Ton.", field: "tonalidade", align: "right" },
            { title: "Lote.", field: "lote", align: "right" },
            { title: "Massa", field: "dsc_massa", align: "right" },
            {
              title: "Saldo (M²)",
              field: "saldo",
              align: "right",
              type: "decimal",
            },
            {
              title: "Maior Lote",
              field: "maior_lote",
              align: "right",
              type: "decimal",
            },
            {
              title: "Programa",
              field: "programa",
            },
          ]}
        />
      )}
    </>
  )
}
