import { LOCALES } from "../locales"

export const es = {
  [LOCALES.SPANISH]: {
    /** LANGUAGES */
    english: "Inglés",
    spanish: "Español",
    portuguese: "Portugués",
    /** HOME */
    "home.dashboard.quotaAchievement": "PEDIDO CUOTA DISPONIBLE",
    "home.dashboard.averagePortifolioOrders":
      "TIEMPO PROMEDIO DE PEDIDOS DE CARTERA",
    "home.dashboard.goal": "OBJETIVO FINANCIERO",
    "home.dashboard.consumptionQuotaByRepresentative":
      "CONSUMO DE CUOTA POR REPRESENTANTE",
    "home.dashboard.representativesWithHigherBilling":
      "REPRESENTANTE CON MAYOR FACTURACIÓN",
    "home.dashboard.customersWithHigherBilling":
      "CLIENTES CON MAYOR FACTURACIÓN",
    "home.dashboard.customersWithMoreDelayedTitles":
      "CLIENTES CON TÍTULOS MÁS RETRASADOS",
    "home.dashboard.lastSixMonthsBilling": "FACTURACIÓN DE LOS ÚLTIMOS 6 MESES",
    "home.dashboard.loadingText": "cargando su información ...",
    "home.dashboard.updatedAt": "Actualizado en {date}",
    /** ORDERS */
    "order.menu.title": "Mis Pedidos",
    "order.yourOrder": "Su pedido",
    "order.productType": "Tipo de Producto",
    "order.note": "Nota",
    "order.futureDelivery": "Entrega futura",
    "order.indirectExport": "Exportación indirecta",
    "order.deliveryType": "Tipo de transporte",
    "order.deliveryType.withdrawnByCustomer": "Retirado por el cliente",
    "order.deliveryType.determinedByCustomer": "Determinado por el cliente",
    "order.new": "Nuevo Pedido",
    "order.item.weight": "P.Bruto",
    "order.item.quantity": "Monto",
    "order.item.includeItemtext": "Incluir item no Pedido",
    order: "Pedido",
    budget: "Presupuesto",
    "order.item.title": "{order} | Itens",
    "order.item.excludeText": "Confirmar exclusão do produto ({product})?",
    "order.item.excludeTextLastItemConfirm":
      "Confirma a exclusão do Item e do Pedido ?",
    "order.item.excludeTextLastItem":
      "Como esse é o último item, o Pedido será excluído também.",
    "order.report.title": "Orden de Venta ({order})",
    "order.report.orderData": "Datos de los Pedidos",
    "order.futureDelivery.initials": "EF",
    "order.futureDelivery.title": "Entrega Futura",
    "order.futureDelivery.title.missingInfo":
      "Entrega Futura - Requerido para completar la información",
    "order.hints.canApprove": "Liberación para evaluación",
    "order.hints.canOrder": "Generar orden de venta",
    "order.budgetConfirm.title": "{previousOrderType} -> {newOrderType}",
    "order.budgetConfirm.text":
      "¿Confirma la generación del {orderType} {order}?",
    "order.budgetConfirm.textSecondary":
      "Algunas partidas presupuestarias no tienen saldo disponible. ¿Quieres continuar sin estos artículos?",
    "order.budgetConfirm.buttonLabel": "Generar {orderType}",
    "order.budgetConfirm.message.success":
      "¡{orderType} {order} generado correctamente!",
    "order.budgetApproval.title": "Presupuesto de lanzamiento",
    "order.budgetApproval.text":
      "¿Confirma la publicación del {order} de presupuesto?",
    "order.budgetApproval.buttonLabel": "Presupuesto de lanzamiento",
    "order.budgetApproval.message.success":
      "¡El presupuesto {order} se publicó correctamente!",

    /** PROFORMAS */
    "proforma.page.title": "Mis Proformas",
    "proforma.codigo": "Proforma Nr.",
    "proforma.emissao": "Fecha",
    "proforma.codpagto": "Condiciones de Pago",
    "proforma.paisorigem": "Pais de Origen",
    "proforma.importador": "Importador",
    "proforma.previsaoemb": "Pronóstico del Embarque",
    "proforma.moeda": "Moneda",
    "proforma.marca": "Marca",
    "proforma.viatransporte": "Via de Transporte",
    "proforma.portoembarque": "Puerto de Embarque",
    "proforma.portodescarga": "Puerto de Descarga",
    "proforma.localentrega": "Local de Entrega",
    "proforma.numpallets": "# de Paletes",
    "proforma.declaracao": "Declaracion",
    "proforma.numcontainers": "# de Contenedores",
    "proforma.pesobruto": "Peso Bruto",
    "proforma.pesoliquido": "Peso Neto",
    "proforma.item.pallets": "Paletes",
    "proforma.item.caixas": "Cajas",
    "proforma.item.sku": "SKU",
    "proforma.item.pecas": "Piezas",
    "proforma.item.metros": "M2",
    "proforma.item.descricao": "Descripcion de las Mercancias",
    "proforma.item.pesobruto": "Peso Bruto",
    "proforma.item.pesoliquido": "Peso Neto",
    "proforma.item.precounitario": "Precio Un.",
    "proforma.item.total": "Importe",
    "proforma.sendAttachment.title": "Enviar proforma firmado por el cliente",
    "proforma.attachments.title": "Archivos ({proforma})",
    "proforma.finish": "¿Terminaste el proforma ({proforma})?",
    "proforma.copy": "Dupdo Proforma",
    "proforma.sendAttachment.inputLabel":
      "Haga clic para agregar archivos adjuntos",
    "proforma.visto": "Visto",
    "proforma.subtotal": "Valor Sub-Total {currency}",
    "proforma.new": "Nuevo Proforma",
    "proforma.approve": "Aprobar",
    "proforma.sendApprovalEmail": "Enviar correo electrónico de aprobación",
    "proforma.resendApprovalEmail": "Reenviar correo electrónico de aprobación",
    "proforma.excludeText":
      "Confirmar la eliminación de la proforma ({proforma})?",
    "proforma.loadingText": "Cargando su proforma... ",
    "proforma.upload.loadingText": "Subiendo archivos...",
    "proforma.download.loadingText": "Descargando archivos...",
    proforma: "Proforma",
    "proforma.menu.title": "Mis Proformas",
    "proforma.item.excludeText": "Confirm deletion of product ({product})?",
    "proforma.item.excludeTextLastItemConfirm":
      "Do you confirm the deletion of the Item and the Proforma?",
    "proforma.item.excludeTextLastItem":
      "As this is the last item, the Proforma will be deleted as well.",
    language: "Idioma",
    "payment.condition": "Condiciones de Pago",
    "pallets.container": "Palets por Contenedor",
    freight: "Flete",
    "document.type": "Documento",
    "document.kind": "Tipo de Documento",
    "document.number": "Número del Documento",
    "document.date": "Fecha del Documento",
    "arrival.place": "Lugar de Ilegada",
    "boarding.place": "Lugar de Embarque",
    "discharge.place": "Lugar de Descarga",
    transport: "Transporte",
    transportation: "Medio de Transporte",
    "unit.value.per.box": "Valor Unitario por Caja",
    "planned.shipment": "Envio Planeado",
    /** ITEMS */
    items: "Artículos",
    item: "Articulo",
    "product.group": "Grupo de Productos",
    "product.format": "Formato",
    "product.formatText": "FORMATO {format}",
    "product.lineText": "LINEA {line}",
    "product.code": "Código",
    "product.ref": "Ref.",
    "product.specs": "Calibre: {gauge} - Matiz: {hue}",
    "measure.unit": "Un.",
    "unit.value": "Valor Unitario",
    "approved.value": "Valor Aprob.",
    "new.item": "Nuevo Artículo",
    /** BULLETINS */
    "bulletins.menu.title": "Anuncios",
    "bulletins.page.title": "Anuncios",
    "bulletins.comunicado": "Lanzamiento",
    "bulletins.emissao": "Fecha de emisión",
    "bulletins.remetente": "Remitente",
    "bulletins.assunto": "Tema en cuestion",
    "bulletin.subject": "Tema en cuestion: {subject}",
    "bulletin.sendedBy": "enviado por {sender} el {date}",
    "bulletin.attachments.label": "Archivos adjuntos",
    /** TERMS */
    "terms.menu.title": "Anuncios",
    "terms.page.title": "Anuncios",
    "terms.comunicado": "Lanzamiento",
    "terms.emissao": "Fecha de emisión",
    "terms.remetente": "Remitente",
    "terms.assunto": "Tema en cuestion",
    "term.subject": "Tema en cuestion: {subject}",
    "term.sendedBy": "enviado por {sender} el {date}",
    "term.attachments.label": "Archivos adjuntos",
    /** REPORTS */
    reports: "Informes",
    report: "Informe - {report}",
    "generate.report": "Generar Informe",
    "reports.paidinstallments": "Cuotas Pagadas",
    "calculation.basis": "Base de cálculo",
    "delete.already.paid": "Eliminar ya pagado?",
    "report.loadingText": "Cargando informe {report}...",
    "report.commissions.predicted": "Comisiones previstas por período",
    "report.commissions.paidOff": "Cheques pagados por período",
    "report.commissions.returned": "Cheques devueltos por período",
    /** VERBS */
    cancel: "Cancelar",
    canceled: "Cancelada",
    changeLanguage: "Cambiar idioma",
    clear: "Reanudar",
    close: "Cerrar",
    loadingOptions: "Cargando opciones...",
    loadingrecords: "Cargando registros",
    print: "Impresión",
    reload: "Recargar",
    save: "Salvar",
    release: "Lanzamiento",
    finish: "Terminar",
    send: "Enviar",
    sendTo: "Enviar al {receiver}",
    search: "Buscar",
    select: "Seleccione",
    "searching.customers": "Buscando Clientes...",
    "search.filters": "Criterio de búsqueda",
    edit: "Editar",
    view: "Vista",
    delete: "Eliminar",
    /** FLAGS */
    created: "Creado",
    pending: "Pendiente...",
    accepted: "Aceptado",
    finalized: "Terminado",
    reserved: "Reservado",
    partiallyReserved: "Parc. Reser.",
    released: "Liberado",
    approved: "Aprobada",
    finished: "Terminada",
    waiting: "Esperando",
    rejected: "Rechazada",
    unfulfilled: "En Abierto",
    fulfilled: "Ya Descargado",
    cancelled: "Cancelado",
    /** MESSAGES */
    nonReversibleAction: "Esta acción no se puede deshacer!",
    "messages.sendMail": "Email enviado con éxito.",
    /** ERRORS */
    "report.error.competenceIsMissing": "Se requiere competencia",
    "no.value": "Falta valor",
    "value.lower": "El valor debe ser menor que {value}",
    "value.higher": "El valor debe ser mayor que {value}",
    "blocked.customer":
      "El cliente está bloqueado, comuníquese con el departamento de finanzas.",
    "inactive.customer":
      "El cliente está inactivo, al ingresar un pedido se volverá a activar.",
    "order.sample.typeProduct.required": "Elige el tipo de producto",
    /** PAGINATOR */
    "records.per.page": "Registros por página",
    pagedescr: "Página: {number} de {total}",
    norecordsfound: "No se encontraron registros",
    registers: "Registros encontrados: {count}",
    /** TERMS */
    agent: "Agente",
    all: "Todos",
    back: "Vuelve",
    carrier: "Compañía de Envios",
    "carrier.add": "Portador adicional {num}",
    city: "Ciudad",
    bank: "Bco",
    agency: "Ag.",
    account: "Cuenta",
    cheque: "Cheque",
    commissions: "Comisiones",
    commision: "Comisión",
    commercial: "Ventas",
    company: "Compañía",
    competence: "Competencia",
    currency: "Moneda",
    "customer.name": "Cliente",
    "customer.document": "Documento",
    days: "dias",
    "due.date": "Vencimiento",
    document: "Documento",
    issue: "Emisión",
    "issued.date": "Emisión",
    "issued.at": "Emitido en {date}",
    predicted: "Predicho",
    paidOffs: "Pagado",
    paidOff: "Pagado",
    quantity: "Monto",
    readed: "Leído",
    representative: "Representante",
    returneds: "Devuelto",
    returned: "Devuelto",
    sample: "Muestra",
    state: "Estado",
    total: "Total",
    title: "Título",
    today: "Hoy",
    unreaded: "No leído",
    value: "Valor",
    brand: "Marca",
    group: "Grupo",
    line: "Línea",
    product: "Producto",
    kit: "Equipo",
    parts: "Partes",
    boxes: "Cajas",
    tags: "Etiquetas",
    pallets: "Paletas",
    goal: "Objetivo {goal}",
    arOrder: "Entrega Futura",

    _requiredField: "Se requiere {field}",
    defaultDialogMessage: "Confirmar la eliminación de {type} ({register}) ?",
    handleAjaxError: "Hubo un error al comunicarse con el servidor: {err}",
    handleUnprocessableEntity:
      "Corrija todos los errores notificados por el servidor.",
    signout: "Terminar",
  },
}

export default es
