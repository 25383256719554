import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import themes from "../../theme"
import { useAppTheme } from "../../contexts/Theme"

import ColorsIcon from "@material-ui/icons/InvertColors"
import IconButton from "@material-ui/core/IconButton"
import Popper from "@material-ui/core/Popper"

import { BASE_DIALOG_Z_INDEX } from "../BaseDialog"
import { Container, Color } from "./styles"
import { Tooltip } from "@material-ui/core"

export default function ChangeTheme({ renderComponent, PopperComponentProps }) {
  const { theme, onChangeTheme } = useAppTheme()

  const isActive = (color) => theme.palette.primary.main === color

  const [anchorEl, setAnchorEl] = useState(null)
  const open = !!anchorEl
  const popperId = anchorEl ? "change-theme-popper" : undefined

  const handleOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleChangeColor = (themeName) => {
    setAnchorEl(null)
    onChangeTheme(themeName)
  }

  return (
    <>
      {renderComponent ? (
        renderComponent({ onClick: handleOpen })
      ) : (
        <Tooltip arrow title="Alterar Aparência">
          <IconButton onClick={handleOpen} color="primary">
            <ColorsIcon
              style={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        </Tooltip>
      )}

      <Popper
        style={{
          zIndex: BASE_DIALOG_Z_INDEX,
        }}
        id={popperId}
        anchorEl={anchorEl}
        open={open}
        {...PopperComponentProps}
      >
        <Container>
          {Object.entries(themes).map(([themeName, theme]) => {
            const color = theme.palette.primary.main
            return (
              <Color
                onClick={() => handleChangeColor(themeName)}
                key={themeName}
                title={themeName.toUpperCase()}
                color={theme.palette.primary.main}
                className={clsx({
                  active: isActive(color),
                })}
              />
            )
          })}
        </Container>
      </Popper>
    </>
  )
}

ChangeTheme.propTypes = {
  onChangeColor: PropTypes.func,
  renderComponent: PropTypes.func,
  PopperComponentProps: PropTypes.object,
}
