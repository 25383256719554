import styled from "styled-components"

export const Container = styled.div`
  position: relative;

  &.loading .content {
    opacity: 0.3;
  }

  .attachments {
    padding: 0.5rem 0;
    border-top: 1px solid ${(props) => props.theme.palette.grey[200]};
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
  }

  .MuiCircularProgress-root {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`
