import Client from "./eventEmitter"
import { events } from "../consts/events"

export const checkTokenExpired = (error) => {
  const response = error.response

  if (response?.status === 401) {
    Client.emit(events.signout)
  }

  return Promise.reject(error)
}
