import React, { useState } from "react"
import PropTypes from "prop-types"
import ScaleLoader from "react-spinners/ScaleLoader"

import { FILE_TYPES } from "@/services/file"
import {
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core"
import { PictureAsPdf, Image as ImageIcon } from "@material-ui/icons"
import {
  StyledListItem,
  StyledAvatar,
  StyledModal,
  ModalContent,
  DeleteIcon,
} from "./styles"

// ------------------------------------

function LoaderContent() {
  return <ScaleLoader color="#FFF" />
}

// ------------------------------------

function ImageListViewer({
  onPreviewFile,
  onDelete,
  file,
  disabledDelete,
  ...props
}) {
  const [preview, setPreview] = useState(file.fileData)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const image = file.fileData || preview

  const isPDF = [FILE_TYPES.PDF, FILE_TYPES.APPLICATION_PDF].includes(file.type)

  const handleOpen = async () => {
    if (onPreviewFile && !image) {
      setShow(true)
      setLoading(true)

      const newPreview = await onPreviewFile(file)
      setPreview(newPreview)

      setLoading(false)
    } else setShow(true)
  }
  const handleClose = () => setShow(false)

  return (
    <>
      <StyledListItem onClick={handleOpen} button {...props}>
        <ListItemAvatar>
          <StyledAvatar
            variant="rounded"
            src={image}
            alt={file.fileName}
            type={file.type}
          >
            {isPDF ? <PictureAsPdf /> : <ImageIcon />}
          </StyledAvatar>
        </ListItemAvatar>
        <ListItemText
          title={file.fileName}
          primary={file.fileName}
          secondary={file.formattedSize}
        />
        <ListItemSecondaryAction hidden={disabledDelete}>
          <IconButton
            size="small"
            color="primary"
            aria-label="image"
            onClick={(e) => onDelete(file, e)}
            disabled={disabledDelete}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </StyledListItem>
      <StyledModal
        onClose={handleClose}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={show}
      >
        <>
          {loading ? (
            <LoaderContent />
          ) : isPDF ? (
            <object
              aria-label="PDF"
              name={file.fileName}
              type={FILE_TYPES.APPLICATION_PDF}
              data={image}
              width="80%"
              height="95%"
              border={1}
            />
          ) : (
            <ModalContent src={image} alt={file.fileName} />
          )}
        </>
      </StyledModal>
    </>
  )
}

ImageListViewer.propTypes = {
  onPreviewFile: PropTypes.func,
  onDelete: PropTypes.func,
}

export default ImageListViewer
