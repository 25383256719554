import styled from "styled-components"
import { darken, parseToRgb } from "polished"
import Skeleton from "@material-ui/lab/Skeleton"

const CARD_HEADER_ICON_SIZE = "20px"

const convertRgbString = (objRbg) =>
  `${objRbg.red} ${objRbg.green} ${objRbg.blue}`

const mixinGradientBoxStyles = (color = "") => `
    linear-gradient(60deg, ${color}, ${darken(0.1, color)});
`

const mixinBoxShadowStyles = (color = "") => `
    0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(${convertRgbString(
      parseToRgb(color)
    )} / 40%);
`

export const StyledCard = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);

  border-radius: 4px;

  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  word-wrap: break-word;

  display: flex;
  flex-direction: column;
  margin-top: ${CARD_HEADER_ICON_SIZE};
`

export const StyledCardHeader = styled.header`
  padding: 0.5rem 1rem;
  position: relative;
  text-align: right;

  .MuiTypography-subtitle1 {
    color: ${(props) => props.theme.palette.grey[500]};
    font-size: 1rem;
  }

  .MuiTypography-subtitle2 {
    font-size: 1.7rem;
    margin-top: -0.3rem;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    &.Large .MuiTypography-subtitle1 {
      text-align: center;
    }

    &:not(.Large) .MuiTypography-subtitle1 {
      font-size: 0.8rem;
    }

    .MuiTypography-subtitle2 {
      font-size: 1.5rem;
      margin-top: 0;
    }
  }
`

export const StyledCardBody = styled.div`
  flex: 1;
  width: 100%;
  overflow-x: auto;
  padding: 0.5rem 1rem;
`

export const StyledCardGradientBox = styled.div`
  color: ${(props) => props.theme.palette.common.white};
  float: left;
  padding: 1rem;
  margin-top: -${CARD_HEADER_ICON_SIZE};
  margin-right: 1rem;
  border-radius: 0.25rem;

  background: ${({ theme }) =>
    mixinGradientBoxStyles(theme.palette.background.default)};
  box-shadow: ${({ theme }) =>
    mixinBoxShadowStyles(theme.palette.background.default)};

  &.primary {
    background: ${({ theme }) =>
      mixinGradientBoxStyles(theme.palette.primary.main)};
    box-shadow: ${({ theme }) =>
      mixinBoxShadowStyles(theme.palette.primary.main)};
  }

  &.info {
    background: ${({ theme }) =>
      mixinGradientBoxStyles(theme.palette.info.main)};
    box-shadow: ${({ theme }) => mixinBoxShadowStyles(theme.palette.info.main)};
  }

  &.success {
    background: ${({ theme }) =>
      mixinGradientBoxStyles(theme.palette.success.main)};
    box-shadow: ${({ theme }) =>
      mixinBoxShadowStyles(theme.palette.success.main)};
  }

  &.error {
    background: ${({ theme }) =>
      mixinGradientBoxStyles(theme.palette.error.main)};
    box-shadow: ${({ theme }) =>
      mixinBoxShadowStyles(theme.palette.error.main)};
  }

  &.custom {
    background: ${(props) =>
      mixinGradientBoxStyles(
        props.color || props.theme.palette.background.default
      )};
  }

  &.lg {
    width: 100%;
    float: center;
    text-align: left;
  }

  .MuiTypography-subtitle1 {
    color: ${(props) => props.theme.palette.common.white};
    font-weight: bold;
  }
`

export const StyledCardFooter = styled.div`
  color: ${(props) => props.theme.palette.grey[600]};
  border-top: 1px solid ${(props) => props.theme.palette.grey[200]};

  margin: 1rem;
  padding-top: 1rem;

  display: flex;
  align-items: center;
`

export const StyledSkeleton = styled(Skeleton)`
  display: block;
  max-width: 100%;
  border-radius: 4px;

  &.skeleton-card {
    margin-top: ${CARD_HEADER_ICON_SIZE};
    min-height: 180px;
  }

  &.skeleton-card-header {
    z-index: 10;
    float: left;
    padding: 1rem;
    margin-top: -${CARD_HEADER_ICON_SIZE};
    margin-left: 1rem;
    border-radius: 0.25rem;

    height: 67px;
    width: 67px;

    box-shadow: ${({ theme }) =>
      mixinBoxShadowStyles(theme.palette.background.default)};
    background: ${({ theme }) =>
      mixinGradientBoxStyles(theme.palette.background.default)};

    &.large {
      width: calc(100% - 2rem);
    }
  }
`
