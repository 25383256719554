import React from "react"
import PropTypes from "prop-types"
import MaskedInput from "react-text-mask"

export default function TextMaskCustom({ inputRef, mask, ...props }) {
  return (
    <MaskedInput
      {...props}
      placeholderChar={"\u2000"}
      mask={mask}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  mask: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
    ),
    PropTypes.func,
  ]).isRequired,
}

TextMaskCustom.defaultProps = {
  inputRef: undefined,
}
