import React, { useEffect, useState, useRef } from "react"
import api from "../../../../services/api"
import apiEndPoints from "../../../../consts/apiEndPoints"
import { cnpj, cpf, cep, telefoneCelular } from "../../../../consts/regexp"
import { UNPROCESSABLE_ENTITY } from "../../../../services/utils"
import { copyObject } from "../../../../services/utils"
import Yup, { handleValidationError } from "../../../../services/yup"
import { unmask } from "../../../../services/masks"
import PropTypes from "prop-types"

import { TabPanel, Tabs } from "../../../../components/Tabs"
import { confirmation } from "../../../../components/Confirmation"
import ComboboxReferences from "../../../../components/Combobox/References"
import {
  Form,
  FormRadioGroup,
  FormCheckBox,
  FormTab,
} from "../../../../components/Form"
import { StyledFormTextField } from "./styles"
import BaseDialog from "./styles"
import { Grid, Typography } from "@material-ui/core"
import { handleAxiosError } from "../../../../services/errorHandlers"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

export const CUSTOMER_TYPES = {
  PJ: "J",
  PF: "F",
}

const ieHelperText = "(Somente números - Clientes sem IE, utilizar ISENTO)"

const zipCodeErrorConfirm = (error) =>
  confirmation.open(
    error.response.data.errors.emp_cep[0]
      .split(" ")
      .map((e) => e.trim())
      .join(" "),
    <>
      <Typography variant="body2" color="secondary">
        CEP não encontrado na base de dados do Grupo Ceral, ou cliente fora de
        área de atendimento do Representante. Favor utilizar outro CEP (o do
        município por exemplo).
      </Typography>
      <Typography variant="body2" color="error">
        Não esquecer de clicar na opção &quot;Não encontrei o CEP&quot;.
      </Typography>
    </>,
    { disagreeLabel: "Fechar", onConfirm: undefined }
  )

//  ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
//  ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
//  ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const whenIsLegalPerson = (validation) =>
  Yup.string().when("emp_tipo_pessoa", (customerType, field) =>
    customerType === CUSTOMER_TYPES.PJ ? validation(field) : field.nullable()
  )

const schema = Yup.object().shape({
  customer: Yup.object().shape({
    id: Yup.string().required("é requerido").nullable(),
  }),
  emp_ie: whenIsLegalPerson((field) =>
    field
      .matches(/(^ISENTO$|\d+)/i, ieHelperText)
      .required("é requirido")
      .nullable()
  ),
  emp_nome_fantasia: whenIsLegalPerson((field) =>
    field.required("é requirido").nullable()
  ),
  emp_razao_social: Yup.string().required("é requerido"),
  emp_cep: Yup.string().required("é requerido"),
  emp_cidade: Yup.string().required("é requerido").nullable(),
  emp_uf: Yup.string().required("é requerido").nullable(),
  emp_endereco: Yup.string().required("é requerido").nullable(),
  cli_email_financeiro: Yup.string()
    .email("Deve ser um e-mail válido")
    .required("é requerido")
    .nullable(),
  emp_e_mail: Yup.string()
    .email("Deve ser um e-mail válido")
    .required("é requerido")
    .nullable(),
  emp_telefone: Yup.string()
    .min(10, "Deve ter no minimo 10 caractéres")
    .required("é requerido")
    .nullable(),
  emp_telefone_2: Yup.string().optional().nullable(),
})

export function CustomerFormEdit({
  customerType: initialCustomerType,
  isFinalCustomer,
  onClose,
  onSave,
  current,
  ...props
}) {
  // =================== HOOKS ===================
  const formRef = useRef(null)
  const [activeTab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [zipCodeLoading, setZipCodeLoading] = useState(false)
  const [customerType, setCustomerType] = useState(initialCustomerType)

  const isPJ = customerType === CUSTOMER_TYPES.PJ
  const isNew = current?.id === undefined
  const isEditable = !isNew

  useEffect(() => {
    const initializeForm = async () => {
      setActiveTab(0)
      formRef.current?.setErrors({})

      if (current?.id) {
        setLoading(true)

        try {
          const response = await api.get(
            `${apiEndPoints.customers.root}/${current.id}`
          )
          const customerData = response.data
          const company = response.data.company

          const previousValues = {
            ...customerData,
            customer: { id: customerData.id },
            emp_ie: customerData.emp_ie, // *
            emp_razao_social: company.emp_razao_social,
            emp_nome_fantasia: company.emp_nome_fantasia, // *
            emp_construtora: customerData.cli_construtora,
            emp_cep: company.emp_cep,
            emp_uf: company.emp_uf || customerData.dsc_uf,
            emp_cidade: customerData.emp_cidade,
            emp_cep_nencontrado: company.emp_cep_nencontrado, // *
            emp_endereco: company.emp_endereco,
            emp_complemento: company?.emp_compl_endereco,
            emp_bairro: company?.emp_bairro,
            emp_numero: company?.emp_endereco_num,
            emp_homepage: company?.emp_homepage, // *
            emp_telefone: company?.emp_telefone,
            emp_telefone_2: company?.emp_telefone_2, // *
            emp_fax: company?.emp_fax,
            cli_email_financeiro: customerData.cli_email,
            emp_e_mail: company?.emp_e_mail,
            emp_referencias: company?.emp_referencias, // *
            emp_referencias_add_check: !!company?.emp_referencias_add, // *
            emp_referencias_add: company?.emp_referencias_add, // *
          }
          // * => valores não recebidos na response, como parametros do backend
          formRef.current?.setValues(previousValues)
        } catch (error) {
          handleAxiosError(error)
        }

        setLoading(false)
      } else {
        const initialValues = {
          customer: { id: null },
          isFinalCustomer,
          emp_tipo_pessoa: customerType,
          emp_telefone: null,
          emp_telefone_2: null,
        }

        formRef.current?.setValues(initialValues)
      }
    }
    initializeForm()
  }, [current])

  // =================== HANDLERS ===================

  const getTitles = () => {
    if (!current) return { title: "...", subtitle: "..." }
    if (!current.id)
      return {
        title: "Cadastro de Clientes",
        subtitle: "Preencha os campos para registrar um novo cliente",
      }
    else
      return {
        title: current.company.emp_razao_social,
        subtitle: "Preencha os campos para atualizar o cliente",
      }
  }

  const handleChangeTab = (_, newTab) => setActiveTab(newTab)

  const validateZipCode = async (e) => {
    const cep = e.target.value

    if (isFinalCustomer || !cep) return

    setZipCodeLoading(true)

    try {
      const response = await api.get(apiEndPoints.miscellaneous.zipCode(cep))

      const zipCode = response.data

      formRef.current.setData({
        emp_cep: zipCode.cep_cep,
        emp_uf: zipCode.dsc_uf,
        emp_cidade: zipCode.dsc_cidade,
        emp_endereco: zipCode.cep_endereco,
        emp_complemento: "",
        emp_bairro: zipCode.cep_bairro,
      })
    } catch (error) {
      if (error.response.status === UNPROCESSABLE_ENTITY) {
        await zipCodeErrorConfirm(error)
      }
    }

    setZipCodeLoading(false)
  }

  const handleSave = async () => {
    formRef.current.setErrors({})

    const values = formRef.current.getData()
    const draft = copyObject(values)

    // ===== FORMAT DATA =====

    draft.customer.id = unmask(draft.customer?.id)
    draft.id = draft.customer.id
    draft.emp_telefone = unmask(draft?.emp_telefone)

    if (draft.emp_telefone_2)
      draft.emp_telefone_2 = unmask(draft?.emp_telefone_2)

    try {
      await schema.validate(draft, { abortEarly: false })
      onSave(draft, formRef.current)
    } catch (error) {
      const validateForm = handleValidationError(formRef.current)
      validateForm(error)
    }
  }

  const handleChangeCustomerType = (event) => {
    const newCustomerType = event.target.value
    setCustomerType(newCustomerType)
  }

  const titles = getTitles()

  return (
    <BaseDialog
      maxWidth="md"
      title={titles.title}
      subtitle={titles.subtitle}
      agreeLabel="Salvar"
      disagreeLabel="Cancelar"
      onConfirm={handleSave}
      onClose={undefined} // Remove Dialog close
      onCancel={onClose}
      {...props}
    >
      <Form formRef={formRef} style={{ minHeight: "320px" }}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          TabComponent={FormTab}
          TabProps={() => ({ panelId: "customer" })}
          tabs={[
            {
              value: 0,
              label: "Cliente",
            },
            {
              value: 1,
              label: "Endereço",
            },
            {
              value: 2,
              label: "Contato",
            },
          ]}
        />
        {/* ==== CLIENTE ==== */}
        <TabPanel
          value={activeTab}
          index={0}
          loading={loading}
          panelId="customer"
        >
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <StyledFormTextField
                name="customer.id"
                label={isPJ ? "CNPJ" : "CPF"}
                textMaskProps={{
                  mask: isPJ ? cnpj.array : cpf.array,
                }}
                disabled={isEditable}
              />
              {isFinalCustomer && (
                <FormRadioGroup
                  onChange={handleChangeCustomerType}
                  direction="row"
                  name="emp_tipo_pessoa"
                  disabled={isEditable}
                  options={[
                    {
                      label: "Pessoa Jurídica",
                      value: CUSTOMER_TYPES.PJ,
                    },
                    {
                      label: "Pessoa Física",
                      value: CUSTOMER_TYPES.PF,
                    },
                  ]}
                />
              )}
            </Grid>
            {isPJ && (
              <Grid item sm={6} xs={12}>
                <StyledFormTextField
                  label="IE"
                  name="emp_ie"
                  helperText={ieHelperText}
                  disabled={isEditable}
                />
              </Grid>
            )}
            <Grid item sm={isPJ ? 7 : 6} xs={12}>
              <StyledFormTextField
                name="emp_razao_social"
                label={isPJ ? "Razão Social" : "Nome"}
                disabled={isEditable}
              />
            </Grid>
            {isPJ && (
              <Grid item sm={5} xs={12}>
                <StyledFormTextField
                  name="emp_nome_fantasia"
                  label="Nome Fantasia"
                  disabled={isEditable}
                />
              </Grid>
            )}
            {isPJ && (
              <Grid item sm={2} xs={12}>
                <FormCheckBox
                  name="emp_construtora"
                  label="Construtora"
                  disabled={isEditable}
                />
              </Grid>
            )}
          </Grid>
        </TabPanel>
        {/* ==== ENDEREÇO ==== */}
        <TabPanel
          value={activeTab}
          index={1}
          loading={zipCodeLoading}
          panelId="customer"
        >
          <Grid container spacing={2}>
            <Grid item sm={4} xs={9}>
              <StyledFormTextField
                name="emp_cep"
                label="CEP"
                onBlur={validateZipCode}
                helperText="(Somente números)"
                textMaskProps={{ mask: cep.array }}
                disabled={isEditable}
              />
              {isPJ && (
                <FormCheckBox
                  name="emp_cep_nencontrado"
                  label="Não encontrei o CEP"
                  disabled={isEditable}
                />
              )}
            </Grid>
            <Grid item sm={2} xs={3}>
              <StyledFormTextField
                name="emp_uf"
                label="UF"
                disabled={!isFinalCustomer || isEditable}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <StyledFormTextField
                name="emp_cidade"
                label="Cidade"
                disabled={!isFinalCustomer || isEditable}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <StyledFormTextField
                name="emp_endereco"
                label="Endereço"
                helperText="Rua, Condominio, Logradouro..."
                disabled={isEditable}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <StyledFormTextField
                name="emp_complemento"
                label="Complemento"
                helperText="(opcional)"
                disabled={isEditable}
              />
            </Grid>
            <Grid item sm={3} xs={8}>
              <StyledFormTextField
                name="emp_bairro"
                label="Bairro"
                disabled={isEditable}
              />
            </Grid>
            <Grid item sm={2} xs={4}>
              <StyledFormTextField
                name="emp_numero"
                label="Nº"
                disabled={isEditable}
              />
            </Grid>
          </Grid>
        </TabPanel>
        {/* ==== CONTATO ==== */}
        <TabPanel
          value={activeTab}
          index={2}
          loading={loading}
          panelId="customer"
        >
          <Grid container spacing={2}>
            <Grid item sm={3} xs={12}>
              <StyledFormTextField
                name="emp_homepage"
                label="Home page"
                disabled={isEditable}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <StyledFormTextField
                name="emp_telefone"
                label="Contato"
                textMaskProps={{
                  mask: telefoneCelular.array,
                }}
                disabled={isEditable}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <StyledFormTextField
                name="emp_telefone_2"
                label="Contato adicional"
                helperText="(opcional)"
                textMaskProps={{
                  mask: telefoneCelular.array,
                }}
                disabled={isEditable}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <StyledFormTextField
                name="emp_fax"
                label="FAX"
                disabled={isEditable}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <StyledFormTextField
                type="email"
                name="cli_email_financeiro"
                label="Email financeiro"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <StyledFormTextField
                type="email"
                name="emp_e_mail"
                label="Email (Nfe)"
                helperText="Email nota fiscal eletrônica"
                disabled={isEditable}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ComboboxReferences
                name="emp_referencias"
                label="Referências"
                search={formRef.current?.emp_referencias}
                disabled={isEditable}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </Form>
    </BaseDialog>
  )
}

CustomerFormEdit.propTypes = {
  isFinalCustomer: PropTypes.bool,
  customerType: PropTypes.oneOf([CUSTOMER_TYPES.PF, CUSTOMER_TYPES.PJ])
    .isRequired,
}

CustomerFormEdit.defaultProps = {
  isFinalCustomer: false,
  customerType: CUSTOMER_TYPES.PJ,
}

export default CustomerFormEdit
