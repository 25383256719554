import React from "react"
import PropTypes from "prop-types"
import { Divider, useMediaQuery, useTheme } from "@material-ui/core"

/**
 * Custom component Divider
 *
 * @param {(DividerProps & {gutterVertical: number} & {onlyMobile: boolean})} props
 * @returns
 */

export function SpacedDivider({ gutterVertical, onlyMobile, ...props }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  if (onlyMobile) {
    return (
      isMobile && (
        <Divider {...props} style={{ margin: `${gutterVertical}rem 0` }} />
      )
    )
  } else {
    return <Divider {...props} style={{ margin: `${gutterVertical}rem 0` }} />
  }
}

export default SpacedDivider

SpacedDivider.propTypes = {
  gutterVertical: PropTypes.number,
  onlyMobile: PropTypes.bool,
}

SpacedDivider.defaultProps = {
  gutterVertical: 1,
  onlyMobile: false,
}
