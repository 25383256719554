import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"

import { copyObject, isValidMail, last } from "../../services/utils"

import { Chip, Box, Typography, Grid, InputAdornment } from "@material-ui/core"

import Dialog from "../BaseDialog"
import { Form, FormTextField, FormCheckBox } from "../Form"

export default function DialogMail({
  initialData,
  staticReceiver,
  onSend,
  loading,
  ...props
}) {
  const formRef = useRef(null)
  const [emails, setEmails] = useState([])
  const lastEmail = last(emails)

  useEffect(() => {
    const initializeForm = () => {
      if (props.open && initialData) {
        setEmails(initialData.copy_to)

        formRef.current?.setData({
          email: initialData.email,
          self_copy: initialData.self_copy,
        })
      }
    }

    initializeForm()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  const handleSend = () => {
    const draft = copyObject(formRef.current.getData())
    draft.copy_to = emails

    onSend(draft)
  }

  const handleSaveEmail = (e) => {
    const email = e.target.value
    const key = e.key

    if (key === "Enter") {
      if (isValidMail(email)) {
        formRef.current.setFieldValue("copy_to", "")
        formRef.current.setFieldError("copy_to", "")

        setEmails([...emails].concat(email))
      } else {
        formRef.current.setFieldError(
          "copy_to",
          `"${email}" Não é um email válido`
        )
      }
    }
  }

  const handleDeleteMail = () => {
    setEmails([...emails].filter((e) => e !== lastEmail))
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onConfirm={handleSend}
      onCancel={props.onClose}
      agreeLabel="Enviar"
      loading={loading}
      {...props}
    >
      <Form formRef={formRef}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField
              disabled={staticReceiver}
              fullWidth
              label="Para"
              name="email"
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              fullWidth
              label="Cc"
              name="copy_to"
              placeholder="Digite o email que deseja copiar e aperte 'Enter'."
              onKeyUp={handleSaveEmail}
              InputProps={{
                startAdornment:
                  emails.length > 0 ? (
                    <InputAdornment position="start">
                      <Box display="flex" gridGap="0.5rem" alignItems="center">
                        <Chip
                          size="small"
                          label={lastEmail}
                          onDelete={handleDeleteMail}
                        />
                        {emails.length > 1 && (
                          <Typography variant="body1">
                            {emails.length}+
                          </Typography>
                        )}
                      </Box>
                    </InputAdornment>
                  ) : null,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              label="Enviar pedido para o meu e-mail"
              name="self_copy"
            />
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  )
}

DialogMail.propTypes = {
  onSend: PropTypes.func.isRequired,
  staticReceiver: PropTypes.bool,
  initialData: PropTypes.shape({
    email: PropTypes.string,
    self_copy: PropTypes.bool,
    copy_to: PropTypes.arrayOf(PropTypes.string),
  }),
}

DialogMail.defaultProps = {
  staticReceiver: true,
}
