import React from "react"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { FormCombobox } from "../../Form"
import { useFetch } from "../../../hooks"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const STATES_STALE_TIME = 1000 * 60 * 30 // 30 minutes
const STATES_CLIENT_NAME = "states"

/** This component can only be used inside a `Form` */
export default function ComboboxStates(props) {
  const {
    data: states,
    isFetching,
    isError,
  } = useFetch({
    url: apiEndPoints.miscellaneous.states,
    clientName: STATES_CLIENT_NAME,
    staleTime: STATES_STALE_TIME,
  })

  const options = (states || []).map((o) => ({
    value: o.id,
    label: o.descr,
  }))

  return (
    <FormCombobox
      options={options}
      loading={isFetching}
      error={isError}
      {...props}
    />
  )
}
