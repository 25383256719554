import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { format } from "date-fns"
import { EXTRACT_URL_PARAMS_REGEXP } from "../../../consts/regexp"
import paths from "../../../consts/paths"
import { useAuth } from "../../../contexts/Auth"
import formatter from "../../../services/formatter"

import Typography from "@material-ui/core/Typography"
import ComboboxReports, { REPORTS } from "../../../components/Combobox/Reports"
import ContentPage from "../../../components/ContentPage"
import Logo from "../../../components/Logo"
import SplashScreen from "../../../components/SplashScreen"

import ReportsProvider from "../../../contexts/Reports"
import ReportRoutes from "../../../routes/reports.routes"
import { Header, Container, Content } from "./styles"

export default function Reports() {
  const history = useHistory()
  const { reportId } = useParams()
  const { currentUser } = useAuth()

  const [report, setReport] = useState(() => {
    const currentReport = REPORTS.find((r) => r.value === Number(reportId))
    return currentReport || REPORTS[0]
  })

  const [loadingText, setLoadingText] = useState("")
  const isLoading = !!loadingText

  useEffect(() => {
    const isUnavailable = Number.isNaN(Number(reportId))

    if (isUnavailable) {
      history.push(
        paths.reports.root.replace(EXTRACT_URL_PARAMS_REGEXP, report.value)
      )
    }
  }, [reportId])

  const handleChange = (_, report) => {
    setReport(report)

    history.push(
      paths.reports.root.replace(EXTRACT_URL_PARAMS_REGEXP, report.value)
    )
  }

  return (
    <>
      <ContentPage
        title="Meus Relatórios"
        contentProps={{ id: "printable" }}
        controls={
          isLoading ? null : (
            <ComboboxReports value={report} onChange={handleChange} />
          )
        }
      >
        <Container>
          <Header>
            <div>
              <Logo />
              <Typography component="div" variant="body2">
                {format(new Date(), "dd/MM/yyyy")}
              </Typography>
            </div>
            <div>
              <Typography
                component="div"
                variant="body2"
                style={{
                  fontWeight: "bold",
                }}
              >
                RELATÓRIO: {report.label.toLocaleUpperCase()}
              </Typography>
              <Typography component="div" variant="body2">
                {currentUser?.socialName} -{" "}
                {formatter(currentUser?.id).toCNPJ()}
              </Typography>
            </div>
          </Header>
          <ReportsProvider
            value={{
              loadingText,
              setLoadingText,
            }}
          >
            <Content>
              <ReportRoutes />
            </Content>
          </ReportsProvider>
        </Container>
      </ContentPage>
      <SplashScreen show={isLoading} loadingText={loadingText} />
    </>
  )
}
