import React from "react"
import PropTypes from "prop-types"
import { merge } from "ramda"

import TextMaskCustom from "../TextMaskCustom"
import { StyledTextField } from "./styles"

/**
 * Custom TextField component
 *
 * @param {import("@material-ui/core").TextFieldProps} props
 * @returns {JSX.Element}
 */
export default function TextField(props) {
  const { textMaskProps, ...textFieldProps } = props

  return (
    <StyledTextField
      {...textFieldProps}
      InputProps={merge(
        textMaskProps ? { inputComponent: TextMaskCustom } : {},
        textFieldProps.InputProps || {}
      )}
      inputProps={merge(textMaskProps, textFieldProps.inputProps || {})}
    />
  )
}

TextField.propTypes = {
  textMaskProps: PropTypes.exact({
    mask: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
      ),
      PropTypes.func,
    ]),
  }),
}

TextField.defaultProps = {}
