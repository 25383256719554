import styled from "styled-components"

export const DuplicatesContainer = styled.div`
  padding: 1rem;

  > div {
    max-width: 25%;
    min-width: 400px;
  }
`
