import { toast } from "react-toastify"
import { translate } from "../_i18n"

export const HTTP_CODES = {
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
  BAD_REQUEST: 400,
}

export const handleAjaxError = (error) =>
  toast.error(translate("handleAjaxError", { err: String(error) }), {
    toastId: error?.response?.status,
  })

export const handleUnprocessableEntity = () => {
  toast.error(translate("handleUnprocessableEntity"))
}

export const handleAxiosError = (error) => {
  const status = error?.response?.status
  const errorMessage = error?.response?.data?.message

  if (errorMessage) {
    toast.error(errorMessage)
  } else {
    switch (status) {
      case HTTP_CODES.UNAUTHORIZED:
        break
      case HTTP_CODES.UNPROCESSABLE_ENTITY:
        handleUnprocessableEntity()
        break
      default:
        handleAjaxError(error)
        break
    }
  }

  return Promise.reject(error)
}

// ---------------------------------------------

export const isBadRequest = (error) => {
  const status = error?.response?.status
  return status === HTTP_CODES.BAD_REQUEST
}
