import styled from "styled-components"
import MuiChip from "@material-ui/core/Chip"

export const StyledChip = styled(MuiChip)`
  min-width: 100px;
  height: 1.75rem;

  .MuiChip-label {
    font-weight: bold;
  }

  &.Chip-primary {
    background-color: ${(props) => props.theme.palette.primary.main};
    .MuiChip-label {
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
  }

  &.Chip-secondary {
    background-color: ${(props) => props.theme.palette.secondary.main};
    .MuiChip-label {
      color: ${(props) => props.theme.palette.secondary.contrastText};
    }
  }

  &.Chip-error {
    background-color: ${(props) => props.theme.palette.error.main};
    .MuiChip-label {
      color: ${(props) => props.theme.palette.error.contrastText};
    }
  }

  &.Chip-success {
    background-color: ${(props) => props.theme.palette.success.main};
    .MuiChip-label {
      color: ${(props) => props.theme.palette.common.white};
    }
  }

  &.Chip-warning {
    background-color: ${(props) => props.theme.palette.warning.main};
    .MuiChip-label {
      color: ${(props) => props.theme.palette.warning.contrastText};
    }
  }

  &.Chip-info {
    background-color: ${(props) => props.theme.palette.info.main};
    .MuiChip-label {
      color: ${(props) => props.theme.palette.info.contrastText};
    }
  }

  &.MuiChip-root.Mui-disabled {
    background-color: ${(props) => props.theme.palette.action.disabled};
    .MuiChip-label {
      color: ${(props) => props.theme.palette.grey[600]};
    }
  }

  /* ＭＯＢＩＬＥ */
  @media (max-width: 600px) {
    min-width: 60px;
    height: 1.4rem;
    margin-left: 0.5rem;
    font-size: 0.7rem;
  }
`
