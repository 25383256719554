import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

.gap-0 {
    gap: 0px;
}

.gap-1 {
    gap: 0.25rem;
    /* 4px */
}

.gap-2 {
    gap: 0.5rem;
    /* 8px */
}

.gap-3 {
    gap: 0.75rem;
    /* 12px */
}

.gap-4 {
    gap: 1rem;
    /* 16px */
}

.gap-5 {
    gap: 1.25rem;
    /* 20px */
}

.gap-6 {
    gap: 1.5rem;
    /* 24px */
}

.gap-7 {
    gap: 1.75rem;
    /* 28px */
}

.gap-7 {
    gap: 1.75rem;
    /* 28px */
}

.gap-8 {
    gap: 2rem;
    /* 32px */
}

.gap-9 {
    gap: 2.25rem;
    /* 36px */
}

.gap-10 {
    gap: 2.5rem;
    /* 40px */
}

/*    GLOBAL
  ===================================================================*/
html,
body {
    font-family: 'Roboto', sans-serif !important;
    --webkit-font-smoothing: antialised;
}

.font-bold {
    font-weight: bold !important;
}

.d-none {
    display: none !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-lower {
    text-transform: lowercase !important;
}

/*    MUI
  ===================================================================*/
.MuiFormHelperText-root {
    font-size: 12px !important;
    white-space: normal !important;
}

/*    PRINT
  ===================================================================*/
@media print {
    ::-webkit-scrollbar {
        width: 0 !important;
    }

    body * {
        visibility: hidden;
    }

    #printable,
    #printable * {
        visibility: visible;
    }

    @page {
        orientation: portrait;
    }
}
`
