import styled from "styled-components"
import BaseDialog from "../../../../components/BaseDialog"
import { FormTextField } from "../../../../components/Form"

export default styled(BaseDialog)`
  .MuiDialogContent-root {
    height: 375px;
    overflow-y: overlay;
  }
`

export const StyledFormTextField = styled(FormTextField)`
  width: 100%;
  & .MuiFormGroup-root {
    justify-content: flex-start;
  }
`
