import styled from "styled-components"

export const SummaryContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;

  .MuiChip-root {
    font-weight: 500;
  }

  .loaded {
    background-color: ${(props) => props.theme.palette.info.main};
    color: #fff;
  }

  .expirated {
    background-color: ${(props) => props.theme.palette.error.main};
    color: #fff;
  }
`
