import axios from "axios"
import { handleAxiosError } from "./errorHandlers"
import { checkTokenExpired } from "./checkTokenExpired"

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.response.use(undefined, handleAxiosError)
api.interceptors.response.use(null, checkTokenExpired)

export default api
