import styled from "styled-components"

export const Container = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;

  background: url("/assets/savane/fundo.png") center no-repeat;
  background-attachment: fixed;
  display: flex;

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    padding: 2rem 0;
    background-color: ${(props) => props.theme.palette.primary.main};

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 75%;
    }
  }

  @media screen and (max-width: 900px) {
    footer .container {
      flex-direction: column;

      .banner img {
        display: none;
      }

      .link img {
        max-width: 150px;
      }
    }
  }
`

export const LeftContainer = styled.div`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.75);

  display: flex;
  flex-direction: column;

  .top {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    flex: 3;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`

export const FormContainer = styled.div`
  flex: 1;
  padding: 0 2rem;
  background-color: rgba(255, 255, 255, 0.9);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 500px;

    img {
      margin: auto;
      margin-bottom: 1rem;
      max-width: 250px;
    }
  }

  @media screen and (max-width: 900px) {
    img {
      width: 200px;
    }
  }
`
