import React from "react"
import { Grid } from "@material-ui/core"
import { toMask } from "@/services/masks"
import { cep } from "@/consts/regexp"

import { TabPanel } from "@/components/Tabs"
import { FormTextField, useCurrentForm } from "@/components/Form"

import ComboboxStates from "@/components/Combobox/States"
import ComboboxCitiesBase from "@/components/Combobox/Cities"
import { useKeepMounted } from "./useKeepMounted"

export function TabAddress({ currentTab }) {
  const keepMounted = useKeepMounted()

  return (
    <TabPanel value={currentTab} index={1} keepMounted={keepMounted}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormTextField
            fullWidth
            name="emp_cep"
            variant="outlined"
            size="small"
            label="CEP"
            textMaskProps={toMask(cep.array)}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <ComboboxStates
            fullWidth
            name="dsc_uf"
            variant="outlined"
            size="small"
            label="UF"
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <ComboboxCities
            fullWidth
            stateName="dsc_uf"
            name="emp_cidade"
            variant="outlined"
            size="small"
            label="Cidade"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormTextField
            fullWidth
            name="emp_bairro"
            variant="outlined"
            size="small"
            label="Bairro"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormTextField
            fullWidth
            name="emp_endereco"
            variant="outlined"
            size="small"
            label="Endereço"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormTextField
            fullWidth
            name="emp_compl_endereco"
            variant="outlined"
            size="small"
            label="Complemento"
          />
        </Grid>
        <Grid item xs={4} sm={1}>
          <FormTextField
            fullWidth
            name="emp_endereco_num"
            variant="outlined"
            size="small"
            label="N°"
          />
        </Grid>
      </Grid>
    </TabPanel>
  )
}

// ------------------------------------------------------------

function ComboboxCities({ stateName, ...props }) {
  const form = useCurrentForm()
  const selectedState = form.getFieldValue(stateName)

  return <ComboboxCitiesBase state={selectedState} {...props} />
}
