import React, { useState } from "react"
import { Link } from "react-router-dom"

import { paths } from "../../../consts/paths"
import { useSearchParams } from "../../../hooks"
import ContentPage from "../../../components/ContentPage"
import { Toolbar, StyledTabs } from "./styles"

import NewCustomerForm from "./NovoCliente"
import BillingAddress from "./LocalEntrega"

import { TabPanelTransition } from "../../../components/TabPanelTransition"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const SEARCH_PARAM = "tab"

const REGISTERS = [
  {
    value: 0,
    label: "NOVO CLIENTE",
  },
  {
    value: 1,
    label: "LOCAL DE ENTREGA",
  },
]

export default function Registers() {
  const searchParams = useSearchParams()

  const [tab, setTab] = useState(
    parseInt(searchParams.get(SEARCH_PARAM)) || REGISTERS[0].value
  )

  const index = parseInt(searchParams?.get(SEARCH_PARAM)) || 0

  const handleChangeTab = (_, newTab) => setTab(newTab)

  const renderContent = () => {
    switch (tab) {
      case 0:
        return <NewCustomerForm />
      case 1:
        return <BillingAddress />
      default:
        return null
    }
  }

  return (
    <>
      <ContentPage
        title="Cadastros"
        headerComponent={
          <Toolbar>
            <StyledTabs
              value={tab}
              onChange={handleChangeTab}
              tabs={REGISTERS}
              TabProps={(tab) => ({
                component: Link,
                to: `${paths.registers.root}?${SEARCH_PARAM}=${tab.value}`,
              })}
            />
          </Toolbar>
        }
      >
        <TabPanelTransition
          size={tab === 0 ? "lg" : "md"}
          value={tab}
          index={index}
        >
          {renderContent()}
        </TabPanelTransition>
      </ContentPage>
    </>
  )
}
