import React from "react"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { useFetch } from "../../../hooks"
import { FormCombobox } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const PRODUCT_REFS_STALE_TIME = 1000 * 60 * 10 // 10 minutes

/** This component can only be used inside a `Form` */
export default function ComboboxRefs(props) {
  const {
    data: refs,
    isFetching,
    isError,
  } = useFetch({
    clientName: "productRefs",
    url: apiEndPoints.miscellaneous.productRefs,
    staleTime: PRODUCT_REFS_STALE_TIME,
  })

  const options = (refs || []).map((ref) => ({
    ...ref,
    value: ref.prd_referencia,
    label: ref.prd_referencia,
  }))

  return (
    <FormCombobox
      options={options}
      loading={isFetching}
      error={isError}
      {...props}
    />
  )
}
