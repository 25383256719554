import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "../../TextField"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

export const DEFAULT_SELECTED_REPORT_ID = 1

export const REPORTS = [
  { value: 1, label: "Pedidos Emitidos" },
  { value: 2, label: "Pedidos Faturados" },
  // { value: 3, label: "Pedidos Cancelados" },
  { value: 4, label: "Pedidos em Carteira" },
  { value: 5, label: "Estoque" },
  { value: 6, label: "Consulta de Clientes" },
  // { value: 7, label: "Condição Comercial" },
  // { value: 8, label: "Comissão" },
  // { value: 9, label: "Conta Corrente" },
  // { value: 10, label: "Duplicatas a Receber" },
]

export default function ComboboxReports(props) {
  return (
    <Autocomplete
      {...props}
      id="combo-box-reports"
      disableClearable
      options={REPORTS}
      getOptionLabel={(option) => option.label}
      getOptionDisabled={(option) => option.disabled}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Escolha o Relatório" variant="filled" />
      )}
    />
  )
}

ComboboxReports.defaultProps = {}
