import React from "react"
import { Switch } from "react-router-dom"
import { paths } from "../consts/paths"

import Route from "../components/PrivateRoute"
import SignIn from "../pages/Auth/SignIn"
import Layout from "../pages/Layout"

import { useAuth } from "../contexts/Auth"

export default function Routes() {
  const { loading } = useAuth()

  if (loading) {
    return null
  }

  return (
    <Switch>
      <Route
        path={paths.public.login}
        component={SignIn}
        exact
        isPrivate={false}
      />
      <Route path={paths.root} component={Layout} exact />
    </Switch>
  )
}
