import React from "react"
import clsx from "clsx"

import { useAuth } from "../../contexts/Auth"
import { useDrawerContext } from "../../contexts/Drawer"
import { useTheme, useMediaQuery } from "@material-ui/core"

import MenuIcon from "@material-ui/icons/Menu"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"

import Logo from "../Logo"
import UserChip from "../UserChip"
import { StyledAppBar, StyledToolbar } from "./styles"

export default function AppBar() {
  const { signOut } = useAuth()
  const { open, onOpen } = useDrawerContext()

  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <StyledAppBar
        variant="outlined"
        color="inherit"
        position="fixed"
        className={clsx({ "AppBar-shift": open })}
      >
        <StyledToolbar>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {sm && (
              <div>
                <IconButton aria-label="open drawer" onClick={onOpen}>
                  <MenuIcon />
                </IconButton>
              </div>
            )}

            <div className="Logo">
              <Logo />
            </div>
            <Box
              display="flex"
              alignItems="center"
              className="gap-4 MuiToolbar-gutters"
            >
              <UserChip />

              {!sm && (
                <Button onClick={signOut} color="inherit">
                  Sair
                </Button>
              )}
            </Box>
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <StyledToolbar />
    </>
  )
}
