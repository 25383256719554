import React from "react"
import { IntlProvider } from "react-intl"
import messages from "./messages"
import useLocale from "../hooks/useLocale"

export default function I18nProvider({ children }) {
  const locale = useLocale()

  return (
    <IntlProvider
      locale={locale}
      textComponent={React.Fragment}
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  )
}
