import styled from "styled-components"

export const FormContainer = styled.div`
  .MuiTab-root {
    text-transform: none;
  }

  div[role="tabpanel"] {
    min-height: 350px;
    max-height: none;
  }
`
