import React from "react"
import { Box, Typography } from "@material-ui/core"
import { ToggleButton } from "@material-ui/lab"

import { FormHelperText, useCurrentForm } from "../../../../../components/Form"
import { StyledToggleButtonGroup } from "./styles"

export function Brands({ name }) {
  const form = useCurrentForm()
  const value = form.getFieldValue(name) || []

  function handleChange(_, newValue) {
    form.setFieldValue(name, newValue)
  }

  return (
    <Box display="flex" flexDirection="column" marginBottom="1rem">
      <Typography
        gutterBottom
        component="label"
        color="textSecondary"
        htmlFor={name}
      >
        Marcas
      </Typography>

      <StyledToggleButtonGroup value={value} onChange={handleChange} id={name}>
        <ToggleButton
          size="small"
          title="Adicionar marca SAVANE"
          style={{ minWidth: 100 }}
          value="1"
        >
          SAVANE
        </ToggleButton>
        <ToggleButton
          size="small"
          title="Adicionar marca LINHA UM"
          style={{ minWidth: 100 }}
          value="6"
        >
          LINHA UM
        </ToggleButton>
        <ToggleButton
          size="small"
          title="Adicionar marca RCR"
          style={{ minWidth: 100 }}
          value="5"
        >
          RCR
        </ToggleButton>
      </StyledToggleButtonGroup>

      <FormHelperText name={name} />
    </Box>
  )
}
