import { LOCALES } from "./locales"

export const LOCALE_SCOPE_LABELS = {
  [LOCALES.ENGLISH]: {
    fulfilled: "Fulfilled",
    unfulfilled: "Unfulfilled",
    canceled: "Canceled",
  },
  [LOCALES.SPANISH]: {
    fulfilled: "Cumplido",
    unfulfilled: "Incumplido",
    canceled: "Cancelado",
  },
  [LOCALES.PORTUGUESE_BR]: {
    fulfilled: "Baixados",
    unfulfilled: "Abertos",
    canceled: "Cancelados",
  },
}

export const LOCALE_PRODUCT_TYPES = {
  [LOCALES.ENGLISH]: {
    kit: "Kit",
    parts: "Parts",
  },
  [LOCALES.SPANISH]: {
    kit: "Equipo",
    parts: "Partes",
  },
  [LOCALES.PORTUGUESE_BR]: {
    kit: "Kit",
    parts: "Peças",
  },
}
