import styled from "styled-components"

export const Container = styled.div`
  min-width: 200px;

  padding: 0.3rem 1rem;
  border-left: 1px solid ${(props) => props.theme.palette.grey["200"]};
  border-right: 1px solid ${(props) => props.theme.palette.grey["200"]};

  .MuiTypography-root {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .MuiAvatar-root {
    height: 40px;
    width: 40px;

    &:hover {
      opacity: 0.8;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0;
    border-left: none;
    border-right: none;
    min-width: 100%;
  }
`
