import React from "react"
import PropTypes from "prop-types"
import { ORDER_SCOPE_TYPES } from "../../../consts/types"
import Combobox from "../../Combobox/Base"
import TextField from "../../TextField"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
export default function ComboboxOrderScopes({ getOptions, ...props }) {
  const defaultOptions = [
    {
      label: "Abertos",
      value: ORDER_SCOPE_TYPES.unfulfilled,
    },
    {
      label: "Baixados",
      value: ORDER_SCOPE_TYPES.fulfilled,
    },
    {
      label: "Cancelados",
      value: ORDER_SCOPE_TYPES.canceled,
    },
  ]

  const options = getOptions ? getOptions(defaultOptions) : defaultOptions

  return (
    <Combobox
      {...props}
      id="combo-box-order-scopes"
      disableClearable
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Status"
          variant="filled"
          size="medium"
        />
      )}
    />
  )
}

ComboboxOrderScopes.propTypes = {
  getOptions: PropTypes.func,
}
