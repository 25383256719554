import React, { useState } from "react"
import Button from "../../../components/Button"
import TextField from "../../../components/TextField"
import { Container, FormContainer, LeftContainer } from "./styles"

import { useAuth } from "../../../contexts/Auth"
import { cnpj } from "../../../consts/regexp"
import { unmask } from "../../../services/masks"

export default function AuthPage() {
  const [loading, setLoading] = useState(false)

  const { signIn } = useAuth()

  async function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)

    const formData = new FormData(event.target)
    const { username, password } = Object.fromEntries(formData)

    const isAuthenticated = await signIn({
      username: unmask(username),
      password,
    })

    if (!isAuthenticated) {
      setLoading(false)
    }
  }

  return (
    <Container>
      <LeftContainer>
        <div className="top">
          <div>
            <img src="/assets/savane/bem_vindo.png" alt=" " />
          </div>
        </div>
      </LeftContainer>

      <FormContainer>
        <form onSubmit={handleSubmit}>
          <img
            src="/assets/logo-empresa.png"
            alt="Mulher repousando em um sofá bege;"
          />

          <TextField
            autoFocus
            name="username"
            label="CNPJ"
            type="text"
            margin="none"
            variant="standard"
            required
            placeholder="Informe seu CNPJ"
            inputProps={{
              inputMode: "numeric",
            }}
            textMaskProps={{
              mask: cnpj.array,
            }}
          />
          <TextField
            autoComplete="current-password"
            name="password"
            label="Senha"
            type="password"
            margin="none"
            variant="standard"
            required
          />

          <Button
            isLoading={loading}
            size="large"
            color="primary"
            type="submit"
            variant="contained"
            disableElevation
            fullWidth
          >
            ENTRAR
          </Button>
        </form>
      </FormContainer>

      <footer>
        <div className="container">
          <div className="banner">
            <img src="/assets/savane/design_escolha.png" alt=" " />
          </div>

          <div className="link">
            <a
              href="http://savane.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/savane/site.png" alt=" " />
            </a>
          </div>
        </div>
      </footer>
    </Container>
  )
}
