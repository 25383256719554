import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"

import { sum } from "ramda"
import { toast } from "react-toastify"
import { Typography } from "@material-ui/core"

import { useCurrentForm } from "@/components/Form"
import { TabPanel } from "@/components/Tabs"

import { SUPPORTED_FILE_TYPES } from "@/services/file"
import ImageUploader from "@/components/ImageUploader"

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const MAX_SIZE_BYTES = 4 * (1024 * 1024) // 4mb

// -----------------------------------------

export function TabAttachments({ attachments, setAttachments, currentTab }) {
  const form = useCurrentForm()

  const onPreview = useCallback((...args) => {
    console.log(args)
  }, [])

  const onAddAttachments = useCallback((event) => {
    const readedFiles = event.target.readedFiles

    setAttachments((previousAttachments) => {
      const newAttachments = previousAttachments.concat(readedFiles)

      const maximumSizeReached =
        sum(newAttachments.map((file) => file.size)) > MAX_SIZE_BYTES

      if (maximumSizeReached) {
        toast.warning("Tamanho máximo de anexos atingido 4mb")
        return previousAttachments
      }

      return newAttachments
    })
  }, [])

  const onDeleteAttachment = useCallback((file) => {
    setAttachments((previousAttachments) =>
      previousAttachments.filter((f) => f.id !== file.id)
    )
  }, [])

  useEffect(() => {
    form.setFieldValue("attachments", attachments)
  }, [attachments])

  return (
    <TabPanel value={currentTab} index={4} keepMounted={false}>
      <ImageUploader
        label="Clique para adicionar os arquivos"
        name="attachments"
        files={attachments}
        loading={false}
        multiple
        attachable
        onPreview={onPreview}
        onAdd={onAddAttachments}
        onDelete={onDeleteAttachment}
      />

      <Typography variant="caption" color="textSecondary">
        Formatos suportados: {SUPPORTED_FILE_TYPES.join(", ")}
      </Typography>

      <Typography variant="body1" color="error">
        Tamanho máximo 4mb
      </Typography>
    </TabPanel>
  )
}

TabAttachments.propTypes = {
  attachments: PropTypes.array,
  setAttachments: PropTypes.func,
  currentTab: PropTypes.number,
}
