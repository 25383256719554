import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import { StyledChip } from "./styles"

/**
 * Base Chip component
 *
 * @typedef {( 'primary' | 'secondary' | 'error' | 'success' | 'warning' | 'info' | undefined )} BackgroundColors
 *
 * @param {(import("@material-ui/core").ChipProps & {background: BackgroundColors})} props
 * @returns
 */
export default function Chip({ background, ...props }) {
  return (
    <StyledChip
      {...props}
      className={clsx(props.className, `Chip-${background}`)}
    />
  )
}

Chip.propTypes = {
  background: PropTypes.string,
}
