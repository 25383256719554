import React from "react"
import PropTypes from "prop-types"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { useFetch } from "../../../hooks"
import { FormCombobox } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const STALE_TIME = 1000 * 60 * 1 // 1 minute

/** This component can only be used inside a `Form` */
export default function ComboboxPriceTables({ customer, ...props }) {
  const { data, isFetching } = useFetch({
    clientName: ["priceTables", customer],
    url: apiEndPoints.miscellaneous.priceTables(customer),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
  })

  const options = (data || []).map((item) => ({
    value: item.cod_tpreco,
    label: item.descr,
  }))

  return (
    <FormCombobox
      disableClearable
      noOptionsText="Não existe nenhuma tabela de preço ativa no momento"
      options={options}
      loading={isFetching}
      {...props}
    />
  )
}

ComboboxPriceTables.propTypes = {
  customer: PropTypes.string,
}

ComboboxPriceTables.defaultProps = {
  customer: "",
}
