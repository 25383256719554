import styled from "styled-components"
import { ToggleButtonGroup } from "@material-ui/lab"

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 1rem;
  padding-bottom: 0.5rem;
  border-radius: 2px;
  background-color: ${(props) => props.theme.palette.grey[50]};
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButton-root.Mui-selected {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
`
