import styled from "styled-components"

export const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};

  small {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${(props) => props.theme.palette.text.secondary};
  }

  img {
    width: 100%;
    max-width: 70px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 1rem;
    display: flex;
    justify-content: center;
    background-color: ${(props) => props.theme.palette.primary.main};

    img {
      display: none;
    }

    .MuiTypography-colorTextSecondary {
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
  }

  @media screen and (max-width: 600px) {
    small {
      font-size: 0.675rem;
      text-align: center;
    }
  }
`
