export const ORDER_TYPES = {
  regular: "R",
  sample: "S",
  outlet: "O",
  budget: "B",
}

export const ORDER_TYPES_LABELS = {
  [ORDER_TYPES.regular]: "Venda",
  [ORDER_TYPES.sample]: "Amostra",
  [ORDER_TYPES.outlet]: "Ponta",
  [ORDER_TYPES.budget]: "Orçamento",
}

export const ORDER_SCOPE_TYPES = {
  fulfilled: "fulfilled",
  unfulfilled: "unfulfilled",
  canceled: "canceled",
}

export const APPROVAL_STATUS_TYPES = {
  unapproved: "unapproved",
  approved: "approved",
}

export const PROFORMA_STATUS_TYPES = {
  created: "created",
  pending: "pending",
  accepted: "accepted",
  finalized: "finalized",
  canceled: "canceled",
}

export const CUSTOMER_SERVICE_STATUS_TYPES = {
  unstarted: 0,
  onAttendance: 1,
  onApproval: 2,
  approved: 3,
  scheduled: 4,
}

export const BULLETIN_STATUS_TYPES = {
  unreaded: "1",
  readed: "2",
  all: "0",
}

export const PARCEL_TYPES = {
  paymentCondition: 0,
  equalParcels: 1,
  differentParcels: 2,
}

export const CUSTOMER_BLOCK_TYPE = {
  permanentlyBlocked: "permanently_blocked",
}

export const CUSTOMER_ACTIVE_TYPE = {
  active: "active",
  inactive: "inactive",
}

export const DELIVERY_TYPES = {
  withdrawn: "1",
  determinedByCompany: "2",
  determined: "3",
}

export const PRODUCT_REFERENCES = {
  S: "S",
  A: "A",
  B: "B",
  C: "C",
}

export const SAMPLE_TYPES = {
  KIT: "K",
  PEACES: "P",
}
