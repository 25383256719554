import React from "react"
import PropTypes from "prop-types"

import CardTable from "../../../../components/CardTable"
import { translate } from "../../../../_i18n"

export default function GoalsTable({ data }) {
  return (
    <CardTable
      color="info"
      data={data}
      columns={[
        { title: translate("brand"), field: "dsc_marca" },
        { title: translate("competence"), field: "met_compet" },
        {
          title: translate("goal", {
            goal: translate("quantity"),
          }),
          field: "met_qtdade",
          type: "decimal",
          align: "right",
        },
        {
          title: "(%)",
          field: "perc_meta_qtdade",
          type: "progress-bar",
          progress: {
            value: "qtdade_total",
          },
        },
        {
          title: translate("goal", {
            goal: translate("value"),
          }),
          field: "met_valor",
          type: "decimal",
          align: "right",
        },
        {
          title: "(%)",
          field: "perc_meta_valor",
          type: "progress-bar",
          progress: {
            value: "valor_total",
          },
        },
      ]}
    />
  )
}

GoalsTable.propTypes = {
  data: PropTypes.array,
}
