import styled from "styled-components"
import { lighten } from "polished"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import CircularProgress from "@material-ui/core/CircularProgress"

export const StyledTableContainer = styled(TableContainer)`
  position: relative;

  &.loading {
    min-height: 50vh;
    overflow: hidden;

    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      width: 100%;
      background-color: rgba(255, 255, 255, 0.6);
    }

    .MuiTablePagination-root {
      margin-top: 50vh;
    }
  }

  &.loading:not(.autoSizing) {
    min-height: 10vh;

    .MuiTablePagination-root {
      margin-top: 10vh;
    }
  }

  &.loading.empty {
    min-height: none;
  }

  &.loading:not(.empty) {
    .MuiTablePagination-root {
      margin-top: 0;
    }
  }
`

export const StyledTable = styled(Table)`
  .MuiTableHead-root {
    background: ${(props) =>
      lighten(0.01, props.theme.palette.background.default)};
  }
`

export const StyledTableRow = styled(TableRow)`
  &.TableRow-group {
    background: ${(props) => lighten(0.725, props.theme.palette.primary.main)};

    .MuiTableCell-root:first-child {
      font-weight: bold;
      border-left: 5px solid ${(props) => props.theme.palette.primary.main};
    }
  }

  &.TableRow-subTotal {
    .MuiTableCell-root {
      font-weight: bold;
    }
  }
`

export const StyledTableCell = styled(TableCell)`
  border-color: ${(props) => props.theme.palette.background.default};

  &.MuiTableCell-head {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
  }

  &.MuiTableCell-sizeSmall {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &[role="action"] {
    &.MuiTableCell-sizeSmall {
      padding: 6px 12px 6px 8px;

      .MuiButtonBase-root:not(.Mui-disabled) {
        .MuiSvgIcon-root.primary {
          color: ${(props) => props.theme.palette.primary.main};
        }

        .MuiSvgIcon-root.success {
          color: ${(props) => props.theme.palette.success.main};
        }

        .MuiSvgIcon-root.info {
          color: ${(props) => props.theme.palette.info.main};
        }
      }
    }
  }

  a {
    color: ${(props) => props.theme.palette.primary.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &.MuiTableCell-detail {
    width: 24px;
  }
`

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
`
