import React, { useState } from "react"

import api from "../../../../services/api"
import apiEndPoints from "../../../../consts/apiEndPoints"
import { Yup } from "../../../../services/yup"
import { sumProp } from "../../../../services/utils"
import { useReportsContext } from "../../../../contexts/Reports"

import Table from "../../../../components/Table"
import Filter from "../../../../components/Reports/Filter"

import ComboboxCompanies from "../../../../components/Combobox/Companies"
import ComboboxCustomers from "../../../../components/Combobox/Customers"
import ComboboxProductRefs from "../../../../components/Combobox/ProductRefs"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const initialState = {
  report: [],
}

//  ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
//  ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
//  ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const schema = Yup.object().shape({
  di: Yup.string().required("Informe a data inicial").nullable(),
  df: Yup.string().required("Informe a data final").nullable(),
})

export default function IssuedOrdersReport() {
  const { setLoadingText, minDate, maxDate } = useReportsContext()
  const [data, setData] = useState({ ...initialState })

  const fetchReport = async (values) => {
    setData({ ...initialState })
    setLoadingText("Montando relatório de pedidos emitidos...")

    try {
      const response = await api.post(apiEndPoints.reports.issuedOrders, {
        filtros: { ...values },
      })

      const report = response.data.report

      setData({ report })
    } catch (error) {
      setData({ ...initialState })
    }

    setLoadingText("")
  }

  const handleSubmit = (values) => {
    const params = { ...values }
    fetchReport(params)
  }

  return (
    <>
      <Filter
        onSubmit={handleSubmit}
        schema={schema}
        fields={[
          {
            name: "di",
            label: "Data Inicial",
            type: "date",
            defaultValue: minDate,
            size: 2,
          },
          {
            name: "df",
            label: "Data Final",
            type: "date",
            defaultValue: maxDate,
            size: 2,
          },
          {
            name: "cod_cia",
            label: "Companhia",
            defaultValue: 1,
            size: 2,
            component: ComboboxCompanies,
            componentProps: {
              disableClearable: true,
            },
          },
          {
            name: "ref",
            label: "Descrição do Produto...",
            defaultValue: "",
            size: 2,
          },
          {
            name: "classif",
            label: "Ref.",
            defaultValue: -1,
            size: 1,
            component: ComboboxProductRefs,
          },
          {
            name: "cod_cliente",
            label: "Cliente",
            defaultValue: "Todos",
            component: ComboboxCustomers,
            componentProps: {
              onLoadOptions: (customer) => ({
                value: customer.id,
                label: customer.emp_razao_social,
              }),
            },
            size: 3,
          },
          {
            name: "gera_fatur",
            label: "Gera Faturamento",
            type: "checkbox",
            defaultValue: 1,
            size: 3,
          },
        ]}
      />

      {data.report.length > 0 && (
        <>
          {/* ==== PEDIDOS EMITIDOS ==== */}
          <Table
            size="small"
            data={data.report}
            options={{ total: true }}
            columns={[
              {
                title: "Pedido",
                field: "cod_pedido",
              },
              { title: "Seu Pedido", field: "ped_seupedido" },
              { title: "Emissão", field: "emissao" },
              { title: "Cliente", field: "cliente" },
              {
                title: "Produto",
                field: "dsc_abreviado",
              },
              {
                title: "Ref",
                field: "prd_referencia",
              },
              {
                title: "Bit",
                field: "itped_bitola",
              },
              {
                title: "Ton",
                field: "itped_tonalidade",
              },
              {
                title: "Lote",
                field: "itped_lote",
              },
              {
                title: "UN",
                field: "dsc_un_medidas",
                align: "center",
              },
              {
                title: "Quantidade M²",
                field: "qtdade",
                type: "decimal",
                align: "right",
                getTotal: ({ data }) => sumProp("qtdade", data),
              },
              {
                title: "Valor Un.",
                field: "itped_prunit",
                type: "currency",
                align: "right",
              },
              {
                title: "Valor",
                field: "valor",
                type: "currency",
                align: "right",
                getTotal: ({ data }) => sumProp("valor", data),
              },
              {
                title: "Faturamento",
                field: "dsc_tipo",
              },
            ]}
            cellStyle={({ row }) =>
              row.isSubTotalRow && {
                fontWeight: "bold",
              }
            }
          />
        </>
      )}
    </>
  )
}
