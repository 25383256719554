import React from "react"
import api from "../../../../services/api"
import { apiEndPoints } from "../../../../consts/apiEndPoints"
import { confirmation } from "../../../../components/Confirmation"
import { translate } from "../../../../_i18n"

import Typography from "@material-ui/core/Typography"
import Alert from "@material-ui/lab/Alert"

export const getBudgetStockVerify = async (order) => {
  try {
    const response = await api.get(
      apiEndPoints.orders.budgetStockVerify(order.id)
    )
    return response.data.in_stock
  } catch (error) {
    return false
  }
}

export const removeOrderConfirm = (order) =>
  confirmation.open(
    "Confirmação",
    `Deseja remover o pedido ${order.cod_pedido} ?`,
    {
      agreeLabel: translate("delete"),
      lazyConfirmation: true, // Disabled auto close after confirm and fill loading state
    }
  )

export const approveBudgetConfirm = (order) =>
  confirmation.open(
    translate("order.budgetApproval.title", {
      budgetType: translate("budget"),
    }),
    translate("order.budgetApproval.text", {
      order: order.cod_pedido,
      budgetType: translate("budget"),
    }),
    {
      agreeLabel: translate("order.budgetApproval.buttonLabel", {
        budgetType: translate("budget"),
      }),
      disagreeLabel: translate("close"),
      lazyConfirmation: true, // Disabled auto close after confirm and fill loading state
    }
  )

export const generateBudgetConfirm = (order) =>
  confirmation.open(
    translate("order.budgetConfirm.title", {
      previousOrderType: translate("order"),
      newOrderType: translate("budget"),
    }),
    translate("order.budgetConfirm.text", {
      order: order.cod_pedido,
      orderType: translate("budget"),
    }),
    {
      agreeLabel: translate("order.budgetConfirm.buttonLabel", {
        orderType: translate("budget"),
      }),
      disagreeLabel: translate("close"),
      lazyConfirmation: true, // Disabled auto close after confirm and fill loading state
    }
  )

export const generateOrderConfirm = async (order) => {
  let confirmationContent = translate("order.budgetConfirm.text", {
    order: order.cod_pedido,
    orderType: translate("order"),
  })

  if (!(await getBudgetStockVerify(order))) {
    confirmationContent = (
      <>
        <Typography variant="body1">{confirmationContent}</Typography>
        <br />
        <Alert severity="warning">
          <Typography variant="body2">
            {translate("order.budgetConfirm.textSecondary")}
          </Typography>
        </Alert>
      </>
    )
  }

  const confirmed = await confirmation.open(
    translate("order.budgetConfirm.title", {
      previousOrderType: translate("budget"),
      newOrderType: translate("order"),
    }),
    confirmationContent,
    {
      agreeLabel: translate("order.budgetConfirm.buttonLabel", {
        orderType: translate("order"),
      }),
      disagreeLabel: translate("close"),
      lazyConfirmation: true, // Disabled auto close after confirm and fill loading state
    }
  )

  return confirmed
}

export const removeOrderItemConfirm = (orderItem, isLast) => {
  const content = isLast ? (
    <>
      <Typography variant="body2">
        {translate("order.item.excludeTextLastItem")}
      </Typography>
      <Typography variant="body2" style={{ marginBottom: "1rem" }}>
        {translate("order.item.excludeTextLastItemConfirm")}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        {translate("nonReversibleAction")}
      </Typography>
    </>
  ) : (
    <>
      <Typography component="div" variant="body2">
        {translate("defaultDialogMessage", {
          type: translate("item"),
          register: orderItem.product.descr,
        })}
      </Typography>
      <Typography component="div" color="textSecondary" variant="body2">
        {translate("nonReversibleAction")}
      </Typography>
    </>
  )

  return confirmation.open("Confirmação", content, {
    agreeLabel: translate("delete"),
    lazyConfirmation: true, // Disabled auto close after confirm and fill loading state
  })
}
