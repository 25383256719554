import React from "react"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { FormCombobox } from "../../Form"
import { useFetch } from "../../../hooks"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const HUES_STALE_TIME = 1000 * 60 * 10 // 10 minutes

/** This component can only be used inside a `Form` */
export default function ComboboxHues(props) {
  const {
    data: hues,
    isFetching,
    isError,
  } = useFetch({
    clientName: "hues",
    url: apiEndPoints.miscellaneous.hues,
    staleTime: HUES_STALE_TIME,
  })

  const huesOptions = (hues || []).map((hue) => ({
    ...hue,
    value: hue.value,
    label: hue.label,
  }))

  return (
    <FormCombobox
      options={huesOptions}
      loading={isFetching}
      error={isError}
      {...props}
    />
  )
}
