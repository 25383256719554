import React from "react"
import { Switch, Redirect } from "react-router-dom"

import paths from "../consts/paths"
import Route from "../components/PrivateRoute"

import Profile from "../pages/User/Profile"
import Bulletins from "../pages/User/Bulletins"
import Home from "../pages/Representative/Home"
import Orders from "../pages/Representative/Orders"
import Order from "../pages/Representative/Orders/Order"
import OrderItems from "../pages/Representative/Orders/Items"
import Reports from "../pages/Representative/Reports"
import Registers from "../pages/Representative/Registers"
import NotFoundPage from "../pages/404"

export default function ContentRoutes() {
  return (
    <Switch>
      <Route path={paths.profile.home} component={Home} exact />
      <Route path={paths.profile.root} component={Profile} exact />
      <Route path={paths.orders.show} component={Order} exact />
      <Route path={paths.orders.root} component={Orders} exact />
      <Route path={paths.orders.items} component={OrderItems} exact />
      <Route path={paths.bulletins.root} component={Bulletins} exact />
      <Route path={paths.registers.root} component={Registers} />
      <Route path={paths.reports.root} component={Reports} />
      <Route
        path={paths.root || "/"}
        component={() => <Redirect to={paths.profile.home} />}
        exact
      />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}
