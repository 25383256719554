import React from "react"
import { Link } from "react-router-dom"
import { useTheme, useMediaQuery } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import { Typography } from "@material-ui/core"

import paths from "../../consts/paths"
import formatter from "../../services/formatter"
import { Container } from "./styles"
import { useAuth } from "../../contexts/Auth"

export default function UserChip() {
  const { currentUser } = useAuth()

  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("xs"))

  if (sm)
    return (
      <Container>
        <Link to={paths.profile.root}>
          <Avatar />
        </Link>
      </Container>
    )

  return (
    <Container className="d-flex gap-4 user-chip-info">
      <div className="w-100 d-flex flex-1 flex-column align-items-end">
        <Typography
          className="d-block font-bold text-uppercase"
          component="span"
          variant="body2"
          color="textPrimary"
        >
          {currentUser?.socialName || "..."}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          {formatter(currentUser?.id).toCNPJ()}
        </Typography>
      </div>
      <div>
        <Link to={paths.profile.root}>
          <Avatar />
        </Link>
      </div>
    </Container>
  )
}
