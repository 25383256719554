import styled, { css } from "styled-components"
import Paper from "../Paper"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`

export const Header = styled.div`
  display: flex;
  background: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 26px;
  width: 100%;
  padding: 1rem 0 0.5rem;

  ${(props) =>
    props.isOver
      ? css`
          overflow: hidden;
          z-index: 104;
          position: sticky;
          top: 0px;
        `
      : css`
          box-shadow: none;
        `}

  div.header-container {
    width: 100%;
    color: ${(props) => props.theme.palette.primary.main};

    display: flex;
    align-items: center;
    gap: 0.5rem;

    .title {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    align-items: flex-start;
    flex-direction: column;
    padding: 14px 0;

    div.header-container {
      justify-content: center;
      margin-bottom: 0.5rem;

      .title {
        font-size: 24px;
      }
    }
  }

  @media print {
    display: none;
  }
`

export const ControlsContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-height: 48px;

  .MuiAutocomplete-root,
  .MuiFormControl-root {
    min-width: 300px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media print {
    gap: 0;
  }
`

export const ChildrenContainer = styled(Paper)`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;

  @media print {
    padding: 0;
  }
`

export const SideContainer = styled.div`
  position: sticky;
  top: 0;

  max-width: 20vw;
  min-width: 400px;
  height: max-content;

  ${(props) => props.theme.breakpoints.down("sm")} {
    display: none;
  }

  transition: ${({ theme }) =>
    theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })};

  @media (max-width: 1700px) {
    max-width: none;
  }
`
