import { darken } from "polished"
import styled from "styled-components"

export const Container = styled.div`
  padding: 2rem 1rem;
  background-color: ${({ theme }) =>
    darken(0.025, theme.palette.background.paper)};
  border: 1px solid
    ${({ theme }) => darken(0.05, theme.palette.background.paper)};

  @media print {
    display: none;
  }
`
