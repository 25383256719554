import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Clear from "@material-ui/icons/Clear"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import TextField from "../TextField"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const SEARCH_TIMEOUT_DELAY_TIME = 500 // 0.5 second

/** This component can only be used inside a `Form` */
export default function SearchField({ onSearch, ...props }) {
  const [search, setSearch] = useState("")

  useEffect(() => {
    let timeOut = undefined

    timeOut = setTimeout(() => {
      onSearch(search)
    }, SEARCH_TIMEOUT_DELAY_TIME)

    return () => {
      clearTimeout(timeOut)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const handleChange = (e) => {
    const searchValue = e.target.value
    setSearch(searchValue)
  }

  const handleClear = () => setSearch("")

  return (
    <TextField
      value={search}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClear} size="small">
              <Clear fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

SearchField.propTypes = {
  onSearch: PropTypes.func.isRequired,
}
