import React, { useMemo } from "react"
import { useTheme, useMediaQuery, Typography } from "@material-ui/core"
import { translate } from "../../../_i18n"
import Table from "../../Table"
import formatter from "../../../services/formatter"

export default function TableOrderItems({ order, ...props }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const items = order?.items || []

  const totalGrossWeight = items.reduce((acc, item) => {
    acc += Number(item.gross_weight)
    return acc
  }, 0)

  const columns = useMemo(() => {
    if (isMobile) {
      return [
        {
          field: "product.id",
          title: "ITEM",
          render: ({ rowData: orderItem }) => (
            <Typography variant="caption">
              {orderItem.product.id} - {orderItem.product.prd_referencia}
            </Typography>
          ),
        },
        {
          field: "product.product_format.dsc_tamanho_produtos",
          title: "ESPECIFIC.",
          render: ({ rowData: orderItem }) => (
            <>
              <Typography variant='caption' display="block"  >
               {orderItem.product.descr}
              </Typography>
              <Typography variant="caption"  color="textSecondary">
                FORMATO: {orderItem.product.product_format.dsc_tamanho_produtos} |  LINHA: {orderItem.product.line.dsc_linha_produtos}
              </Typography>
            </>
          ),
        },
        {
          field: "itped_qtd",
          title: "TOTAL",
          type: "decimal",
          render: ({ rowData: orderItem }) => (
            <>
              <Typography variant="caption" display="block">
                {formatter(orderItem.itped_qtd).toDecimal()}{" "}
                {orderItem.product.unit_of_measure.dsc_un_medidas}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                R$ {formatter(orderItem.total).toDecimal()}
              </Typography>
            </>
          ),
        },
        {
          field: "itped_qtd",
          title: "CARGA",
          type: "decimal",
          render: ({ rowData: orderItem }) => (
            <>
              <Typography variant="caption" display="block">
                {formatter(orderItem.gross_weight).toDecimal()} KG
              </Typography>
              <Typography variant="caption" color="textSecondary">
                PALETES: {orderItem.no_pallets}
              </Typography>
            </>
          ),
        },
      ]
    }

    return [
      { field: "product.id", title: translate("product.code") },
      { field: "product.descr", title: translate("product") },
      {
        field: "product.prd_referencia",
        title: translate("product.ref"),
      },
      {
        field: "product.product_format.dsc_tamanho_produtos",
        title: translate("product.format"),
      },
      {
        field: "itped_qtd",
        title: translate("quantity"),
        align: "right",
        type: "decimal",
        getTotal: () => order?.total_quantity,
      },
      {
        field: "product.unit_of_measure.dsc_un_medidas",
        title: translate("measure.unit"),
      },
      {
        field: "itped_prunit",
        title: translate("unit.value"),
        align: "right",
        type: "decimal",
        getTotal: () => "",
      },
      {
        field: "total",
        title: "Total",
        align: "right",
        type: "currency",
        getTotal: () => order?.total,
      },
      {
        field: "product.line.dsc_linha_produtos",
        title: translate("line"),
      },
      {
        field: "gross_weight",
        title: translate("order.item.weight"),
        align: "right",
        type: "decimal",
        getTotal: () => totalGrossWeight,
      },
      {
        field: "no_pallets",
        title: translate("pallets"),
        align: "right",
        getTotal: () => order?.total_pallets,
      },
      {
        field: "no_boxes",
        title: translate("boxes"),
        align: "right",
      },
    ]
  }, [order, isMobile])

  return (
    <Table
      size="small"
      autoSizing={false}
      data={items}
      columns={columns}
      {...props}
    />
  )
}

TableOrderItems.defaultProps = {
  options: {
    sorting: false,
    paging: false,
    total: true,
  },
}
