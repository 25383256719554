// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

export const KBYTES = 1024

export const BYTES_SUFFIX = ["Bytes", "KB", "MB"]

export const FILE_TYPES = {
  PDF: "pdf",
  APPLICATION_PDF: "application/pdf",
}

export const SUPPORTED_FILE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  FILE_TYPES.APPLICATION_PDF,
]

/**
 *
 * @param {string} fileName - The name of the file to read
 * @param {string} encodedFile - The encoded file to read, base64 encoded
 */
export const previewFile = (fileName, encodedFile) => {
  const preview = window.open("", "_blank")
  const { document: DOM } = preview

  const obj = document.createElement("object")
  obj.style.width = "100%"
  obj.style.height = "100%"
  obj.type = "application/pdf"
  obj.data = `data:application/pdf;base64,${encodedFile}`

  DOM.body.style.margin = "0"
  DOM.title = fileName
  DOM.body.appendChild(obj)
}

/**
 * @param {string} fileName - The name of the file
 * @param {string} encodedFile - The encoded file, base64 encoded
 * @param {string} extension  - The extension of the file, (PDF, DOC, etc)
 */
export const downloadFile = (fileName, encodedFile, extension) => {
  const fileData = `data:${extension};base64,${encodedFile}`
  const downloadLink = document.createElement("a")
  downloadLink.href = fileData
  downloadLink.download = [fileName, extension].join(".")
  downloadLink.click()
}

/**
 *
 * @param {number} fileSize - FileSize
 * @returns {string} - FormattedSize
 */
export const formatFileSize = (fileSize) => {
  const bytes =
    fileSize < KBYTES
      ? fileSize
      : Math.floor(Math.log(fileSize) / Math.log(KBYTES))

  const size = parseFloat((fileSize / Math.pow(KBYTES, bytes)).toFixed(2))
  const suffix = BYTES_SUFFIX[bytes]
  return `${size} ${suffix}`
}

/**
 *
 * @param {string} encodedFile - Encoded file
 * @param {string} type - file type
 * @returns {string} - Encoded file with base64 prefix
 */
export const toBase64String = (encodedFile, type) =>
  encodedFile ? `data:${type};base64,${encodedFile}` : ""

/**
 *
 * @param {string} fileName = File name
 * @returns {string} - File extension
 */
export const getFileExtension = (fileName) =>
  fileName?.split(".").pop().toLowerCase()

/**
 *
 * @param {string} encodedFile - Base64 encoded file
 * @returns {number} - Bytes amount of file
 */
export const getFileSize = (encodedFile) => {
  const pad = encodedFile.slice(-2)
  const padlen = pad === "==" ? 2 : pad.slice(-1) === "=" ? 1 : null
  return (encodedFile.length / 4) * 3 - padlen
}

/**
 *
 * @param {unknown} file - Object containing file data
 * @param {string} fileNameProp
 * @param {string} encodedFileProp
 * @returns {{
 *      fileName: string
 *      size: number
 *      type: string
 *      fileData: string
 *      formattedSize: string
 * }} - File
 */
export const toFile = (file, fileNameProp, encodedFileProp) => {
  const fileName = file[fileNameProp]
  const encoded = file[encodedFileProp] || ""
  const extension = getFileExtension(fileName)
  const size = getFileSize(encoded)
  const type =
    extension === FILE_TYPES.PDF ? FILE_TYPES.APPLICATION_PDF : extension

  return {
    fileName,
    size,
    type,
    fileData: toBase64String(encoded, type),
    formattedSize: formatFileSize(size),
    ...file,
  }
}
