import styled from "styled-components"

export const NewCarrierContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.grey[100]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  padding: 1rem;
  margin-top: 1rem;
`
