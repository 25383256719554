import React from "react"
import AppBar from "../AppBar"
import Drawer from "../Drawer"

export default function Navigation() {
  return (
    <>
      <AppBar />
      <Drawer />
    </>
  )
}
