import React from "react"
import PropTypes from "prop-types"

import { apiEndPoints } from "@/consts/apiEndPoints"
import { FormCombobox } from "@/components/Form"

import useFetch, { useFetchUrl } from "@/hooks/useFetch"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const PRODUCT_GROUTS_STALE_TIME = 1000 * 60 * 1 // 1 minute
const PRODUCT_GROUTS_CLIENT_NAME = "productGrouts"

/** This component can only be used inside a `Form` */
export default function ComboboxProductGrouts(props) {
  const { productCode, ...other } = props

  const url = useFetchUrl(apiEndPoints.miscellaneous.grouts, {
    cod_produto: productCode,
  })

  const {
    data: productGrouts,
    // isFetching,
    isError,
  } = useFetch({
    url,
    clientName: [PRODUCT_GROUTS_CLIENT_NAME, productCode],
    staleTime: PRODUCT_GROUTS_STALE_TIME,
    retry: 0,
  })

  const options = (productGrouts || []).map((grout) => ({
    label: grout.dsc_massa,
    value: grout.dsc_tamanho_produtos,
  }))

  return (
    <FormCombobox
      disabled={!productCode}
      options={options}
      // loading={isFetching}
      error={isError}
      {...other}
    />
  )
}

ComboboxProductGrouts.propTypes = {
  productCode: PropTypes.string,
}
