import React, { useState } from "react"

import api from "../../../../services/api"
import apiEndPoints from "../../../../consts/apiEndPoints"
import formatter from "../../../../services/formatter"
import { useReportsContext } from "../../../../contexts/Reports"

import Table from "../../../../components/Table"
import Filter from "../../../../components/Reports/Filter"
import { FormCombobox } from "../../../../components/Form"
import { CUSTOMER_STATUS_OPTIONS } from "../../../../pages/Representative/Customers"

import { Box } from "@material-ui/core"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const CUSTOMER_ORDER_OPTIONS = [
  { value: "emp_empresa.emp_razao_social", label: "Razão Social" },
  { value: "emp_empresa.emp_cidade", label: "Cidade" },
]

export default function CustomersReport() {
  const { setLoadingText } = useReportsContext()
  const [customers, setCustomers] = useState([])

  const formatAddress = (
    city,
    state,
    neighborhood,
    address,
    number,
    zipCode
  ) => {
    return `${address}, ${number} - ${neighborhood}, ${city} - ${state}, ${formatter(
      zipCode
    ).toCep()}`
  }

  const getShowDetailPanel = (customer) => {
    return (
      customer.deliveryAddresses.length > 1 ||
      customer.billingAddresses.length > 0
    )
  }

  const fetchReport = async (values) => {
    setCustomers([])
    setLoadingText("Montando relatório de clientes...")

    try {
      const response = await api.post(apiEndPoints.reports.customers, {
        filtros: { ...values },
      })

      const customers = response.data.map((customer) => {
        const { company } = customer

        return {
          deliveryAddresses: customer.delivery_addresses,
          billingAddresses: customer.billing_addresses,

          id: formatter(customer.cod_empresa).toCNPJorCPF(),
          socialName: company.emp_razao_social,
          email: customer.cli_email,
          phone: formatter(company.emp_telefone).toTelefone(),
          suframa: customer.cli_suframa,
          address: formatAddress(
            customer.emp_cidade,
            customer.dsc_uf,
            company.emp_bairro,
            company.emp_endereco,
            company.emp_endereco_num,
            company.emp_cep
          ),
        }
      })

      setCustomers(customers)
    } finally {
      setLoadingText("")
    }
  }

  const handleSubmit = (values) => {
    fetchReport(values)
  }

  return (
    <>
      <Filter
        onSubmit={handleSubmit}
        fields={[
          {
            name: "status",
            label: "Status",
            defaultValue: "1",
            size: 2,
            component: FormCombobox,
            componentProps: {
              options: CUSTOMER_STATUS_OPTIONS,
              disableClearable: true,
            },
          },
          {
            name: "ord",
            label: "Ordenação",
            defaultValue: CUSTOMER_ORDER_OPTIONS[0].value,
            size: 2,
            component: FormCombobox,
            componentProps: {
              options: CUSTOMER_ORDER_OPTIONS,
              disableClearable: true,
            },
          },
        ]}
      />

      {/* ==== CLIENTES ==== */}
      {customers.length > 0 && (
        <Table
          size="small"
          data={customers}
          cellStyle={({ column }) =>
            ["address", "socialName"].includes(column.field)
              ? { whiteSpace: "normal" }
              : { whiteSpace: "nowrap" }
          }
          columns={[
            { title: "CNPJ", field: "id" },
            { title: "Razão Social", field: "socialName" },
            { title: "Suframa", field: "suframa" },
            { title: "E-Mail NF-e", field: "email" },
            { title: "Telefone", field: "phone" },
            { title: "Endereço", field: "address" },
          ]}
          getShowDetailPanel={getShowDetailPanel}
          detailPanel={({ row: customer }) => {
            return (
              <Box
                padding="2rem"
                display="flex"
                flexDirection="row"
                style={{ gap: "1rem" }}
              >
                <Box flex="1" border="1px solid #888">
                  <Table
                    size="small"
                    data={customer.deliveryAddresses}
                    columns={[
                      {
                        title: "Endereços de Entrega",
                        field: "descr",
                      },
                    ]}
                  />
                </Box>

                <Box flex="1" border="1px solid #888">
                  <Table
                    size="small"
                    data={customer.billingAddresses}
                    columns={[
                      {
                        title: "Endereços de Cobrança",
                        field: "descr",
                      },
                    ]}
                  />
                </Box>
              </Box>
            )
          }}
        />
      )}
    </>
  )
}
