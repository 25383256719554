import React from "react"
import { Grid, IconButton } from "@material-ui/core"
import { Add, Remove } from "@material-ui/icons"

import { SectionContainer, ButtonsContainer } from "./styles"
import {
  FormHelperText,
  FormTextField,
  useCurrentForm,
} from "@/components/Form"
import { useControlledArray } from "@/hooks"

import ComboboxCities from "@/components/Combobox/Cities"
import ComboboxStates from "@/components/Combobox/States"
import { toMask } from "@/services/masks"
import { telefoneFixo } from "@/consts/regexp"

export function CommercialRefs() {
  const { data, add, remove } = useControlledArray([{ id: "0" }])
  const form = useCurrentForm()

  function handleAdd(index) {
    const newRegister = {
      id: String(index + 1),
    }

    add(newRegister)
  }

  return (
    <SectionContainer>
      {data.map((register, index) => {
        const disabledAddButton = index !== data.length - 1
        const disabledRemoveButton = data.length === 1 || disabledAddButton

        const selectedState = form.getFieldValue(
          `commercial_refs.${index}.eir_dsc_uf`
        )

        return (
          <Grid key={register.id} container spacing={2} alignItems="flex-start">
            <Grid item xs={3}>
              <FormTextField
                fullWidth
                label="Nome"
                size="small"
                variant="outlined"
                name={`commercial_refs.${index}.dsc_inf_referencia`}
              />
            </Grid>
            <Grid item xs={2}>
              <ComboboxStates
                fullWidth
                disableClearable
                label="UF"
                size="small"
                variant="outlined"
                name={`commercial_refs.${index}.eir_dsc_uf`}
              />
            </Grid>
            <Grid item xs={4}>
              <ComboboxCities
                fullWidth
                label="Cidade"
                size="small"
                variant="outlined"
                name={`commercial_refs.${index}.eir_dsc_cidade`}
                state={selectedState}
              />
            </Grid>
            <Grid item xs={2}>
              <FormTextField
                fullWidth
                label="Phone"
                size="small"
                variant="outlined"
                name={`commercial_refs.${index}.eir_fone`}
                textMaskProps={toMask(telefoneFixo.array)}
              />
            </Grid>

            <Grid item xs={1}>
              <ButtonsContainer>
                <IconButton
                  disabled={disabledAddButton}
                  color="primary"
                  title="Adicionar"
                  size="small"
                  onClick={() => handleAdd(index)}
                >
                  <Add />
                </IconButton>

                <IconButton
                  disabled={disabledRemoveButton}
                  className="remove"
                  title="Remover"
                  size="small"
                  onClick={() => remove(register.id)}
                >
                  <Remove />
                </IconButton>
              </ButtonsContainer>
            </Grid>
          </Grid>
        )
      })}

      <FormHelperText name="commercial_refs" />
    </SectionContainer>
  )
}
