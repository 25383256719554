import styled from "styled-components"

export const Container = styled.div`
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.default};

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.refresh {
    z-index: ${(props) => props.theme.zIndex.drawer + 1};
    background-color: transparent;
    padding: 1rem 0;
    top: inherit;
    right: inherit;
    left: 1rem;
    bottom: 1rem;
  }
`
