import React, { useState } from "react"

import api from "../../../../services/api"
import apiEndPoints from "../../../../consts/apiEndPoints"
import { Yup } from "../../../../services/yup"
import { useReportsContext } from "../../../../contexts/Reports"

import Table from "../../../../components/Table"
import Filter from "../../../../components/Reports/Filter"
import Summary from "../../../../components/Reports/Summary"

import ComboboxCompanies from "../../../../components/Combobox/Companies"
import ComboboxCustomers from "../../../../components/Combobox/Customers"
import ComboboxProductRefs from "../../../../components/Combobox/ProductRefs"
import { sumProp } from "../../../../services/utils"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const initialState = {
  report: [],
}

//  ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
//  ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
//  ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const schema = Yup.object().shape({
  di: Yup.string().required("Informe a data inicial").nullable(),
  df: Yup.string().required("Informe a data final").nullable(),
})

export default function WalletOrdersReport() {
  const { setLoadingText, minDate, maxDate } = useReportsContext()
  const [data, setData] = useState({ ...initialState })

  const fetchReport = async (values) => {
    setData({ ...initialState })
    setLoadingText("Montando relatório de pedidos em carteira...")

    try {
      const response = await api.post(apiEndPoints.reports.walletOrders, {
        filtros: { ...values },
      })

      const report = response.data.report
      setData({ report })
    } catch (error) {
      setData({ ...initialState })
    }

    setLoadingText("")
  }

  const handleSubmit = (values) => {
    const params = { ...values }
    fetchReport(params)
  }

  const getCellStyle = () => {
    return {
      fontSize: "0.75rem",
      whiteSpace: "nowrap",
    }
  }

  return (
    <>
      <Filter
        onSubmit={handleSubmit}
        schema={schema}
        fields={[
          {
            name: "di",
            label: "Data Inicial",
            type: "date",
            defaultValue: minDate,
            size: 2,
          },
          {
            name: "df",
            label: "Data Final",
            type: "date",
            defaultValue: maxDate,
            size: 2,
          },
          {
            name: "cod_cia",
            label: "Companhia",
            defaultValue: 1,
            size: 2,
            component: ComboboxCompanies,
            componentProps: {
              disableClearable: true,
            },
          },
          {
            name: "ref",
            label: "Descrição do Produto...",
            defaultValue: "",
            size: 2,
          },
          {
            name: "classif",
            label: "Ref.",
            defaultValue: -1,
            size: 1,
            component: ComboboxProductRefs,
          },
          {
            name: "cod_cliente",
            label: "Cliente",
            defaultValue: "Todos",
            component: ComboboxCustomers,
            componentProps: {
              onLoadOptions: (customer) => ({
                value: customer.id,
                label: customer.emp_razao_social,
              }),
            },
            size: 3,
          },
          {
            name: "gera_fatur",
            label: "Gera Faturamento",
            type: "checkbox",
            defaultValue: 1,
            size: 3,
          },
        ]}
      />

      {/* ==== PEDIDOS EM CARTEIRA ==== */}
      {data.report.length !== 0 && (
        <>
          <Table
            size="small"
            data={data.report}
            options={{
              total: true,
            }}
            cellStyle={getCellStyle}
            columns={[
              { title: "Pedido", field: "cod_pedido" },
              { title: "S.Pedido", field: "ped_seupedido" },
              { title: "Emissão", field: "emissao" },
              { title: "Cliente", field: "cliente" },
              { title: "Produto", field: "dsc_abreviado" },
              { title: "UN", field: "dsc_un_medidas", align: "center" },
              { title: "Ref", field: "prd_referencia", align: "center" },
              { title: "Bit", field: "itped_bitola", align: "center" },
              { title: "Ton", field: "itped_tonalidade", align: "center" },
              { title: "Lote", field: "itped_lote", align: "left" },
              {
                title: "A.Faturar",
                field: "qtdade",
                align: "right",
                type: "decimal",
                getTotal: ({ data }) => sumProp("qtdade", data),
              },
              {
                title: "Reserva",
                field: "reserva",
                align: "right",
                type: "decimal",
              },
              {
                title: "Dt.Reserva",
                field: "dtreserva",
                align: "center",
                // type: "date",
              },
              {
                title: "Pr.Unit",
                field: "itped_prunit",
                align: "right",
                type: "currency",
              },
              {
                title: "Valor",
                field: "valor",
                align: "right",
                type: "currency",
                getTotal: ({ data }) => sumProp("valor", data),
              },
              {
                title: "Pes.Bruto",
                field: "gross_weight",
                align: "right",
                type: "decimal",
              },
              { title: "Progr.", field: "programa", align: "center" },
              { title: "Bloq.", field: "dsc_bloq", align: "center" },
              { title: "Fatur.", field: "dsc_tipo" },
            ]}
          />

          {/* ==== LEGENDA ==== */}
          <Summary />
        </>
      )}
    </>
  )
}
