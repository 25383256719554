import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import api from "../../../services/api"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import {
  CUSTOMER_BLOCK_TYPE,
  CUSTOMER_ACTIVE_TYPE,
} from "../../../consts/types"
import { FormCombobox, FormTextField } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const SEARCH_TIMEOUT_DELAY_TIME = 1000 * 1 // 1 second

/** This component can only be used inside a `Form` */
export default function ComboboxCustomers({
  search: receivedSearch,
  searchUrl,
  onLoadOptions,
  ...props
}) {
  const [search, setSearch] = useState("")
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let timeOut = undefined
    const value = search || receivedSearch

    if (value) {
      setLoading(true)
      setCustomers([])

      timeOut = setTimeout(
        () => {
          api
            .get(searchUrl, {
              params: { search: value },
            })
            .then((response) => setCustomers(response.data))
            .catch(() => setCustomers([]))
            .finally(() => setLoading(false))
        },
        receivedSearch ? 0 : SEARCH_TIMEOUT_DELAY_TIME
      )
    }

    return () => {
      clearTimeout(timeOut)
    }

    // check dep of searchURL
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, receivedSearch])

  const handleChange = async (e) => {
    const searchValue = e.target.value
    setSearch(searchValue)
  }

  const getOptionContent = (customer) => {
    let color = ""

    const isInactive = customer.cli_ativo === CUSTOMER_ACTIVE_TYPE.inactive

    const isPermanentlyBlocked =
      customer.cli_tipo_bloqueio === CUSTOMER_BLOCK_TYPE.permanentlyBlocked

    if (isPermanentlyBlocked) color = "#b28602"
    else if (isInactive) color = "red"
    else color = "currentColor"

    return <span style={{ color }}>{customer.descr}</span>
  }

  const customersOptions = customers.map(
    onLoadOptions ||
      ((c) => ({
        ...c,
        value: c.id,
        label: c.descr,
        optionContent: getOptionContent(c),
      }))
  )

  return (
    <FormCombobox
      options={customersOptions}
      loading={loading}
      // disabled={loading}
      renderInput={(params) => (
        <FormTextField
          value={search}
          onChange={handleChange}
          fullWidth
          variant={props.variant}
          label={props.label}
          name={props.name}
          required={props.required}
          {...params}
        />
      )}
      noOptionsText="Digite para pesquisar o Cliente"
      loadingText="Buscando clientes..."
      {...props}
    />
  )
}

ComboboxCustomers.propTypes = {
  search: PropTypes.string,
  onLoadOptions: PropTypes.func,
  searchUrl: PropTypes.string,
}

ComboboxCustomers.defaultProps = {
  searchUrl: apiEndPoints.miscellaneous.simplifiedCustomers,
}
