import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
} from "react"
import Cookies from "js-cookie"

import apiEndPoints from "../consts/apiEndPoints"
import { api } from "../services/api"
import { handleAjaxError } from "../services/errorHandlers"
import { queryClient } from "../services/queryClient"
import { toast } from "react-toastify"
import { confirmation } from "@/components/Confirmation"

/**
 * @typedef {({
 *      id: string
 *      name: string
 *      socialName: string
 *      email: string
 *      company: unknown
 * })} User
 *
 * @typedef {({
 *      currentUser: User | null
 *      loading: boolean
 *      isAuthenticated: boolean
 *      signIn: (credencials: any) => Promise<boolean>
 *      signOut: () => void
 *      updateCurrentUser: (data: any) => Promise<void>
 * } )}  AuthContext
 *
 * @type {AuthContext}
 */
const AUTH_CONTEXT_INITIAL_STATE = null

export const COOKIE_TOKEN_NAME = "ECO_AUTH_TOKEN"

export const AuthContext = createContext(AUTH_CONTEXT_INITIAL_STATE)

export const useAuth = () => {
  const context = useContext(AuthContext)
  return context
}

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(null)

  async function getUser() {
    const responseUser = await api.get(apiEndPoints.miscellaneous.me)
    const userData = responseUser.data

    return {
      id: userData.id,
      name: userData.descr,
      socialName: userData.emp_razao_social,
      company: userData.company,
    }
  }

  useEffect(() => {
    async function authenticateApp() {
      try {
        const token = Cookies.get(COOKIE_TOKEN_NAME)

        if (token) {
          api.defaults.headers.Authorization = `Bearer ${token}`

          const user = await getUser()
          setCurrentUser(user)
        }
      } catch (error) {
        handleAjaxError(error, "Usuário não authorizado")
      } finally {
        setLoading(false)
      }
    }

    authenticateApp()
  }, [])

  async function signIn(credencials) {
    const { username, password } = credencials

    try {
      const response = await api.post(apiEndPoints.session, {
        username,
        password,
      })

      const { auth_token } = response.data

      api.defaults.headers.Authorization = `Bearer ${auth_token}`
      Cookies.set(COOKIE_TOKEN_NAME, auth_token)

      const user = await getUser()
      setCurrentUser(user)

      return true
    } catch (error) {
      return false
    }
  }

  const signOut = useCallback(() => {
    Cookies.remove(COOKIE_TOKEN_NAME)
    setCurrentUser(null)

    // Important: Clear all cached data on react-query
    queryClient.clear()
  }, [])

  const updateCurrentUser = useCallback(async (data) => {
    const confirm = await confirmation.open(
      "Salvar alterações",
      "Deseja salvar as alterações ?"
    )

    if (!confirm) {
      return
    }

    try {
      await api.post(apiEndPoints.updateCurrentUser, {
        current_password: data.current_password,
        new_password: data.new_password,
      })

      toast.success("Usuário atualizado com sucesso")
    } catch (error) {
      return
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAuthenticated: !!currentUser,
        loading,
        signIn,
        signOut,
        updateCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
