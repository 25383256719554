import { useState } from "react"
import { toast } from "react-toastify"

export default function useControlledArray(
  initialState = [],
  { max = 3 } = {}
) {
  const [data, setData] = useState(initialState)

  function add(register) {
    if (data.length >= max) {
      toast.info("Máximo de registros atingido")
      return
    }

    setData((previousState) => {
      return [...previousState, register]
    })
  }

  function remove(registerId) {
    setData((previousState) => {
      return previousState.filter((reg) => reg.id !== registerId)
    })
  }

  return { data, add, remove }
}
