import React, { useState, useRef, useEffect } from "react"
import { useParams } from "react-router-dom"

import { CUSTOMER_ACTIVE_TYPE, DELIVERY_TYPES } from "@/consts/types"
import api from "@/services/api"
import apiEndPoints from "@/consts/apiEndPoints"

import { Yup, handleValidationError } from "@/services/yup"
import { formatter } from "@/services/formatter"
import { copyObject } from "@/services/utils"
import { translate } from "@/_i18n"

import Grid from "@material-ui/core/Grid"
import BaseDialog from "@/components/BaseDialog"
import { Tabs, TabPanel } from "@/components/Tabs"
import {
  Form,
  FormRadioGroup,
  FormTextField,
  FormTab,
  FormCombobox,
} from "@/components/Form"

import ComboboxCustomers from "@/components/Combobox/Customers"
import ComboboxPaymentConditions from "@/components/Combobox/PaymentConditions"
import ComboboxTableTypes from "@/components/Combobox/TableTypes"
import ComboboxPriceTables from "@/components/Combobox/PriceTables"
import ComboboxCarriers from "@/components/Combobox/Carriers"
import ComboboxDeliveryWeeks from "@/components/Combobox/DeliveryWeeks"

import { FormContainer } from "./styles"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const OBSERVATION_MAX_LENGTH = 180

// ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
// ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const relationScheme = (message) =>
  Yup.object()
    .shape({
      id: Yup.string().required(message).nullable(),
    })
    .required()

const orderFormValidationScheme = Yup.object().shape({
  customer: relationScheme("Selecione o cliente"),
  payment_condition: relationScheme("Selecione a condição de pagamento"),
  delivery_address: relationScheme("Selecione o endereço de entrega"),
  cod_tipo_tabela: Yup.string().required("Selecione a tabela"),
  ped_cod_tpreco: Yup.string().required("Selecione a tabela de preço"),
  ped_semana_entrega: Yup.string().required("Selecione a semana de entrega"),

  carrier: Yup.object().shape({
    id: Yup.string().nullable(),
    name: Yup.string().when("id", (id, scheme) =>
      id === null ? scheme.required() : scheme
    ),
    phone: Yup.string().when("id", (id, scheme) =>
      id === null ? scheme.required() : scheme
    ),
    city: Yup.string().when("id", (id, scheme) =>
      id === null ? scheme.required() : scheme
    ),
  }),
})

export default function OrderFormEdit({ current, onClose, onSave, ...props }) {
  const { orderType } = useParams()

  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [order, setOrder] = useState(null)

  useEffect(() => {
    setSelectedCustomer(null)
    setOrder(null)

    async function initializeForm() {
      formRef.current?.setErrors({})

      if (current?.id) {
        try {
          setLoading(true)

          const response = await api.get(
            apiEndPoints.orders.orderForEdit(current.id)
          )

          const order = response.data
          const orderDeliveryAddress = order.delivery_address

          formRef.current?.setValues({
            id: order.id,
            cod_tipo_tabela: order.cod_tipo_tabela,
            ped_cod_tpreco: order.ped_cod_tpreco,
            ped_seupedido: order.ped_seupedido,
            ped_obs: order.ped_obs,
            ped_vr_frete: order.ped_vr_frete,
            ped_semana_entrega: order.ped_semana_entrega,
            ped_tipo_entrega: order.ped_tipo_entrega,

            customer: { id: order.customer?.id },
            payment_condition: { id: order.payment_condition?.id },
            carrier: { id: order.carrier?.id },
            delivery_address: { id: orderDeliveryAddress?.id },
          })

          setSelectedCustomer({
            ...order.customer,
            delivery_addresses: [orderDeliveryAddress],
          })

          setOrder(order)
        } finally {
          setLoading(false)
        }
      } else {
        formRef.current?.setValues({
          id: null,
          cod_tipo_tabela: "",
          ped_cod_tpreco: "",
          ped_seupedido: "",
          ped_obs: "",
          ped_vr_frete: "",
          ped_semana_entrega: "",
          ped_tipo_entrega: DELIVERY_TYPES.determined,

          customer: { id: null },
          payment_condition: { id: null },
          carrier: { id: null },
        })
      }
    }

    initializeForm()
  }, [current])

  const isNew = current?.id === undefined
  const isEditable = isNew || current?.editable

  // === HANDLERS ===

  function handleChangeTab(_, newTab) {
    setActiveTab(newTab)
  }

  function handleChangeCustomer(_, customer) {
    let message = null

    const isInactive = customer?.cli_ativo === CUSTOMER_ACTIVE_TYPE.inactive

    if (isInactive) message = translate("inactive.customer")
    else message = null

    formRef.current.setFieldError("customer.id", message)
    setSelectedCustomer(customer)
  }

  async function handleSaveOrder() {
    const values = formRef.current.getData()
    const draft = copyObject(values)
    draft.order_type = orderType

    try {
      await orderFormValidationScheme.validate(draft, { abortEarly: false })
      onSave(draft)
    } catch (error) {
      const validationError = handleValidationError(formRef.current)
      validationError(error)
    }
  }

  const title = current
    ? current.id === undefined
      ? translate("order.new")
      : `Edição ${current.cod_pedido}`
    : "..."

  const issuedDate = formatter(
    current?.ped_data_emissao || new Date()
  ).toSimpleDate()

  const deliveryAddressesOptions = selectedCustomer
    ? selectedCustomer.delivery_addresses.map((address) => ({
        value: address.id,
        label: address.descr,
      }))
    : []

  const disableForm = !isEditable

  // Note: Desabilita os campos ultilizados na chamada da procedure `sp_ret_produto_internet` na tela dos items
  const disableFixedFields = disableForm || order?.total > 0

  const disableConfirmButton = disableForm

  return (
    <BaseDialog
      maxWidth="md"
      title={title}
      subtitle={translate("issued.at", {
        date: issuedDate,
      })}
      agreeLabel="Salvar"
      disagreeLabel="Cancelar"
      onConfirm={handleSaveOrder}
      onCancel={onClose}
      disableConfirmButton={disableConfirmButton}
      {...props}
    >
      <FormContainer>
        <Form formRef={formRef}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            TabComponent={FormTab}
            tabs={[
              {
                value: 0,
                label: translate("customer.name"),
              },
              {
                value: 1,
                label: translate("transport"),
              },
            ]}
          />

          {/* ==== CLIENTE === */}
          <TabPanel value={activeTab} index={0} loading={loading}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ComboboxCustomers
                  fullWidth
                  name="customer.id"
                  label={translate("customer.name")}
                  onChange={handleChangeCustomer}
                  search={order?.customer.emp_razao_social}
                  disabled={disableFixedFields}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <ComboboxTableTypes
                  fullWidth
                  name="cod_tipo_tabela"
                  label={translate("table")}
                  disabled={disableFixedFields}
                  customer={selectedCustomer?.id}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ComboboxPriceTables
                  fullWidth
                  name="ped_cod_tpreco"
                  label={translate("price.table")}
                  disabled={disableFixedFields}
                  customer={selectedCustomer?.id}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ComboboxPaymentConditions
                  fullWidth
                  name="payment_condition.id"
                  label={translate("payment.condition")}
                  disabled={disableFixedFields}
                  customer={selectedCustomer?.id}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  fullWidth
                  multiline
                  name="ped_obs"
                  placeholder="Alguma informação adicional sobre o pedido ?"
                  label={translate("order.note")}
                  inputProps={{
                    maxLength: OBSERVATION_MAX_LENGTH,
                  }}
                  disabled={disableForm}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  fullWidth
                  name="ped_seupedido"
                  label={translate("order.yourOrder")}
                  disabled={disableForm}
                />
              </Grid>
            </Grid>
          </TabPanel>

          {/* ==== TRANSPORTE === */}
          <TabPanel value={activeTab} index={1} loading={loading}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormRadioGroup
                  name="ped_tipo_entrega"
                  label={translate("order.deliveryType")}
                  options={[
                    {
                      value: DELIVERY_TYPES.determined,
                      label: "Determinado pelo cliente",
                    },
                    // {
                    //   value: DELIVERY_TYPES.withdrawn,
                    //   label: "Retirado pelo cliente",
                    // },
                    // {
                    //   value: DELIVERY_TYPES.determinedByCompany,
                    //   label: "Determinado pela Cerâmica Savane",
                    // },
                  ]}
                  direction="row"
                  disabled={disableForm}
                />
              </Grid>
              <Grid item xs={12}>
                <ComboboxCarriers
                  name="carrier.id"
                  deliveryTypeName="ped_tipo_entrega"
                  label={translate("carrier")}
                  search={order?.carrier?.id}
                  disabled={disableForm}
                  showNewCarrierContainer
                />
              </Grid>
              <Grid item xs={12}>
                <FormCombobox
                  fullWidth
                  name="delivery_address.id"
                  label={translate("deliveryTo")}
                  disabled={disableForm}
                  options={deliveryAddressesOptions}
                  noOptionsText="Nenhum endereço encontrado"
                />
              </Grid>
              <Grid item xs={12}>
                <ComboboxDeliveryWeeks
                  fullWidth
                  name="ped_semana_entrega"
                  label={translate("delivery.week")}
                  disabled={disableForm}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Form>
      </FormContainer>
    </BaseDialog>
  )
}
