import styled from "styled-components"

export const Container = styled.div`
  position: relative;

  &.loading {
    &::after {
      content: "";
      background-color: rgba(255, 255, 255, 0.6);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: ${(props) => props.theme.zIndex.modal + 1};
    }
  }

  .Loader {
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${(props) => props.theme.zIndex.modal + 2};

    display: flex;
    justify-content: center;
    align-items: center;
  }
`
