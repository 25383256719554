import React from "react"
import PropTypes from "prop-types"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { useFetch } from "../../../hooks"
import { FormCombobox } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const PAYMENT_CONDITIONS_STALE_TIME = 1000 * 60 * 10 // 10 minutes

/** This component can only be used inside a `Form` */
export default function ComboboxPaymentConditions({ customer, ...props }) {
  const { data: paymentConditions, isFetching } = useFetch({
    clientName: ["paymentConditions", customer],
    url: apiEndPoints.miscellaneous.paymentConditions(customer),
    staleTime: PAYMENT_CONDITIONS_STALE_TIME,
    refetchOnWindowFocus: false,
  })

  const paymentConditionsOptions = (paymentConditions || []).map((pc) => ({
    ...pc,
    value: pc.id,
    label: pc.descr || String(pc.id),
  }))

  return (
    <FormCombobox
      options={paymentConditionsOptions}
      loading={isFetching}
      {...props}
    />
  )
}

ComboboxPaymentConditions.propTypes = {
  customer: PropTypes.string,
}

ComboboxPaymentConditions.defaultProps = {
  customer: "",
}
