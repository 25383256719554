import ptBR from "./pt-BR"
import en from "./en-US"
import es from "./es"

const I18N_LIBRARIES = {
  ...ptBR,
  ...en,
  ...es,
}

export default I18N_LIBRARIES
