import React from "react"
import clsx from "clsx"

import { useDrawerContext } from "../../contexts/Drawer"

import { makeStyles } from "@material-ui/styles"
import { useTheme, useMediaQuery } from "@material-ui/core"
import MuiDrawer, { DrawerProps } from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import IconButton from "@material-ui/core/IconButton"
import Divider from "@material-ui/core/Divider"

import ChevronRight from "@material-ui/icons/ChevronRight"
import ChevronLeft from "@material-ui/icons/ChevronLeft"

import MenuItemsCommon from "../Sidebar"

// ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
// ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const useDrawerStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: theme.drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    "& svg.MuiSvgIcon-root, .MuiIconButton-root, .MuiListItemText-root": {
      color: theme.palette.secondary.contrastText,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}))

/**
 * @param {DrawerProps} props
 * @returns {JSX.Element}
 */
export default function Drawer(props) {
  const theme = useTheme()
  const classes = useDrawerStyles()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const { open, onClose, onOpen } = useDrawerContext()

  const drawerProps = () => {
    const drawerProps = { ...props }
    drawerProps.open = open
    drawerProps.onClose = onClose

    if (sm) {
      drawerProps.anchor = "left"
      drawerProps.variant = "temporary"
    } else {
      drawerProps.variant = "permanent"
      drawerProps.className = clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })
      drawerProps.classes = {
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }
    }

    return drawerProps
  }

  return (
    <MuiDrawer {...drawerProps()}>
      <List disablePadding>
        {!xs && (
          <>
            <ListItem
              className="d-flex justify-content-center"
              style={{
                minHeight: "49px",
              }}
            >
              <IconButton size="small" onClick={open ? onClose : onOpen}>
                {open ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </ListItem>
            <Divider />
          </>
        )}
        {/* === ACTIVE MENU ITEMS ==== */}
        <MenuItemsCommon />
      </List>
    </MuiDrawer>
  )
}
