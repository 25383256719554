import styled from "styled-components"
import { toPx } from "../../services/utils"
import MuiContainer from "@material-ui/core/Container"

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  margin-left: ${({ theme }) => toPx(theme.drawerWidthShift)};
  transition: ${({ theme }) =>
    theme.transitions.create("margin-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })};

  &.shift {
    margin-left: ${({ theme }) => toPx(theme.drawerWidth)};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-left: 0;
    &.shift {
      margin-left: 0;
    }
  }

  @media print {
    margin-left: 0;
  }
`

export const Content = styled(MuiContainer)`
  flex: 1;
  margin-top: calc(48px + 1rem);
  margin-bottom: 1rem;
  height: 100%;

  ${(props) => props.theme.breakpoints.down("xs")} {
    padding-top: 1rem;
    margin-top: 0;
  }

  @media print {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;

    #printable {
      box-shadow: none;

      .MuiTableCell-root,
      .MuiTypography-body2 {
        font-size: 12px;
      }
    }

    .proforma-print {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 600px) {
    overflow: overlay;
  }
`
