import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { StyledTabPanel, StyledTabs, StyledTab, StyledBadge } from "./styles"
import { motion } from "framer-motion"

const allyProps = (index) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
})

// ▀█▀ ▄▀█ █▄▄ █▀
// ░█░ █▀█ █▄█ ▄█
export function Tabs({ tabs, TabProps, TabComponent, ...props }) {
  return (
    <StyledTabs
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      {...props}
    >
      {tabs.map((tab, index) => {
        const content =
          tab.error === true ? (
            <StyledBadge color="error" variant="dot">
              <span>{tab.label}</span>
            </StyledBadge>
          ) : (
            <span>{tab.label}</span>
          )

        const tabProps = TabProps ? TabProps(tab) : {}
        const Component = TabComponent || StyledTab

        return (
          <Component
            {...tabProps}
            {...allyProps(index)}
            key={index}
            label={content}
            className={clsx({
              hidden: tab.hidden,
              error: tab.error,
            })}
          />
        )
      })}
    </StyledTabs>
  )
}

Tabs.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      hidden: PropTypes.bool,
      error: PropTypes.bool,
    })
  ),
  TabProps: PropTypes.func,
  TabComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

// ▀█▀ ▄▀█ █▄▄ █▀█ ▄▀█ █▄░█ █▀▀ █░░
// ░█░ █▀█ █▄█ █▀▀ █▀█ █░▀█ ██▄ █▄▄
export function TabPanel({
  value,
  index,
  loading,
  children,
  disableAnimation,
  keepMounted,
  panelId,
  ...props
}) {
  const StyledTabPanelVariant = {
    active: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    inactive: {},
  }

  const childrenVariant = {
    active: {
      transition: {
        duration: 0.3,
      },
      opacity: 1,
    },
    inactive: {
      transition: {
        duration: 0.3,
      },
      opacity: 0,
    },
  }

  const id = `${panelId}-tabpanel-${index}`
  const tabId = `${panelId}-tabpanel-${index}`

  return disableAnimation ? (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={id}
      aria-labelledby={tabId}
      loading={loading}
      {...props}
    >
      {children}
    </StyledTabPanel>
  ) : (
    <motion.div
      variants={StyledTabPanelVariant}
      animate={value === index ? "active" : "inactive"}
      initial="inactive"
      id={id}
    >
      <StyledTabPanel
        role="tabpanel"
        hidden={value !== index}
        id={id}
        aria-labelledby={tabId}
        loading={loading}
        {...props}
      >
        <motion.div key={index} variants={childrenVariant}>
          {keepMounted ? (
            <>{children}</>
          ) : (
            <>{value !== index ? null : children}</>
          )}
        </motion.div>
      </StyledTabPanel>
    </motion.div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  panelId: PropTypes.string,
  keepMounted: PropTypes.bool,
}

TabPanel.defaultProps = {
  loading: false,
  keepMounted: true,
  disableAnimation: false,
  panelId: "simple",
}
