import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ListContainer = styled.div`
  width: 100%;

  .MuiListItem-container {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`
