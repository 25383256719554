import styled from "styled-components"
import { lighten } from "polished"

export const Container = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  .MuiLinearProgress-root {
    height: 1rem;
    border-radius: 20px;

    &.danger {
      background-color: ${(props) =>
        lighten(0.2, props.theme.palette.error.main)};
      .MuiLinearProgress-bar {
        background-color: ${(props) => props.theme.palette.error.main};
      }
    }

    &.warn {
      background-color: ${(props) =>
        lighten(0.2, props.theme.palette.warning.main)};
      .MuiLinearProgress-bar {
        background-color: ${(props) => props.theme.palette.warning.main};
      }
    }

    &.success {
      background-color: ${(props) =>
        lighten(0.2, props.theme.palette.success.main)};
      .MuiLinearProgress-bar {
        background-color: ${(props) => props.theme.palette.success.main};
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`
