import React from "react"
import { BULLETIN_STATUS_TYPES } from "../../../consts/types"
import TextField from "../../TextField"
import Combobox from "../Base"

export default function ComboboxBulletinStatus(props) {
  const options = [
    {
      label: "Não lidos",
      value: BULLETIN_STATUS_TYPES.unreaded,
    },
    {
      label: "Lidos",
      value: BULLETIN_STATUS_TYPES.readed,
    },
    {
      label: "Todos",
      value: BULLETIN_STATUS_TYPES.all,
    },
  ]

  return (
    <Combobox
      {...props}
      id="combo-box-bulletin-status"
      disableClearable
      options={options}
      getOptionLabel={(option) => option.label}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Status" variant="filled" />
      )}
    />
  )
}
