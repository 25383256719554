import React from "react"
import { useCurrentForm } from ".."

import TextField from "../../TextField"
import SelectField from "../../SelectField"
import NumberField from "../../NumberField"
import CheckBox from "../../Checkbox"
import RadioGroup from "../../RadioGroup"
import BaseCombobox from "../../Combobox/Base"
import MultipleCombobox from "../../Combobox/MultipleCombobox"
import { FormDatePicker as DatePicker } from "../../DatePicker"

const withCurrentForm = (Component) => {
  const WithCurrentForm = ({
    name,
    separator,
    onChange,
    helperText,
    ...props
  }) => {
    const {
      errors,
      getFieldValue,
      handleChange: onFormChange,
      readOnly,
    } = useCurrentForm()

    const fieldValue = getFieldValue(name, separator) ?? ""
    const error = errors[name]

    const handleChange = (...args) => {
      // 1. Change form context value
      onFormChange(args[0], separator)

      // 2. Custom onChange
      if (onChange) onChange(...args)
    }

    return (
      <Component
        name={name}
        value={fieldValue}
        onChange={handleChange}
        disabled={Boolean(readOnly || props.disabled)}
        error={Boolean(error)}
        helperText={error || helperText}
        {...props}
      />
    )
  }

  WithCurrentForm.WrappedComponent = Component
  return WithCurrentForm
}

export const FormTextField = withCurrentForm(TextField)
export const FormSelectField = withCurrentForm(SelectField)
export const FormNumberField = withCurrentForm(NumberField)
export const FormCheckBox = withCurrentForm(CheckBox)
export const FormRadioGroup = withCurrentForm(RadioGroup)
export const FormCombobox = withCurrentForm(BaseCombobox)
export const FormDatePicker = withCurrentForm(DatePicker)
export const FormMultipleCombobox = withCurrentForm(MultipleCombobox)

export { default as FormTab } from "./FormTab"
export { default as FormHelperText } from "./FormHelperText"
