import React, { useState, useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { Typography, useMediaQuery, useTheme } from "@material-ui/core"

import api from "../../../services/api"
import apiEndPoints from "../../../consts/apiEndPoints"
import paths from "../../../consts/paths"
import { BULLETIN_STATUS_TYPES } from "../../../consts/types"
import { usePagination, useSearchParams } from "../../../hooks"
import { useNotificationsContext } from "../../../contexts/Notifications"
import { translate } from "../../../_i18n"

import ContentPage from "../../../components/ContentPage"
import ComboboxBulletinStatus from "../../../components/Combobox/BulletinStatus"
import Table from "../../../components/Table"
import Bulletin from "../../../components/Bulletin"
import formatter from "../../../services/formatter"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const BULLETINS_STALE_TIME = 1000 * 30 * 5 // 5 minutes
const BULLETINS_CLIENT_NAME = "bulletins"

export default function Bulletins() {
  const history = useHistory()
  const searchParams = useSearchParams()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { notifications, updateNotification } = useNotificationsContext()
  const withUnreadedBulletins = notifications.bulletins > 0

  const [current, setCurrent] = useState(null)
  const [status, setStatus] = useState(
    withUnreadedBulletins
      ? BULLETIN_STATUS_TYPES.unreaded
      : BULLETIN_STATUS_TYPES.all
  )

  const {
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    calculateOffset,
  } = usePagination()

  const { data, isFetching, refetch } = useQuery(
    [BULLETINS_CLIENT_NAME, { status, page, rowsPerPage }],
    async () => {
      const response = await api.get(apiEndPoints.bulletins.root, {
        params: {
          status,
          page: calculateOffset(),
          page_size: rowsPerPage,
        },
      })

      const bulletins = response.data
      const count = parseInt(response.headers.total)

      return { bulletins, count }
    },
    {
      staleTime: BULLETINS_STALE_TIME,
      retry: 1,
    }
  )

  useEffect(() => {
    const bulletinId = searchParams.get("id")

    if (data && bulletinId) {
      const newBulletin = data.bulletins.find(
        (bulletin) => String(bulletin.id) === bulletinId
      )
      setCurrent(newBulletin || current)
    }
  }, [data, searchParams])

  const handleChange = (_e, newStatus) => {
    setStatus(newStatus.value)
  }

  const handleClose = () => {
    history.push(paths.bulletins.root)
    setCurrent(null)
  }

  const handleRead = () => {
    updateNotification(
      BULLETINS_CLIENT_NAME,
      (previousNotifications) => previousNotifications - 1
    )

    refetch()
  }

  const renderBulletinMobile = ({ rowData: bulletin }) => {
    return (
      <>
        <Typography variant="body2">{bulletin.id}</Typography>
        <Typography gutterBottom color="textSecondary" variant="body2">
          {formatter(bulletin.vc_dinicial).toSimpleDate()}
        </Typography>
      </>
    )
  }

  const columns = useMemo(() => {
    if (isMobile) {
      return [
        {
          field: "id",
          title: translate("bulletins.comunicado"),
          render: renderBulletinMobile,
        },
        {
          field: "vc_remetente",
          title: translate("bulletins.remetente"),
        },
        {
          field: "vc_assunto",
          title: translate("bulletins.assunto"),
          render: ({ rowData: bulletin }) => (
            <Link to={`${paths.bulletins.root}?id=${bulletin.id}`}>
              {bulletin.vc_assunto}
            </Link>
          ),
        },
      ]
    }

    return [
      {
        field: "id",
        title: translate("bulletins.comunicado"),
      },
      {
        field: "vc_dinicial",
        title: translate("bulletins.emissao"),
        type: "date",
      },
      {
        field: "vc_remetente",
        title: translate("bulletins.remetente"),
      },
      {
        field: "vc_assunto",
        title: translate("bulletins.assunto"),
        render: ({ rowData: bulletin }) => (
          <Link to={`${paths.bulletins.root}?id=${bulletin.id}`}>
            {bulletin.vc_assunto}
          </Link>
        ),
      },
    ]
  })

  return (
    <>
      <ContentPage
        title={translate("bulletins.page.title")}
        controls={
          <>
            <ComboboxBulletinStatus value={status} onChange={handleChange} />
          </>
        }
      >
        <Table
          data={data?.bulletins || []}
          columns={columns}
          page={page}
          rowsPerPage={rowsPerPage}
          count={data?.count || 0}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          loading={isFetching}
          options={{
            paging: true,
          }}
        />
      </ContentPage>
      {!!current && (
        <Bulletin
          open
          id={current.id}
          subject={current.vc_assunto}
          content={current.vc_mensagem}
          sender={current.vc_remetente}
          date={current.vc_dinicial}
          recipient={current.recipient}
          onClose={handleClose}
          onConfirm={handleClose}
          onRead={handleRead}
        />
      )}
    </>
  )
}
