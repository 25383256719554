import { LOCALES } from "../locales"

export const ptBR = {
  [LOCALES.PORTUGUESE_BR]: {
    /** LANGUAGES */
    english: "Inglês",
    spanish: "Espanhol",
    portuguese: "Português",
    /** HOME */
    "home.dashboard.quotaAchievement": "DISPONÍVEL DA COTA DE PEDIDO",
    "home.dashboard.averagePortifolioOrders":
      "TEMPO MÉDIO DE PEDIDOS EM CARTEIRA",
    "home.dashboard.goal": "META FINANCEIRO",
    "home.dashboard.consumptionQuotaByRepresentative":
      "CONSUMO DA COTA POR REPRESENTANTE",
    "home.dashboard.representativesWithHigherBilling":
      "REPRESENTANTES COM MAIOR FATURAMENTO",
    "home.dashboard.customersWithHigherBilling":
      "CLIENTES COM MAIOR FATURAMENTO",
    "home.dashboard.customersWithMoreDelayedTitles":
      "CLIENTES COM MAIS TÍTULOS EM ATRASO",
    "home.dashboard.lastSixMonthsBilling": "FATURAMENTO NOS ÚLTIMOS 6 MESES",
    "home.dashboard.loadingText": "Carregando suas informações...",
    "home.dashboard.updatedAt": "Atualizado em {date}",

    /** ORDERS */
    "order.page.title": "Meus Pedidos",
    "order.menu.title": "Meus Pedidos",
    "order.yourOrder": "Seu pedido",
    "order.productType": "Tipo do produto",
    "order.note": "Observação",
    "order.futureDelivery": "Entrega futura",
    "order.bnds": "BNDES",
    "order.bearer": "Portador",
    "order.flag": "Bandeira",
    "order.numberCard": "Nº do Cartão",
    "order.expirationDate": "Validade",
    "order.securityNumber": "Cód.Segurança",
    "order.numParcels": "Nº de Parcelas",
    "order.indirectExport": "Exportação indireta",
    "order.deliveryType": "Tipo de transporte",
    "order.deliveryType.withdrawnByCustomer": "Retirado pelo cliente",
    "order.deliveryType.determinedByCustomer": "Determinado pelo cliente",
    "order.new": "Novo Pedido",
    "order.item.weight": "P.Bruto",
    "order.item.quantity": "Qtdade",
    "order.item.includeItemtext": "Incluir item no Pedido",
    order: "Pedido",
    budget: "Orçamento",
    outlet: "Ponta",
    "order.item.title": "{order} | Itens",
    "order.item.excludeText": "Confirmar exclusão do produto ({product})?",
    "order.item.excludeTextLastItemConfirm": "Confirma a exclusão do pedido ?",
    "order.item.excludeTextLastItem":
      "Ao excluir o último item. o pedido será excluido também.",
    "order.report.title": "Pedido de Venda ({order})",
    "order.report.orderData": "Dados do pedido",
    "order.futureDelivery.initials": "EF",
    "order.futureDelivery.title": "Entrega Futura",
    "order.futureDelivery.title.missingInfo":
      "Entrega Futura - Necessário completar as informações",
    "order.hints.canApprove": "Liberar para Avaliação",
    "order.hints.canOrder": "Gerar Pedido de Venda",
    "order.hints.canBudget": "Voltar Orçamento",
    "order.budgetConfirm.title": "{previousOrderType} -> {newOrderType}",
    "order.budgetConfirm.text": "Confirma a geração do {orderType} {order} ?",
    "order.budgetConfirm.textSecondary":
      "Alguns itens do orçamento não possuem saldo disponível. Deseja continuar sem esses itens ?",
    "order.budgetConfirm.buttonLabel": "Gerar {orderType}",
    "order.budgetConfirm.message.success":
      "{orderType} {order} gerado com sucesso!",
    "order.budgetApproval.title": "Liberar Orçamento",
    "order.budgetApproval.text": "Confirma a liberação do Orçamento {order} ?",
    "order.budgetApproval.buttonLabel": "Liberar Orçamento",
    "order.budgetApproval.message.success":
      "Orçamento {order} liberado com sucesso!",

    /** PROFORMAS */
    "proforma.page.title": "Minhas Proformas",
    "proforma.codigo": "Proforma No.",
    "proforma.emissao": "Emissão:",
    "proforma.codpagto": "Condições Pagamento",
    "proforma.paisorigem": "País de Origem",
    "proforma.importador": "Importador",
    "proforma.previsaoemb": "Previsão de Embarque",
    "proforma.moeda": "Moeda",
    "proforma.marca": "Marca",
    "proforma.viatransporte": "Via de Transporte",
    "proforma.portoembarque": "Porto de Embarque",
    "proforma.portodescarga": "Porto de Descarga",
    "proforma.localentrega": "Local de Entrega",
    "proforma.numpallets": "# de Paletes",
    "proforma.declaracao": "Declaração",
    "proforma.numcontainers": "# de Containers",
    "proforma.pesobruto": "Peso Bruto",
    "proforma.pesoliquido": "Peso Líquido",
    "proforma.item.pallets": "Paletes",
    "proforma.item.caixas": "Caixas",
    "proforma.item.sku": "SKU",
    "proforma.item.pecas": "Peças",
    "proforma.item.metros": "M2",
    "proforma.item.descricao": "Descrição das Mercadorias",
    "proforma.item.pesobruto": "Peso Bruto",
    "proforma.item.pesoliquido": "Peso Líquido",
    "proforma.item.precounitario": "Preço Un.",
    "proforma.item.total": "Total",
    "proforma.sendAttachment.title": "Enviar proforma assinada pelo cliente",
    "proforma.attachments.title": "Anexos ({proforma})",
    "proforma.finish": "Finalizar Proforma ({proforma}) ?",
    "proforma.copy": "Copiar Proforma",
    "proforma.sendAttachment.inputLabel": "Clique para adicionar os anexos",
    "proforma.visto": "Visto",
    "proforma.subtotal": "Valor Sub-Total {currency}",
    "proforma.new": "Nova Proforma",
    "proforma.approve": "Aprovar",
    "proforma.sendApprovalEmail": "Enviar email de aprovação",
    "proforma.resendApprovalEmail": "Reenviar email de aprovação",
    "proforma.excludeText": "Confimar exclusão da Proforma ({proforma})?",
    "proforma.loadingText": "Carregando sua proforma {proforma}...",
    "proforma.upload.loadingText": "Fazendo upload dos arquivos...",
    "proforma.download.loadingText": "Fazendo Download dos arquivos...",
    proforma: "Proforma",
    "proforma.menu.title": "Minhas Proformas",
    "proforma.item.excludeText": "Confirmar exclusão do produto ({product})?",
    "proforma.item.excludeTextLastItemConfirm":
      "Confirma a exclusão do Item e da Proforma ?",
    "proforma.item.excludeTextLastItem":
      "Como esse é o último item, a Proforma será excluída também.",
    language: "Idioma",
    table: "Tabela",
    "price.table": "Tabela de preço",
    "payment.condition": "Condição de pagamento",
    "billing.type": "Tipo de faturamento",
    "pallets.container": "Paletes por Container",
    freight: "Frete",
    "delivery.week": "Semana de entrega",
    "document.type": "Documento",
    "document.kind": "Tipo do Documento",
    "document.number": "Número do Documento",
    "document.date": "Data do Documento",
    "arrival.place": "Porto de Destino",
    "boarding.place": "Porto de Origem",
    "discharge.place": "Porto de Descarga",
    transport: "Transporte",
    transportation: "Meio de Transporte",
    "unit.value.per.box": "Valor Unitário por Caixa",
    "planned.shipment": "Previsão de Embarque",
    /** ITEMS */
    items: "Itens",
    item: "Item",
    "product.group": "Grupo de Produtos",
    "product.format": "Formato",
    "product.formatText": "FORMATO {format}",
    "product.lineText": "LINHA {line}",
    "product.code": "Código",
    "product.ref": "Ref.",
    "product.specs": "Bit: {gauge} - Ton: {hue}",
    "measure.unit": "Un.",
    "unit.value": "Vl.Unit.",
    "approved.value": "Valor Aprov.",
    "new.item": "Novo Item",
    /** REPORTS */
    "bulletins.menu.title": "Comunicados",
    "bulletins.page.title": "Comunicados",
    "bulletins.comunicado": "Comunicado",
    "bulletins.emissao": "Emissão",
    "bulletins.remetente": "Remetente",
    "bulletins.assunto": "Assunto",
    "bulletin.subject": "Assunto: {subject}",
    "bulletin.sendedBy": "Enviado por {sender} em {date}",
    "bulletin.attachments.label": "Anexos",
    /** TERMS */
    "terms.menu.title": "Termos",
    "terms.page.title": "Termos",
    "terms.comunicado": "Termo",
    "terms.emissao": "Emissão",
    "terms.remetente": "Remetente",
    "terms.assunto": "Assunto",
    "term.subject": "Assunto: {subject}",
    "term.sendedBy": "Enviado por {sender} em {date}",
    "term.attachments.label": "Anexos",
    /** RELATORIOS */
    reports: "Relatórios",
    report: "Relatório - {report}",
    "generate.report": "Gerar Relatório",
    "update.report": "Atualizar",
    "reports.paidinstallments": "Títulos Liquidados",
    "calculation.basis": "Base de Cálculo",
    "delete.already.paid": "Excluir já pagas?",
    "report.loadingText": "Carregando relatório {report}...",
    "report.commissions.predicted": "Comissões Previstas por Período",
    "report.commissions.paidOff": "Cheques Quitados por Período",
    "report.commissions.returned": "Cheques Devolvidos por Período",
    /** VERBS */
    cancel: "Cancelar",
    canceled: "Cancelada",
    changeLanguage: "Mudar Idioma",
    clear: "Limpar",
    close: "Fechar",
    loadingOptions: "Carregando opções...",
    loadingrecords: "Carregando registros",
    print: "Imprimir",
    reload: "Recarregar",
    save: "Salvar",
    finish: "Finalizar",
    send: "Enviar",
    sendTo: "Enviar para o {receiver}",
    release: "Liberar",
    search: "Buscar",
    select: "Selecionar",
    "searching.customers": "Buscando Clientes...",
    "search.filters": "Filtros",
    edit: "Editar",
    view: "Visualizar",
    delete: "Excluir",
    /** FLAGS */
    created: "Criado",
    pending: "Pendente...",
    accepted: "Aceito",
    finalized: "Finalizada",
    reserved: "Reservado",
    partiallyReserved: "Parc. Reser.",
    released: "Liberado",
    approved: "Aprovado",
    finished: "Baixada",
    waiting: "Aguardando",
    rejected: "Rejeitado",
    unfulfilled: "Abertos",
    fulfilled: "Baixados",
    cancelled: "Cancelados",
    /** MESSAGES */
    nonReversibleAction: "Alerta: Essa ação não pode ser revertida",
    "messages.sendMail": "Email enviado com sucesso.",
    /** ERRORS */
    "report.error.competenceIsMissing": "Competência é requirida",
    "no.value": "Valor não informado",
    "value.lower": "Valor deve ser menor que {value}",
    "value.higher": "Valor deve ser maior que {value}",
    "blocked.customer":
      "Cliente está bloqueado, entre em contato com o departamento financeiro.",
    "inactive.customer":
      "Cliente está inativo, ao inserir um pedido será ativado novamente.",
    "order.sample.typeProduct.required": "Escolha o tipo de produto",
    /** PAGINATOR */
    "records.per.page": "Registros por página",
    pagedescr: "Página: {number} de {total}",
    norecordsfound: "Nenhum registro encontrado",
    registers: "Registros encontrados: {count}",
    /** TERMS */
    agent: "Preposto",
    all: "Todos",
    back: "Voltar",
    carrier: "Transportadora",
    "carrier.add": "Transportadora adicional {num}",
    city: "Cidade",
    bank: "Bco",
    agency: "Ag.",
    account: "Conta",
    cheque: "Cheque",
    commissions: "Comissões",
    commision: "Comissão",
    commercial: "Venda",
    company: "Companhia",

    competence: "Competência",
    currency: "Moeda",
    "customer.name": "Cliente",
    deliveryTo: "Entregar em",
    "customer.document": "CPF/CNPJ",
    days: "dias",
    "due.date": "Vencimento",
    document: "CPF/CNPJ",
    issue: "Emissão",
    "issued.date": "Emissão",
    "issued.at": "Emitido em {date}",
    predicted: "Previstas",
    paidOffs: "Quitadas",
    paidOff: "Quitado",
    quantity: "Quantidade",
    hue: "Tonalidade",
    gauge: "Bitola",
    quality: "Qualidade",
    readed: "Lidos",
    representative: "Representante",
    returneds: "Devolvidas",
    returned: "Devolvido",
    sample: "Amostra",
    state: "Estado",
    total: "Total",
    title: "Título",
    today: "Hoje",
    unreaded: "Não lidos",
    value: "Valor",
    brand: "Marca",
    group: "Grupo",
    line: "Linha",
    product: "Produto",
    kit: "Kit",
    parts: "Peças",
    boxes: "Caixas",
    tags: "Etiquetas",
    pallets: "Paletes",
    pallet: "Palete",
    goal: "Meta {goal}",
    arOrder: "Entrega Futura",
    customersServed: "Clientes Atendidos",

    _requiredField: "{field} é requerido",
    defaultDialogMessage: "Confirmar a exclusão do {type} ({register}) ?",
    handleAjaxError: "Ocorreu um erro de comunicação com o servidor: {err}",
    handleUnprocessableEntity:
      "Por favor corrija todos os erros reportados pelo servidor.",
    signout: "Sair",
  },
}

export default ptBR
