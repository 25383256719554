import styled, { css } from "styled-components"
import Panel from "../Panel"

export const StyledPanel = styled(Panel)`
  padding: 2rem 1rem;
  margin: auto;
  max-width: 600px;
  min-height: calc(100vh - 249px);
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    if (props.size === "lg") {
      return css`
        max-width: 980px;
        display: block;

        form {
          width: 100%;
        }
      `
    }
  }}

  @media (max-width: 600px) {
    align-items: flex-start;
  }
`
