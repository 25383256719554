import React, { useMemo, useState } from "react"
import { usePagination, useSortData } from "../../../hooks"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { useQuery } from "react-query"

import api from "../../../services/api"
import formatter from "../../../services/formatter"
import { handleUnprocessableEntity } from "../../../services/utils"
import { CUSTOMER_ACTIVE_TYPE } from "../../../consts/types"
import apiEndPoints from "../../../consts/apiEndPoints"

import ContentPage from "../../../components/ContentPage"
import Table from "../../../components/Table"
import Button from "../../../components/Button"
import Chip from "../../../components/Chip"
import Combobox from "../../../components/Combobox/Base"
import TextField from "../../../components/TextField"
import EditIcon from "../../../components/Icons/Edit"

import AddIcon from "@material-ui/icons/Add"
import Typography from "@material-ui/core/Typography"

import FormEdit from "./FormEdit"
import { translate } from "../../../_i18n"
import { toast } from "react-toastify"
import { MobileTableDiv } from "./styles"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const CUSTOMERS_STALE_TIME = 0
const CUSTOMERS_CLIENT_NAME = "customers"
const CUSTOMERS_DEFAULT_SORT = "cli_ativo"

export const CUSTOMER_STATUS_OPTIONS = [
  { value: "1", label: "Ativos" },
  { value: "0", label: "Inativos" },
  { value: "-1", label: "Todos" },
]

const initialData = {
  customers: [],
  count: 0,
}

export default function Customers() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [status, seStatus] = useState(CUSTOMER_STATUS_OPTIONS[0].value) // Active

  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(null)

  const { currentSort, onSortChange, getSortString } = useSortData({
    initialField: CUSTOMERS_DEFAULT_SORT,
  })
  const {
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    calculateOffset,
  } = usePagination()

  const { data, isFetching, refetch } = useQuery(
    [CUSTOMERS_CLIENT_NAME, { status, page, rowsPerPage, currentSort }],
    async () => {
      const response = await api.get(apiEndPoints.customers.root, {
        params: {
          order: getSortString(),
          page: calculateOffset(),
          page_size: rowsPerPage,
          status,
        },
      })

      const count = parseInt(response.headers.total)
      const customers = response.data.map((customer) => ({
        ...customer,
        editable: true,
      }))

      return { customers, count }
    },
    {
      staleTime: CUSTOMERS_STALE_TIME,
      refetchOnWindowFocus: false,
      initialData,
    }
  )

  const createCustomer = async (customer, form) => {
    setLoading(true)

    try {
      await api.post(apiEndPoints.customers.root, customer)

      toast.success("Cliente criado com sucesso")
      refetch()
      handleClose()
    } catch (error) {
      const errors = handleUnprocessableEntity(error)
      form.setErrors(errors)
    }

    setLoading(false)
  }

  const updateCustomer = async (customer, form) => {
    setLoading(true)

    try {
      await api.put(`${apiEndPoints.customers.root}/${customer.id}`, customer)
      toast.success("Cliente atualizado com sucesso")
      refetch()
      handleClose()
    } catch (error) {
      const errors = handleUnprocessableEntity(error)
      form.setErrors(errors)
    }

    setLoading(false)
  }

  const handleChangeStatus = (_, newStatus) => {
    seStatus(newStatus.value)
  }

  const handleNewCustomer = () => {
    setCurrent({})
  }

  const handleEditCustomer = (customer) => {
    setCurrent(customer)
  }

  const handleClose = () => {
    setCurrent(null)
  }

  const handleSave = (customer, form) => {
    if (current.id) updateCustomer(customer, form)
    else createCustomer(customer, form)
  }

  const renderCustomerAddress = ({ rowData: customer }) => {
    const company = customer.company
    return (
      <div>
        <Typography variant="body2">
          {customer.emp_cidade.toUpperCase()} - {customer.dsc_uf.toUpperCase()}
        </Typography>
        <Typography variant="body2">
          {company.emp_endereco.toUpperCase()}, {company.emp_endereco_num} -{" "}
          {company.emp_bairro.toUpperCase()}
        </Typography>
        {company.emp_compl_endereco && (
          <Typography color="textSecondary" variant="body2">
            {company.emp_compl_endereco}
          </Typography>
        )}
      </div>
    )
  }

  const renderCustomerStatus = ({ rowData: customer }) => {
    const isActive = customer.cli_ativo === CUSTOMER_ACTIVE_TYPE.active
    return (
      <Chip
        size="small"
        label={isActive ? "Ativo" : "Inativo"}
        background={isActive ? "success" : "error"}
      />
    )
  }

  const renderCustomerMobile = ({ rowData: customer }) => {
    const isActive = customer.cli_ativo === CUSTOMER_ACTIVE_TYPE.active
    return (
      <>
        <MobileTableDiv>
          <div>
            <Typography variant="body2">
              {customer.company.emp_razao_social}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {formatter(customer.id).toCNPJorCPF()}
            </Typography>
          </div>
          <Chip
            size="small"
            label={isActive ? "Ativo" : "Inativo"}
            background={isActive ? "success" : "error"}
          />
        </MobileTableDiv>
      </>
    )
  }

  const columns = useMemo(() => {
    if (isMobile) {
      return [
        {
          title: "Cliente",
          field: "company.emp_razao_social",
          render: renderCustomerMobile,
        },
      ]
    }

    return [
      {
        title: "CNPJ",
        field: "cod_empresa",
        type: "document",
        render: ({ cellValue }) => (
          <span className="text-nowrap">{cellValue}</span>
        ),
      },
      {
        title: "Razão Social",
        field: "company.emp_razao_social",
      },
      {
        title: "E-Mail",
        field: "cli_email",
        render: ({ cellValue }) => (
          <span className="text-lower">{cellValue}</span>
        ),
      },
      {
        title: "Endereço",
        field: "company.emp_endereco",
        render: renderCustomerAddress,
      },
      {
        title: "Telefone",
        field: "company.emp_telefone",
        type: "phone",
        render: ({ cellValue }) => (
          <span className="text-nowrap">{cellValue}</span>
        ),
      },
      {
        title: "Status",
        field: "cli_ativo",
        align: "center",
        render: renderCustomerStatus,
      },
    ]
  }, [isMobile])

  return (
    <>
      <ContentPage
        title="Clientes"
        controls={
          <>
            <Combobox
              style={{ width: 250 }}
              disableClearable
              options={CUSTOMER_STATUS_OPTIONS}
              value={status}
              onChange={handleChangeStatus}
              renderInput={(props) => (
                <TextField label="Status" variant="filled" {...props} />
              )}
            />
          </>
        }
      >
        <Table
          size="small"
          loading={isFetching}
          data={data?.customers}
          currentSort={currentSort}
          page={page}
          rowsPerPage={rowsPerPage}
          count={data?.count || 0}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          columns={columns}
          options={{
            paging: true,
            sorting: true,
          }}
          actions={[
            {
              title: translate("edit"),
              onClick: handleEditCustomer,
              icon: (customer) => (
                <EditIcon isEditable={customer.editable} fontSize="small" />
              ),
            },
          ]}
        />
      </ContentPage>
      <div className="mt-3 d-flex justify-content-end">
        <Button
          color="primary"
          variant="contained"
          size="large"
          startIcon={<AddIcon />}
          onClick={handleNewCustomer}
        >
          Novo Cliente
        </Button>
      </div>

      <FormEdit
        loading={loading}
        current={current}
        open={!!current}
        onClose={handleClose}
        onSave={handleSave}
        isFinalCustomer={false}
      />
    </>
  )
}
