import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import {
  StyledCard,
  StyledCardHeader,
  StyledCardBody,
  StyledCardFooter,
  StyledCardGradientBox,
  StyledSkeleton,
} from "./styles"

/**
 * Dashboard card component
 *
 * @typedef {({
 *      Header: {
 *          color: 'primary' | 'info' | 'success' | 'error'
 *          Icon: JSX.Element
 *          size: 'small' | 'large'
 *          title: string | object
 *          primary: string | object
 *          secondary: string | object
 *      }
 *      Footer: {
 *          Icon: JSX.Element
 *          title: string | object
 *      }
 * })} CardProps
 *
 * @param {CardProps} props
 * @returns {JSX.Element}
 */
export default function Card({
  Footer,
  Header,
  children,
  isLoading,
  ...props
}) {
  const isLargeHeader = Header.size === "large"

  if (isLoading) {
    return (
      <div style={{ position: "relative" }}>
        <StyledSkeleton
          animation="wave"
          variant="rect"
          className={clsx("skeleton-card-header", {
            large: isLargeHeader,
          })}
        />
        <StyledSkeleton variant="rect" className="skeleton-card" />
      </div>
    )
  }

  return (
    <StyledCard {...props}>
      <StyledCardHeader className={clsx({ Large: isLargeHeader })}>
        {isLargeHeader ? (
          <StyledCardGradientBox
            className={clsx(Header.color, {
              lg: isLargeHeader,
            })}
          >
            <Typography variant="subtitle1">{Header.title}</Typography>
          </StyledCardGradientBox>
        ) : (
          <Box>
            <StyledCardGradientBox className={Header.color}>
              <Header.Icon fontSize="large" />
            </StyledCardGradientBox>
            <Typography variant="subtitle1">{Header.title}</Typography>
            <Typography
              component="div"
              className="d-flex gap-2 justify-content-end align-items-center"
              variant="subtitle2"
            >
              {Header.primary}
              <Typography component="span" variant="body2">
                {Header.secondary}
              </Typography>
            </Typography>
          </Box>
        )}
      </StyledCardHeader>

      <StyledCardBody>{children}</StyledCardBody>

      {Footer ? (
        <StyledCardFooter className="gap-2">
          <Footer.Icon fontSize="small" />
          <Typography variant="body2">{Footer.title}</Typography>
        </StyledCardFooter>
      ) : null}
    </StyledCard>
  )
}

const Text = PropTypes.oneOfType([PropTypes.string, PropTypes.object])

Card.propTypes = {
  Header: PropTypes.shape({
    color: PropTypes.string,
    Icon: PropTypes.object,
    size: PropTypes.oneOf(["small", "large"]),
    title: Text,
    primary: Text,
    secondary: Text,
  }).isRequired,
  Footer: PropTypes.exact({
    Icon: PropTypes.object,
    title: Text,
  }),
}
