import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Link, useLocation } from "react-router-dom"

import { EXTRACT_URL_PARAMS_REGEXP } from "../../consts/regexp"

import useTheme from "@material-ui/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import List from "@material-ui/core/List"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import Divider from "@material-ui/core/Divider"
import Badge from "@material-ui/core/Badge"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { useDrawerContext } from "../../contexts/Drawer"

import { StyledListItem, CollapseListItem } from "./styles"
import { IconButton } from "@material-ui/core"

export default function MenuItem(props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const location = useLocation()
  const drawer = useDrawerContext()
  const [openCollpase, setOpenCollpase] = useState(false)

  useEffect(() => {
    // Close all collapse menus on close drawer
    if (!drawer.open) setOpenCollpase(false)
  }, [drawer.open])

  const handleShowCollapse = () => {
    setOpenCollpase(!openCollpase)
    drawer.onOpen()
  }

  const isCollapse = props.options.length > 0

  const isActiveCollapse = (value) => location.pathname.includes(`/${value}`)

  const isActive = () => {
    if (isCollapse) {
      const withAnyOptionActive = props.options.reduce((acc, option) => {
        if (!acc) {
          acc = isActiveCollapse(option.value)
          return acc
        } else return true
      }, false)

      return withAnyOptionActive
    } else if (props.isActive)
      return props.isActive(location.pathname, props.to)
    else return location.pathname === props.to
  }

  const handleCloseMobile = () => {
    if (isMobile) drawer.onClose()
  }

  const handleClick = () => {
    if (isCollapse) handleShowCollapse()
    else handleCloseMobile()
  }

  return (
    <>
      <StyledListItem
        button
        to={props.to}
        selected={isActive()}
        component={isCollapse ? "li" : Link}
        onClick={handleClick}
        className={clsx({ shift: !drawer.open })}
      >
        <ListItemIcon>
          <Badge
            badgeContent={props.badgeContent}
            overlap="rectangular"
            color="error"
          >
            <props.Icon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={props.label} />

        {isCollapse && (
          <IconButton size="small">
            {openCollpase ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </StyledListItem>

      {/* ==== COLLAPSE ==== */}
      {isCollapse && (
        <Collapse in={openCollpase} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.options.map((option, index) => {
              const to = props.to.replace(
                EXTRACT_URL_PARAMS_REGEXP,
                option.value
              )
              return (
                <CollapseListItem
                  key={index}
                  to={to}
                  button
                  selected={isActiveCollapse(option.value)}
                  component={Link}
                  onClick={handleCloseMobile}
                >
                  <ListItemIcon />
                  <ListItemText primary={option.label} />
                </CollapseListItem>
              )
            })}
          </List>
          <Divider />
        </Collapse>
      )}
    </>
  )
}

MenuItem.propTypes = {
  badgeContent: PropTypes.number,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  to: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      value: PropTypes.string,
    })
  ),
  isActive: PropTypes.func,
}

MenuItem.defaultProps = {
  options: [],
  badgeContent: 0,
}
