import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .MuiTableCell-sizeSmall {
    font-size: 12px;
    padding: 4px 8px;
    /* border-bottom: none; */
  }

  .MuiTableRow-hover:hover {
    /* background-color: #ffeb3b; */
  }
`
