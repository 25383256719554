import React from "react"
import PropTypes from "prop-types"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { useFetch } from "../../../hooks"
import { FormCombobox } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const STALE_TIME = 1000 * 60 * 10 // 10 minutes

/** This component can only be used inside a `Form` */
export default function ComboboxTableTypes({customer, ...props}) {
  const { data, isFetching } = useFetch({
    clientName: ["tableTypes", customer],
    url: customer ? apiEndPoints.miscellaneous.tableTypes(customer) : null,
    staleTime: STALE_TIME,
  })

  const options = (data || []).map((item) => ({
    value: item.cod_tipo_tabela,
    label: item.cod_tipo_tabela,
  }))

  return (
    <FormCombobox
      disableClearable
      options={options}
      loading={isFetching}
      {...props}
    />
  )
}

ComboboxTableTypes.propTypes = {
  customer: PropTypes.string
}

ComboboxTableTypes.defaultProps = {
  customer: ''
}