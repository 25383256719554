import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

import CircularProgress from "@material-ui/core/CircularProgress"
import { Container } from "./styles"

export default function Panel({ loading, children, className, ...props }) {
  return (
    <Container className={clsx(className, { loading })} {...props}>
      {loading && (
        <div className="Loader">
          <CircularProgress size={45} color="primary" />
        </div>
      )}
      {children}
    </Container>
  )
}

Panel.propTypes = {
  loading: PropTypes.bool,
}
