import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import api from "../../../services/api"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { FormMultipleCombobox, FormTextField } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const SEARCH_TIMEOUT_DELAY_TIME = 1000 * 1 // 1 second

/** This component can only be used inside a `Form` */
export default function ComboboxReferences({
  search: receivedSearch,
  searchUrl,
  onLoadOptions,
  ...props
}) {
  // =================== HOOKS ===================
  const [search, setSearch] = useState("")
  const [references, setReferences] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let timeOut = undefined
    const value = search || receivedSearch

    if (value) {
      setLoading(true)
      setReferences([])

      timeOut = setTimeout(
        () => {
          api
            .get(searchUrl, {
              params: { search: value },
            })
            .then((response) => setReferences(response.data))
            .catch(() => setReferences([]))
            .finally(() => setLoading(false))
        },
        receivedSearch ? 0 : SEARCH_TIMEOUT_DELAY_TIME
      )
    }

    return () => {
      clearTimeout(timeOut)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, receivedSearch])

  // =================== HANDLERS ===================
  const handleChange = async (e) => {
    const searchValue = e.target.value
    setSearch(searchValue)
  }

  const referencesOptions = references.map(
    (ref) =>
      onLoadOptions || {
        ...ref,
        value: ref.id,
        label: ref.dsc_inf_referencia,
      }
  )

  return (
    <FormMultipleCombobox
      options={referencesOptions}
      loading={loading}
      limitTags={3}
      renderInput={(params) => (
        <FormTextField
          value={search}
          onChange={handleChange}
          fullWidth
          variant={props.variant}
          label={props.label}
          name={props.name}
          {...params}
        />
      )}
      {...props}
    />
  )
}

ComboboxReferences.propTypes = {
  search: PropTypes.string,
  onLoadOptions: PropTypes.func,
  searchUrl: PropTypes.string,
}

ComboboxReferences.defaultProps = {
  searchUrl: apiEndPoints.miscellaneous.references,
}
