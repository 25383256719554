import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"
import { addDays } from "date-fns"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const DEFAULT_REPORT_PERIOD_DAYS = 200

const minDate = addDays(new Date(), -DEFAULT_REPORT_PERIOD_DAYS)
const maxDate = new Date()

export const ReportsContext = createContext({
  loadingText: "",
  minDate,
  maxDate,
  setLoadingText: (_loadingText) => {},
})

export const useReportsContext = () => {
  const context = useContext(ReportsContext)
  return context
}

export function ReportsProvider({ value, children }) {
  return (
    <ReportsContext.Provider value={{ ...value, minDate, maxDate }}>
      {children}
    </ReportsContext.Provider>
  )
}

ReportsProvider.propTypes = {
  value: PropTypes.exact({
    loadingText: PropTypes.string,
    setLoadingText: PropTypes.func,
  }),
}

export default ReportsProvider
