import React from "react"
import { useQuery, useQueryClient } from "react-query"

import api from "../../../services/api"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { translate } from "../../../_i18n"

import Grid from "@material-ui/core/Grid"
import UpdateIcon from "@material-ui/icons/Update"

import Card from "../../../components/Card"
import SplashScreen from "../../../components/SplashScreen"
import GoalsTable from "./components/GoalsTable"
import CustomersBillingTable from "./components/CustomersBillingTable"
import CustomersDelayedTitlesTable from "./components/CustomersDelayedTitlesTable"
import BillingRankingChart from "./components/BillingRankingChart"

import { Container } from "./styles"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const DASHBOARD_STALE_TIME = 1000 * 30 * 5 // 5 minutes
const DASHBOARD_QUERY_KEY = "home-dashboard"

const HomeCard = (props) => {
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData(DASHBOARD_QUERY_KEY)
  const formattedDate = data ? data.updateAt.toLocaleString() : "..."

  return (
    <Card
      {...props}
      Footer={{
        Icon: UpdateIcon,
        title: translate("home.dashboard.updatedAt", {
          date: formattedDate,
        }),
      }}
    />
  )
}

export default function Home() {
  async function fetchDashboardData() {
    const response = await api.get(apiEndPoints.dashboard)
    const data = { ...response.data }

    data.days = data.dashb_rk_order_days_unfulfilled[0]?.dias_cart || 0
    data.updateAt = new Date()

    return data
  }

  const { data, isFetching, isLoading, isError } = useQuery(
    DASHBOARD_QUERY_KEY,
    fetchDashboardData,
    {
      staleTime: DASHBOARD_STALE_TIME,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  )

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <HomeCard
            Header={{
              color: "primary",
              Icon: UpdateIcon,
              title: translate("home.dashboard.averagePortifolioOrders"),
              primary: `${data?.days || "0"}`,
              secondary: translate("days"),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <HomeCard
            Header={{
              color: "info",
              size: "large",
              title: translate("home.dashboard.goal"),
            }}
          >
            <GoalsTable data={data?.dashb_rk_representative_goals} />
          </HomeCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeCard
            Header={{
              color: "success",
              size: "large",
              title: translate("home.dashboard.customersWithHigherBilling"),
            }}
          >
            <CustomersBillingTable
              data={data?.dashb_rk_customers_total_billing}
            />
          </HomeCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeCard
            Header={{
              color: "error",
              size: "large",
              title: translate("home.dashboard.customersWithMoreDelayedTitles"),
            }}
          >
            <CustomersDelayedTitlesTable
              data={data?.dashb_rk_customers_delayed}
            />
          </HomeCard>
        </Grid>
        <Grid item xs={12}>
          <HomeCard
            Header={{
              color: "primary",
              size: "large",
              title: translate("home.dashboard.lastSixMonthsBilling"),
            }}
          >
            <BillingRankingChart
              data={data?.dashb_rk_last_six_months_billings}
            />
          </HomeCard>
        </Grid>
      </Grid>
      <SplashScreen
        show={isLoading}
        inRefresh={isFetching}
        error={isError}
        loadingText={translate("home.dashboard.loadingText")}
      />
    </Container>
  )
}
