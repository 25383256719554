import React from "react"
import PropTypes from "prop-types"

import CardTable from "../../../../components/CardTable"
import { translate } from "../../../../_i18n"

export default function CustomersBillingTable({ data }) {
  return (
    <CardTable
      color="success"
      data={data}
      columns={[
        { field: "rk" },
        {
          title: translate("document"),
          field: "cod_empresa",
          type: "company",
        },
        {
          title: translate("customer.name"),
          field: "emp_razao_social",
        },
        {
          title: translate("value"),
          field: "cnf_valor_total",
          type: "decimal",
          align: "right",
        },
      ]}
    />
  )
}

CustomersBillingTable.propTypes = {
  data: PropTypes.array,
}
