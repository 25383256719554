import React from "react"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { useFetch } from "../../../hooks"
import { FormCombobox } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const CLASSIFICATIONS_STALE_TIME = 1000 * 60 * 10 // 10 minutes

/** This component can only be used inside a `Form` */
export default function ComboboxClassifications(props) {
  const {
    data: classifications,
    isFetching,
    isError,
  } = useFetch({
    clientName: "classifications",
    url: apiEndPoints.miscellaneous.classifications,
    staleTime: CLASSIFICATIONS_STALE_TIME,
  })

  const options = (classifications || []).map((b) => ({
    value: b.ctt_campo,
    label: b.dsc_conteudo,
  }))

  return (
    <FormCombobox
      disableClearable
      options={options}
      loading={isFetching}
      error={isError}
      {...props}
    />
  )
}
