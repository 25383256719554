import React from "react"
import PropTypes from "prop-types"
import ChartistGraph from "react-chartist"
import ctPointLabels from "chartist-plugin-pointlabels"
import { Container } from "./styles"
import formatter from "../../services/formatter"

export default function Chart({ data, type, ...props }) {
  const graphOptions = {
    low: 0,
    height: 300,
    showArea: true,
    fullWidth: true,
    chartPadding: {
      left: 20,
      right: 60,
    },
    plugins: [
      ctPointLabels({
        textAnchor: "left",
        labelInterpolationFnc: (v) => formatter(v).toDecimal(),
      }),
    ],
  }

  return (
    <Container {...props}>
      <ChartistGraph data={data} type={type} options={graphOptions} />
    </Container>
  )
}

Chart.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  listener: PropTypes.object,
}

Chart.defaultProps = {
  type: "Line",
}
