import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  lazy,
  Suspense,
} from "react"
import { toast } from "react-toastify"
import { merge } from "ramda"

import api from "@/services/api"
import apiEndPoints from "@/consts/apiEndPoints"
import { translate } from "@/_i18n"
import { Yup, handleValidationError } from "@/services/yup"
import { copyObject } from "@/services/utils"

import Search from "@material-ui/icons/Search"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"

import BaseDialog from "@/components/BaseDialog"
import Panel from "@/components/Panel"
import { FormCombobox, FormNumberField, FormTextField } from "@/components/Form"

import ComboboxBillingTypes from "@/components/Combobox/BillingTypes"
import ComboboxProductGrouts from "@/components/Combobox/ProductGrouts"
import ComboboxProductRefs from "@/components/Combobox/ProductRefs"
import ComboboxProductGauges from "@/components/Combobox/Gauges"
import ComboboxProductHues from "@/components/Combobox/Hues"

import Button from "@/components/Button"
import SpacedDivider from "@/components/SpacedDivider"

import { StyledGrid, StyledForm } from "./styles"
import { calculateQuantity, getProductTableValue, validateSave } from "./utils"

const QrCodeDialog = lazy(() => import("../QrCodeDialog"))

//  ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
//  ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
//  ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const validateOrderItemFormScheme = Yup.object().shape({
  id_tipo: Yup.string().required("Informe o tipo de faturamento").nullable(),
  itped_qtd: Yup.string()
    .test(
      "value",
      "A quantidade deve ser maior que 0",
      (val) => val && Number(val) > 0
    )
    .required("Informe a quantidade"),
  itped_prunit: Yup.string()
    .test(
      "value",
      "O valor deve ser maior que 0",
      (val) => val && Number(val) > 0
    )
    .required("Informe o valor"),
})

export default function OrderItemForm({
  order,
  current,
  onClose,
  onSave,
  loading,
  isMobile,
  ...props
}) {
  const formRef = useRef()
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  // ==== PREDICATES ====
  const isNew = current?.id === undefined
  const isEditable = isNew || current.editable

  const fetchWebProducts = useCallback(
    async ({ billingTypeId }) => {
      try {
        const response = await api.post(
          apiEndPoints.miscellaneous.customerProducts,
          {
            order_id: order.id,
            billing_type_id: billingTypeId,
          }
        )

        return response.data
      } catch (error) {
        return null
      }
    },
    [order]
  )

  useEffect(() => {
    setProduct(null)

    const initializeForm = async () => {
      formRef.current?.setErrors({})
      const isEditing = order.id && current?.id

      if (isEditing) {
        setIsLoading(true)

        const selectedProduct = products.find(
          (p) => p.cod_produto === current.product.id
        )

        formRef.current?.setValues({
          id: current.id,
          product: {
            id: current.product.id,
          },
          prd_referencia: selectedProduct?.prd_referencia,
          itped_bitola: current.itped_bitola,
          itped_tonalidade: current.itped_tonalidade,
          pallets: current.no_pallets,
          boxes: current.no_boxes,
          itped_qtd: current.itped_qtd,
          itped_prunit: current.itped_prunit,
          id_tipo: current.id_tipo,
          dsc_tamanho_produtos: current.dsc_tamanho_produtos,
          //
          itped_prtabela: 0,
        })

        setProduct(selectedProduct)
        setIsLoading(false)
      } else {
        const billingType =
          order.items?.length > 0 ? order.items[0].id_tipo : null

        formRef.current?.setValues({
          id: null,
          product: {
            id: null,
          },
          id_tipo: billingType,
          dsc_tamanho_produtos: "",
          prd_referencia: "",
          itped_bitola: "",
          itped_tonalidade: "",
          pallets: 1,
          boxes: 0,
          itped_qtd: 0,
          itped_prunit: 0,
          //
          itped_prtabela: 0,
        })
      }
    }

    if (current) {
      initializeForm()
    }
  }, [order, current])

  useEffect(() => {
    const initializeProducts = async () => {
      const billingTypeId =
        order.items?.length > 0 ? order.items[0].id_tipo : null

      if (billingTypeId && products.length === 0) {
        const newProducts = await fetchWebProducts({
          billingTypeId,
        })

        if (newProducts) {
          setProducts(newProducts)
        }
      }
    }

    initializeProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  // ==== HANDLERS ====

  const handleFetchProducts = async () => {
    const billingTypeId = formRef.current.getFieldValue("id_tipo")

    if (!billingTypeId) {
      formRef.current.setFieldError("id_tipo", "Escolha o tipo de faturamento")
      return
    }

    setIsFetching(true)

    formRef.current.setFieldValue("product.id", null)
    formRef.current.setFieldError("id_tipo", null)

    const newProducts = await fetchWebProducts({
      billingTypeId,
    })

    if (newProducts) {
      setProducts(newProducts)
    }

    setIsFetching(false)
  }

  const revalidateQuantity = (params) => {
    const amountPallets = formRef.current.getFieldValue("pallets")
    const amountBoxes = formRef.current.getFieldValue("boxes")

    const defaultParams = {
      amountPallets,
      amountBoxes,
      product,
    }

    const { quantity, pallets, boxes } = calculateQuantity(
      merge(defaultParams, params)
    )

    formRef.current.setData({
      itped_qtd: quantity,
      pallets,
      boxes,
    })
  }

  const handleChangeProduct = (newProduct) => {
    formRef.current.setData({
      prd_referencia: newProduct?.prd_referencia,
      dsc_tamanho_produtos: newProduct?.dsc_tamanho_produtos,
      itped_tonalidade: 0,
      itped_bitola: 0,
      itped_prunit: getProductTableValue(newProduct),
    })

    revalidateQuantity({
      product: newProduct,
    })

    setProduct(newProduct)
  }

  const handleChangeProductByQrCodeReader = (productCode) => {
    const product = products.find(
      (product) => product.cod_produto === productCode
    )

    if (product) {
      const minimumValue = getProductTableValue(product)

      formRef.current.setFieldValue("product.id", productCode)
      formRef.current.setFieldValue("itped_prunit", minimumValue)
      handleChangeProduct(product)
    } else {
      toast.info("Produto não encontrado")
    }
  }

  const handleChangeAmountPallets = (event) => {
    const amountPallets = event.target.value
    revalidateQuantity({ amountPallets })
  }

  const handleChangeAmountBoxes = (event) => {
    const amountBoxes = event.target.value
    revalidateQuantity({ amountBoxes })
  }

  const handleSave = async () => {
    formRef.current.setErrors({})

    const values = formRef.current.getData()
    const draft = copyObject(values)

    // console.log({ NewOrderItemSchema: draft })

    try {
      await validateOrderItemFormScheme.validate(draft, { abortEarly: false })
      const validated = await validateSave(formRef.current, draft, product)

      if (validated) {
        onSave(draft, formRef.current)
      }
    } catch (error) {
      const validateForm = handleValidationError(formRef.current)
      validateForm(error)
    }
  }

  const title = current
    ? isNew
      ? "Incluir item no pedido"
      : `Editar item "${current.product.descr}"`
    : "..."

  const disableBillingType = order.items?.length > 0
  const disableConfirmButton = !isEditable || product === null
  const disableBoxesField = product?.prd_pallet_aberto === "0"
  const disableQrCodeButton = products.length === 0

  const productsOptions = products.map((product) => ({
    ...product,
    value: product.cod_produto,
    label: `${product.dsc_tamanho_produtos} - ${product.cod_produto} - ${product.dsc_produto_completo}`,
  }))

  return (
    <BaseDialog
      maxWidth="md"
      fullScreen={isMobile}
      title={title}
      agreeLabel="Salvar"
      disagreeLabel="Cancelar"
      disableConfirmButton={disableConfirmButton}
      onConfirm={handleSave}
      onCancel={onClose}
      onClose={undefined}
      loading={loading}
      {...props}
    >
      <Panel loading={isLoading}>
        <StyledForm formRef={formRef}>
          {/* ==== BUSCAR PRODUTOS ==== */}
          <StyledGrid
            container
            spacing={2}
            alignItems="flex-start"
            className="Paper"
          >
            <Grid item xs={12} md={9}>
              <ComboboxBillingTypes
                fullWidth
                disableClearable
                variant="outlined"
                size="small"
                name="id_tipo"
                label="Tipo de Faturamento"
                disabled={disableBillingType}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {!isMobile && (
                <Button
                  fullWidth
                  startIcon={<Search />}
                  onClick={handleFetchProducts}
                  disabled={isFetching}
                  color="primary"
                  variant="contained"
                >
                  {isFetching && <CircularProgress size={20} />}
                  Buscar produtos
                </Button>
              )}

              {isMobile && (
                <Box display="flex" width="100%" style={{ gap: "1rem" }}>
                  <Button
                    fullWidth
                    onClick={handleFetchProducts}
                    isLoading={isFetching}
                    color="primary"
                    variant="contained"
                    className="Flex-grow"
                  >
                    Atualizar
                  </Button>
                  <Suspense fallback={null}>
                    <QrCodeDialog
                      disabled={disableQrCodeButton}
                      onSave={handleChangeProductByQrCodeReader}
                    />
                  </Suspense>
                </Box>
              )}
            </Grid>
          </StyledGrid>

          <SpacedDivider gutterVertical={0.5} onlyMobile />

          <StyledGrid container spacing={2} alignItems="baseline">
            {/* ==== PRODUTO ==== */}
            <Grid item xs={12} md={7}>
              <FormCombobox
                fullWidth
                name="product.id"
                label="Produto"
                loadingText="Buscando produtos..."
                options={productsOptions}
                loading={isFetching}
                onChange={(_, product) => handleChangeProduct(product)}
                helperText={`${productsOptions.length} produtos encontrados`}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <ComboboxProductGrouts
                fullWidth
                name="dsc_tamanho_produtos"
                label="Massa"
                disableClearable
                productCode={product?.cod_produto}
              />
            </Grid>
            <Grid item xs={6} md={1}>
              <ComboboxProductRefs
                fullWidth
                label="Ref."
                name="prd_referencia"
                disableClearable
                disabled
              />
            </Grid>
            <Grid item xs={6} md={1}>
              <ComboboxProductGauges
                fullWidth
                label="Bit."
                name="itped_bitola"
                disableClearable
              />
            </Grid>
            <Grid item xs={6} md={1}>
              <ComboboxProductHues
                fullWidth
                label="Ton."
                name="itped_tonalidade"
                disableClearable
              />
            </Grid>

            {/* ==== ITEM ==== */}
            <Grid item xs={6} md={2}>
              <FormTextField
                fullWidth
                name="pallets"
                type="number"
                label="Paletes"
                variant="filled"
                onChange={handleChangeAmountPallets}
                inputProps={{
                  min: 1,
                }}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTextField
                fullWidth
                name="boxes"
                type="number"
                label="Caixas"
                variant="filled"
                onChange={handleChangeAmountBoxes}
                disabled={disableBoxesField}
                inputProps={{
                  min: 0,
                }}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormNumberField
                fullWidth
                label={translate("quantity")}
                name="itped_qtd"
                inputMode="numeric"
                variant="filled"
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormNumberField
                fullWidth
                label={translate("unit.value")}
                name="itped_prunit"
                inputMode="numeric"
                variant="filled"
              />
            </Grid>
          </StyledGrid>
        </StyledForm>
      </Panel>
    </BaseDialog>
  )
}
