import React from "react"
import PropTypes from "prop-types"

import { MenuItem, TextField } from "@material-ui/core"

/**
 * @param {import("@material-ui/core").TextFieldProps} props
 * @returns {JSX.Element}
 */
export default function SelectField(props) {
  const { options, ...other } = props

  const isEmpty = options.length === 0

  return (
    <TextField {...other} select>
      {isEmpty && <MenuItem value="">Nenhuma opção econtrada</MenuItem>}

      {options.map((option) => {
        return (
          <MenuItem key={`option-${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}

SelectField.defaultProps = {
  options: [],
}
