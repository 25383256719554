import React, { useState, useRef } from "react"
import { useQuery } from "react-query"
import { format } from "date-fns"
import { useHistory, useParams } from "react-router-dom"
import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core"

import apiEndPoints from "../../../../consts/apiEndPoints"
import api from "../../../../services/api"
import { formatter } from "../../../../services/formatter"
import { handleAjaxError } from "../../../../services/errorHandlers"
import { translate } from "../../../../_i18n"

import EmailIcon from "@material-ui/icons/Email"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import Typography from "@material-ui/core/Typography"

import Logo from "../../../../components/Logo"
import Button from "../../../../components/Button"
import SplashScreen from "../../../../components/SplashScreen"
import TableOrderItems from "../../../../components/Orders/TableItems"
import DialogMail from "../../../../components/DialogMail"

import { StyledPaper, Header, Footer, Toolbar } from "./styles"
import { toast } from "react-toastify"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const ORDER_STALE_TIME = 1000 * 60 * 1 // 1 minute
const ORDER_CLIENT_NAME = "order"

export default function Order() {
  const { orderId } = useParams()
  const history = useHistory()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const paperRef = useRef(null)
  const [showMailConfirmation, setShowMailConfirmation] = useState(false)
  const [isSendingMail, setIsSendingMail] = useState(false)

  const { data, isLoading, isFetching } = useQuery(
    [ORDER_CLIENT_NAME, { orderId }],
    async () => {
      const response = await api.get(apiEndPoints.orders.order(orderId))
      const order = response.data
      return order
    },
    {
      staleTime: ORDER_STALE_TIME,
      retry: 1,
    }
  )

  const fmtDocument = (doc) => formatter(doc).toCNPJorCPF()
  const fmtDecimal = (number) => formatter(number).toDecimal()

  const customer = data?.customer
  const customerAddress = [customer?.emp_cidade, customer?.dsc_uf].join(" - ")
  const representative = data?.representative
  const carrier = data?.carrier
  const paymentCondition = data?.payment_condition
  const emails = customer?.emp_e_mail?.split(";") || []

  const handleSendOrderMail = (config) => {
    setIsSendingMail(true)

    api
      .post(apiEndPoints.orders.sendOrderEmail(orderId), {
        mail_config: {
          subject: `Pedido de Venda ${orderId.slice(0, -2)}`,
          ...config,
        },
      })
      .then(() => {
        toast.success("Email enviado com sucesso")
        setShowMailConfirmation(false)
      })
      .catch(handleAjaxError)
      .finally(() => {
        setIsSendingMail(false)
      })
  }

  const handlePrint = () => {
    window.print()
  }

  const disableButtons = !data

  return (
    <Container maxWidth="xl">
      <Toolbar>
        <Button
          size="medium"
          color="primary"
          variant="text"
          startIcon={<ChevronLeftIcon />}
          onClick={() => history.goBack()}
        >
          {translate("back")}
        </Button>
        <div className="d-flex gap-3">
          <Button
            size="medium"
            color="primary"
            variant="outlined"
            startIcon={<EmailIcon />}
            style={isMobile ? { flexGrow: 1 } : {}}
            onClick={() => setShowMailConfirmation(true)}
            disabled={disableButtons}
          >
            Enviar para o Cliente
          </Button>
          <Button
            onClick={handlePrint}
            size="medium"
            color="primary"
            variant="contained"
            hidden={isMobile}
            disabled={disableButtons}
          >
            {translate("print")}
          </Button>
        </div>
      </Toolbar>

      <StyledPaper ref={paperRef} id="printable" className="p-3">
        <Header>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="1rem"
          >
            <Box>
              <Typography variant="h5">{data?.cod_pedido}</Typography>

              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {format(new Date(), "dd/MM/yyyy")}
              </Typography>
            </Box>

            <Logo />
          </Box>

          <section>
            <div>
              <Typography className="font-bold" variant="body2">
                {translate("customer.name")}
              </Typography>
              <Typography variant="body2">
                {fmtDocument(customer?.id)}
              </Typography>
              <Typography variant="body2">
                {customer?.emp_razao_social}
              </Typography>
              {emails?.map((email, index) => (
                <Typography variant="body2" key={index}>
                  {email}
                </Typography>
              ))}
              <Typography variant="body2">{customerAddress}</Typography>
            </div>
            <div>
              <Typography className="font-bold" variant="body2">
                {translate("representative")}
              </Typography>
              <Typography variant="body2">
                {fmtDocument(representative?.id)}
              </Typography>
              <Typography variant="body2">{representative?.descr}</Typography>
            </div>
            <div>
              <Typography className="font-bold" variant="body2">
                {translate("carrier")}
              </Typography>
              <Typography variant="body2">
                {fmtDocument(carrier?.id)}
              </Typography>
              <Typography variant="body2">{carrier?.descr}</Typography>
            </div>
            <div>
              <Typography className="font-bold" variant="body2">
                {translate("order.report.orderData")}
              </Typography>
              <Typography variant="body2">
                {translate("issue")}:{" "}
                {formatter(data?.ped_data_emissao).toSimpleDate()}
              </Typography>
              <Typography variant="body2">
                {translate("freight")}: {data?.ped_tipo_frete}
              </Typography>
              <Typography variant="body2">
                {translate("order.yourOrder")}: {data?.ped_seupedido}
              </Typography>
            </div>
          </section>

          {/* ==== TAXES ==== */}
          <section style={{ display: "none" }}>
            <div>
              <Typography className="font-bold" variant="body2">
                Total:
              </Typography>
              <Typography variant="body2">{fmtDecimal(data?.total)}</Typography>
            </div>
            <div>
              <Typography className="font-bold" variant="body2">
                IPI:
              </Typography>
              <Typography variant="body2"></Typography>
            </div>
            <div>
              <Typography className="font-bold" variant="body2">
                ICMS-ST:
              </Typography>
              <Typography variant="body2"></Typography>
            </div>
            <div>
              <Typography className="font-bold" variant="body2">
                Total Geral
              </Typography>
              <Typography variant="body2">{fmtDecimal(data?.total)}</Typography>
            </div>
          </section>
        </Header>

        <TableOrderItems size="small" order={data} />

        <Footer>
          <div>
            <Typography className="font-bold" variant="body2">
              {translate("payment.condition")}:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {paymentCondition?.descr}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold" variant="body2">
              {translate("order.note")}:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {data?.ped_obs}
            </Typography>
          </div>
        </Footer>
      </StyledPaper>

      <SplashScreen
        show={isLoading}
        inRefresh={isFetching}
        loadingText="Carregando pedido..."
      />

      {/* ==== MAILER CONFIRMATION ==== */}
      <DialogMail
        title={`Enviar pedido para ${
          customer ? customer.emp_razao_social : "..."
        }`}
        open={showMailConfirmation}
        loading={isSendingMail}
        onClose={() => setShowMailConfirmation(false)}
        onSend={handleSendOrderMail}
        initialData={{
          email: emails[0],
          copy_to: emails.slice(1),
          self_copy: false,
        }}
      />
    </Container>
  )
}
