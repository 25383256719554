import React from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { StyledPanel } from "./styles"

export function TabPanelTransition({
  value,
  index,
  disableAnimation,
  children,
  size,
  ...props
}) {
  const parentVariant = {
    active: {
      transition: {
        staggerChildren: 0.01,
        delay: props.delay,
      },
    },
    inactive: {},
  }

  const childrenVariant = {
    active: {
      transition: {
        duration: 0.3,
      },
      opacity: 1,
    },
    inactive: {
      transition: {
        duration: 0.3,
      },
      opacity: 0,
    },
  }

  return !disableAnimation ? (
    <motion.div
      variants={parentVariant}
      animate={value === index ? "active" : "inactive"}
      initial="inactive"
      id={index}
      style={{ width: "100%" }}
    >
      <StyledPanel size={size}>
        <motion.div key={index} variants={childrenVariant}>
          {children}
        </motion.div>
      </StyledPanel>
    </motion.div>
  ) : (
    <StyledPanel>{children}</StyledPanel>
  )
}

TabPanelTransition.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
  staggerChildren: PropTypes.number,
  delay: PropTypes.number,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
}

TabPanelTransition.defaultProps = {
  disableAnimation: false,
  size: "md",
}
