import React from "react"
import PropTypes from "prop-types"
import { Redirect, Route } from "react-router-dom"

import { paths } from "../../consts/paths"
import { useAuth } from "../../contexts/Auth"

export default function PrivateRoute({
  component: Component,
  isPrivate = true,
  ...props
}) {
  const { isAuthenticated } = useAuth()

  if (isPrivate && !isAuthenticated) return <Redirect to={paths.public.login} />

  if (!isPrivate && isAuthenticated) return <Redirect to={paths.profile.home} />

  return <Route {...props} component={Component} />
}

PrivateRoute.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
}
