import React from "react"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { useFetch } from "../../../hooks"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const STALE_TIME = 1000 * 60 * 10 // 10 minutes

export default function Summary() {
  const { data } = useFetch({
    clientName: "status",
    url: apiEndPoints.miscellaneous.status,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    retry: 0,
  })

  return (
    <div
      style={{
        width: "300",
        padding: "1rem",
      }}
    >
      <table
        style={{
          fontSize: "10px",
          border: "#000 solid 1px",
        }}
      >
        <tbody>
          <tr>
            <th colSpan="3">LEGENDA</th>
          </tr>
          {data?.map(({ id, descr }, index) => (
            <tr key={index}>
              <td width="30" align="center">
                {id}
              </td>
              <td width="190">{descr}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
