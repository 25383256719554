import React from "react"
import PropTypes from "prop-types"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Radio from "@material-ui/core/Radio"
import { RadioGroupProps } from "@material-ui/core/RadioGroup"
import { StyledRadioGroup } from "./styles"

/**
 * Custom component based on Material-UI RadioGroup
 *
 * @param {RadioGroupProps} props
 * @returns {JSX.Element}
 */
export default function RadioGroup({
  options,
  label,
  onChange,
  error,
  helperText,
  ...props
}) {
  const handleChange = (e) => {
    const value = e.target.value
    onChange({ ...e, target: { name: props.name, value } }, value)
  }

  return (
    <FormControl
      fullWidth
      size="small"
      component="fieldset"
      error={error}
      margin="dense"
    >
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <StyledRadioGroup {...props} onChange={handleChange}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            label={option.label || option.value}
            value={option.value}
            control={
              <Radio disabled={props.disabled} color="primary" size="small" />
            }
          />
        ))}
      </StyledRadioGroup>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

RadioGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  direction: PropTypes.oneOf(["row", "column"]),
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.any,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ).isRequired,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
}

RadioGroup.defaultProps = {
  direction: "column",
}
