import { createNumberMask } from "text-mask-addons"
import { telefoneFixo, telefoneCelular, cpf, cnpj } from "../consts/regexp"

export const toMask = (mask, { ...options } = {}) => ({ mask, ...options })

export const unmask = (value) => {
  if (!value) return ""
  return value.replace(/[' '()/.-]/g, "").trim()
}

export const numberMask = createNumberMask({
  prefix: "",
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  requireDecimal: true,
})

export const numberTextMaskProps = (decimalPlaces = 2) => ({
  mask: numberMask,
  pipe: (value) => {
    const [number = "0", decimal = "0"] = value.trim().split(",")
    return [number, decimal.padEnd(decimalPlaces, "0")].join(",")
  },
})

export const removeNumberMask = (value, fractionDigits = 2) =>
  Number(
    parseFloat(value.replace(".", "").replace(",", ".")).toFixed(fractionDigits)
  )

export const phoneMask = (rawPhone) => {
  if (unmask(rawPhone).length > 10) {
    return telefoneCelular.array
  }

  return telefoneFixo.array
}

export const cnpjCpfMask = (rawCnpjCPF) => {
  if (unmask(rawCnpjCPF).length > 11) {
    return cnpj.array
  }

  return cpf.array
}
