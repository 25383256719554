import React from "react"
import clsx from "clsx"

import { useDrawerContext } from "../../contexts/Drawer"

import Navigation from "../../components/Navigation"
import ContentRoutes from "../../routes/ContentRoutes"
import Footer from "../../components/Footer"
import Confirmation from "../../components/Confirmation"
import SignOutChecker from "../../components/SignOutChecker"

import { Container, Content } from "./styles"

export function Layout() {
  const { open } = useDrawerContext()

  return (
    <Container
      className={clsx({
        shift: open,
      })}
    >
      <Navigation />

      <Content maxWidth="xl">
        <ContentRoutes />
      </Content>

      <Footer />
      <Confirmation />
      <SignOutChecker />
    </Container>
  )
}

export default React.memo(Layout)
