import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"

import api from "../../../services/api"
import apiEndPoints from "../../../consts/apiEndPoints"
import { ORDER_SCOPE_TYPES } from "../../../consts/types"
import { paths } from "../../../consts/paths"
import { usePagination, useSortData } from "../../../hooks"
import { allZero } from "../../../services/utils"
import { translate } from "../../../_i18n"

import AddIcon from "@material-ui/icons/Add"
import Button from "../../../components/Button"
import ContentPage from "../../../components/ContentPage"
import TableOrders from "../../../components/Orders/Table"
import SearchField from "../../../components/SearchField"
import ComboboxOrderScopes from "../../../components/Combobox/OrderScope"

import { confirmation } from "../../../components/Confirmation"
import {
  removeOrderConfirm,
  approveBudgetConfirm,
  generateBudgetConfirm,
  generateOrderConfirm,
} from "./confirmations"

import FormEdit from "./FormEdit"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const ORDERS_STALE_TIME = 1000 * 60 * 1
const ORDERS_CLIENT_NAME = "orders"
const ORDERS_DEFAULT_SORT = "ped_data_emissao"

export default function Orders() {
  const { orderType } = useParams()
  const { state } = useLocation()
  const history = useHistory()

  const [current, setCurrent] = useState(null)
  const [showFormEdit, setShowFormEdit] = useState(false)
  const [loading, setLoading] = useState(false)

  const [searchOrder, setSearchOrder] = useState("")
  const [scope, setScope] = useState(
    state?.scope || ORDER_SCOPE_TYPES.unfulfilled
  )

  const { currentSort, onSortChange, getSortString } = useSortData({
    initialField: state?.field || ORDERS_DEFAULT_SORT,
    initialOrder: state?.order,
  })

  const {
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    calculateOffset,
    reset,
  } = usePagination({
    initialPage: state?.page,
    initialRowsPerpage: state?.page_size,
  })

  useEffect(() => {
    history.replace({
      state: {
        page,
        scope,
        page_size: rowsPerPage,
        field: currentSort.field,
        order: currentSort.order,
      },
    })
  }, [page, rowsPerPage, currentSort, scope])

  useEffect(() => {
    reset()
  }, [orderType, scope])

  const { data, isFetching, refetch } = useQuery(
    [
      ORDERS_CLIENT_NAME,
      {
        page,
        rowsPerPage,
        orderType,
        scope,
        field: currentSort.field,
        order: currentSort.order,
        search: searchOrder,
      },
    ],
    async () => {
      const params = {}
      params.order = getSortString()
      params.page = calculateOffset()
      params.page_size = rowsPerPage
      params.order_type = orderType
      params.order_scope = scope
      params.search = searchOrder

      const response = await api.get(apiEndPoints.orders.root, { params })
      const count = parseInt(response.headers.total)

      const orders = response.data.map((order) => {
        const isUnfullfilled = allZero([
          order.total_load_assembly_items,
          order.total_quantity_fulfilled,
          order.total_quantity_cancelled,
        ])

        const editable = order.editable && isUnfullfilled

        return {
          ...order,
          editable,
        }
      })

      return { orders, count }
    },
    {
      staleTime: ORDERS_STALE_TIME,
      refetchOnWindowFocus: false,
    }
  )

  // ==== HANDLERS ====

  const handleNewOrder = () => {
    setCurrent({})
    setShowFormEdit(true)
  }

  const handleEditOrder = (order) => {
    setCurrent({ ...order })
    setShowFormEdit(true)
  }

  const handleClose = () => {
    setShowFormEdit(false)
    setCurrent(null)
  }

  const handlePrint = (order) => {
    const orderId = encodeURIComponent(order.id)

    const path = paths.orders.show
      .replace(":orderType", orderType)
      .replace(":orderId", orderId)

    history.push(path)
  }

  // ==== API HANDLERS ====

  const createOrder = async (order) => {
    setLoading(true)

    try {
      const response = await api.post(apiEndPoints.orders.root, { order })
      const newOrder = response.data

      const orderId = encodeURIComponent(newOrder.id)

      const path = paths.orders.items
        .replace(":orderType", orderType)
        .replace(":orderId", orderId)

      history.push(path)
    } catch {
      setLoading(false)
    }
  }

  const updateOrder = async (order) => {
    setLoading(true)

    try {
      await api.put(apiEndPoints.orders.order(order.id), { order })

      refetch()
      setShowFormEdit(false)
    } finally {
      setLoading(false)
    }
  }

  const removeOrder = async (order) => {
    const confirmed = await removeOrderConfirm(order)

    if (confirmed) {
      await api.delete(apiEndPoints.orders.order(order.id))
      refetch()
    }

    confirmation.close()
  }

  const approveBudget = async (order) => {
    const confirmed = await approveBudgetConfirm(order)

    if (confirmed) {
      try {
        await api.post(apiEndPoints.orders.budgetApproval(order.id))

        refetch()
        toast.success(
          translate("order.budgetApproval.message.success", {
            order: order.cod_pedido,
            orderType: translate("budget"),
          })
        )
      } catch (error) {
        toast.error(error.response.data.errors)
      }
    }

    confirmation.close()
  }

  const generateBudget = async (order) => {
    const confirmed = await generateBudgetConfirm(order)

    if (confirmed) {
      try {
        await api.post(apiEndPoints.orders.orderToBudget(order.id))

        refetch()
        toast.success(
          translate("order.budgetConfirm.message.success", {
            order: order.cod_pedido,
            orderType: translate("budget"),
          })
        )
      } catch (error) {
        toast.error(error.response.data.errors)
      }
    }

    confirmation.close()
  }

  const generateOrder = async (order) => {
    const confirmed = await generateOrderConfirm(order)

    if (confirmed) {
      try {
        await api.post(apiEndPoints.orders.budgetToOrder(order.id))

        refetch()
        toast.success(
          translate("order.budgetConfirm.message.success", {
            order: order.cod_pedido,
            orderType: translate("order"),
          })
        )
      } catch (error) {
        toast.error(error.response.data.errors)
      }
    }

    confirmation.close()
  }

  // ==== HANDLERS ====

  const handleSave = (order) => {
    if (order.id) updateOrder(order)
    else createOrder(order)
  }

  return (
    <>
      <ContentPage
        title={translate("order.page.title")}
        controls={
          <>
            <SearchField
              fullWidth
              size="medium"
              label="Pesquisar"
              placeholder="Pesquise pelo número ou cliente..."
              variant="filled"
              onSearch={setSearchOrder}
            />
            <ComboboxOrderScopes
              value={scope}
              onChange={(_, newScope) => setScope(newScope.value)}
            />
          </>
        }
      >
        <TableOrders
          orderType={orderType}
          data={data?.orders}
          currentSort={currentSort}
          page={page}
          rowsPerPage={rowsPerPage}
          count={data?.count}
          loading={isFetching}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onRemoveOrder={removeOrder}
          onEditOrder={handleEditOrder}
          onPrintOrder={handlePrint}
          onApproveBudget={approveBudget}
          onGenerateOrder={generateOrder}
          onGenerateBudget={generateBudget}
        />
      </ContentPage>
      <div className="mt-3 d-flex justify-content-end">
        <Button
          color="secondary"
          variant="contained"
          size="medium"
          startIcon={<AddIcon />}
          onClick={handleNewOrder}
        >
          {translate("order.new")}
        </Button>
      </div>

      {/* ==== FORM EDIT ==== */}
      <FormEdit
        loading={loading}
        current={current}
        open={showFormEdit}
        onClose={handleClose}
        onSave={handleSave}
      />
    </>
  )
}
