import React, { useCallback, createContext, useContext } from "react"
import { usePersistedState } from "../hooks/usePersistedState"
import { DRAWER_OPEN } from "../consts/storage"

export const DrawerStateContext = createContext({
  open: false,
  onOpen: () => {},
  onClose: () => {},
})

export const useDrawerContext = () => {
  const context = useContext(DrawerStateContext)
  return context
}

export function DrawerStateProvider({ children, initialOpen }) {
  const [open, setOpen] = usePersistedState(DRAWER_OPEN, initialOpen)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <DrawerStateContext.Provider
      value={{
        open,
        onOpen: handleOpen,
        onClose: handleClose,
      }}
    >
      {children}
    </DrawerStateContext.Provider>
  )
}

DrawerStateProvider.defaultProps = {
  initialOpen: false,
}

export default DrawerStateProvider
