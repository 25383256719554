import { useMemo } from "react"

import api from "../services/api"
import { useQuery } from "react-query"

/**
 * Define the options for the useFetch hook
 *
 * @param {Omit<import("react-query").UseQueryOptions<any, unknown, any, any>, "queryKey" | "queryFn">)} options
 * @param {string} options.clientName - The name of the client
 * @param {string} options.url - The url to fetch
 * @param {function} options.onSuccess - The function to call when the request is successful
 * @param {boolean} options.disabledRequest
 */
export default function useFetch({
  clientName,
  url,
  onSuccess,
  disabledRequest,
  ...options
}) {
  const queryResult = useQuery(
    clientName,
    async () => {
      if (!url || disabledRequest) return

      const response = await api.get(url)
      return onSuccess ? onSuccess(response.data) : response.data
    },
    {
      retry: 3,
      // refetchOnWindowFocus: false,
      // refetchOnReconnect: false,
      ...options,
    }
  )

  return queryResult
}

// ------------------------------------------

export function useFetchUrl(baseUrl, params) {
  const url = useMemo(() => {
    if (Object.values(params).some((value) => value === undefined))
      return undefined

    const searchParams = new URLSearchParams(params)
    return `${baseUrl}?${searchParams}`
  }, [baseUrl, params])

  return url
}
