import React, { useState } from "react"

import {
  Avatar,
  Box,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { CameraAlt } from "@material-ui/icons"

import ChangeTheme from "@/components/ChangeTheme"
import ContentPage from "@/components/ContentPage"
import { useAuth } from "@/contexts/Auth"
import formatter from "@/services/formatter"
import {
  AccountPageContainer,
  AccountPageFormContainer,
  EndSessionButton,
} from "./styles"
import Button from "@/components/Button"

export default function AccountPage() {
  const { currentUser, signOut, updateCurrentUser } = useAuth()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("")

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  async function handleSubmit(event) {
    event.preventDefault()

    const form = event.currentTarget
    const formData = new FormData(event.currentTarget)

    const values = Object.fromEntries(formData)

    if (values.new_password !== values.confirm_password) {
      setPasswordErrorMessage("Senhas informadas divergem")
      return
    }

    setPasswordErrorMessage("")
    setIsSubmitting(true)

    await updateCurrentUser({
      current_password: values.current_password,
      new_password: values.new_password,
    })

    setIsSubmitting(false)

    form.reset()
  }

  return (
    <ContentPage useDefaultPaper={false} title="Meu Perfil" showReturn>
      <AccountPageContainer>
        <AccountPageFormContainer>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <Typography color="primary" variant="overline">
                Usuário
              </Typography>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Avatar
                  src="/"
                  alt={currentUser?.company.emp_razao_social}
                  style={{
                    marginBottom: "1rem",
                    width: isMobile ? 100 : 200,
                    height: isMobile ? 100 : 200,
                  }}
                >
                  <CameraAlt />
                </Avatar>

                <ChangeTheme />

                <Typography gutterBottom variant="body1" color="textPrimary">
                  {/* {currentUser?.user.name} */}
                </Typography>

                <Typography variant="body1" color="textSecondary">
                  {/* {currentUser?.user.email} */}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={7}>
              <Typography color="primary" variant="overline">
                Representante
              </Typography>

              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gridGap="1rem"
                  gridTemplateColumns={
                    isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"
                  }
                  mt={3}
                  mb={2}
                >
                  <TextField
                    label="CNPJ"
                    name="cnpj"
                    defaultValue={formatter(currentUser?.company.id).toCNPJ()}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    label="Razão Social"
                    name="companyName"
                    defaultValue={currentUser?.company.emp_razao_social}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    label="E-mail"
                    name="email"
                    defaultValue={currentUser?.company.emp_e_mail}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    label="Telefone"
                    name="phone"
                    defaultValue={currentUser?.company.emp_telefone}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    label="CEP"
                    name="cep"
                    defaultValue={formatter(
                      currentUser?.company.emp_cep
                    ).toCep()}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    label="Bairro"
                    name="neighboor"
                    defaultValue={currentUser?.company.emp_bairro}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    label="Endereço"
                    name="address"
                    defaultValue={
                      currentUser?.company.emp_endereco +
                      " " +
                      currentUser?.company.emp_endereco_num
                    }
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    label="Complemento"
                    name="complement"
                    defaultValue={currentUser?.company.emp_compl_endereco}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    label="Senha atual"
                    name="current_password"
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    required
                  />
                  <TextField
                    label="Nova senha"
                    name="new_password"
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    required
                    inputProps={{
                      pattern: ".{6,}",
                      title: "A senha deve ter pelo menos 6 caracteres.",
                    }}
                  />

                  {!isMobile && <Box />}

                  <TextField
                    error={Boolean(passwordErrorMessage)}
                    helperText={passwordErrorMessage}
                    label="Confirmar senha"
                    name="confirm_password"
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    required
                    inputProps={{
                      pattern: ".{6,}",
                      title: "A senha deve ter pelo menos 6 caracteres.",
                    }}
                  />
                </Box>

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    isLoading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                  >
                    Salvar
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </AccountPageFormContainer>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <EndSessionButton
            className="Button-Endsession"
            onClick={signOut}
            color="default"
            variant="outlined"
          >
            Encerrar sessão
          </EndSessionButton>
        </Box>
      </AccountPageContainer>
    </ContentPage>
  )
}
