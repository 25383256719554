import apiEndPoints from "@/consts/apiEndPoints"
import api from "@/services/api"

async function _uploadCustomerAttachment(customerId, attachment) {
  const URL = apiEndPoints.customers.attachments(customerId)

  const data = new FormData()
  data.append("file", attachment.file, attachment.file.name)

  return api.post(URL, data)
}

export async function uploadCustomerAttachments(customerId, attachments) {
  try {
    await Promise.all(
      attachments.map((attachment) =>
        _uploadCustomerAttachment(customerId, attachment)
      )
    )
  } catch (error) {
    console.error(error)
  }
}
