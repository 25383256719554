import React, { useState } from "react"
import { Grid, InputAdornment } from "@material-ui/core"
import { Phone, PhoneAndroid } from "@material-ui/icons"

import { toMask } from "@/services/masks"
import { cpf, cnpj, telefoneCelular, telefoneFixo } from "@/consts/regexp"

import { FormCheckBox, FormTextField } from "@/components/Form"
import { TabPanel } from "@/components/Tabs"
import ComboboxClassifications from "@/components/Combobox/Classifications"
import RadioGroup from "@/components/RadioGroup"

import { Brands } from "../components/Brands"
import { useKeepMounted } from "./useKeepMounted"

export function TabCustomer({ currentTab }) {
  const [customerType, setCustomerType] = useState("J")
  const isJuridicPerson = customerType === "J"

  const keepMounted = useKeepMounted()

  return (
    <TabPanel value={currentTab} index={0} keepMounted={keepMounted}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioGroup
            value={customerType}
            onChange={(_, newValue) => setCustomerType(newValue)}
            name="customer_type"
            direction="row"
            size="small"
            options={[
              { value: "J", label: "Pessoa Juridica" },
              { value: "F", label: "Pessoa Fisica" },
            ]}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormTextField
            fullWidth
            name="cod_empresa"
            variant="outlined"
            size="small"
            label={isJuridicPerson ? "CNPJ" : "CPF"}
            textMaskProps={toMask(isJuridicPerson ? cnpj.array : cpf.array)}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormTextField
            fullWidth
            name="emp_estadual_rg"
            variant="outlined"
            size="small"
            label="IE"
            placeholder="Clientes sem IE, utilizar ISENTO"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ComboboxClassifications
            fullWidth
            name="cli_classific_cliente"
            variant="outlined"
            size="small"
            label="Classificação"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            fullWidth
            name="dsc_empresa"
            variant="outlined"
            size="small"
            label="Nome Fantasia"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            fullWidth
            name="emp_razao_social"
            variant="outlined"
            size="small"
            label="Razão Social"
          />
        </Grid>

        {/* === CONTATO === */}
        <Grid item xs={12} sm={4}>
          <FormTextField
            fullWidth
            name="emp_home_page"
            variant="outlined"
            size="small"
            label="Home Page"
            placeholder="ex:."
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormTextField
            fullWidth
            name="emp_telefone"
            variant="outlined"
            size="small"
            label="Telefone/Celular"
            placeholder="55+"
            textMaskProps={toMask(telefoneCelular.array)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneAndroid size="small" color="action" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormTextField
            fullWidth
            name="emp_telefone2"
            variant="outlined"
            size="small"
            label="Telefone"
            placeholder="55+"
            textMaskProps={toMask(telefoneFixo.array)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone size="small" color="action" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormTextField
            fullWidth
            name="emp_e_mail"
            variant="outlined"
            size="small"
            label="E-mail"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormTextField
            fullWidth
            name="cli_email"
            variant="outlined"
            size="small"
            label="E-mail NFe"
            type="email"
            placeholder="E-mail nota fiscal eletrônica"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormTextField
            fullWidth
            name="cli_email_cob"
            variant="outlined"
            size="small"
            label="E-mail Cob."
            type="email"
            placeholder="E-mail cobrança"
          />
        </Grid>

        {/* === CHECKBOX === */}
        <Grid item xs={12} sm={3}>
          <FormCheckBox
            name="cli_consumidor"
            label="Consumidor Final"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormCheckBox
            name="cli_nao_contribuinte"
            label="Não Contribuinte"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormCheckBox
            name="cli_reg_icms_st"
            label="Regime Esp. ICMS"
            variant="outlined"
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <Brands name="commercial_brands" />
        </Grid>
      </Grid>
    </TabPanel>
  )
}
