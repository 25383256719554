export const paths = {
  root: process.env.PUBLIC_URL,
  // PUBLIC
  public: {
    get login() {
      return `${paths.root}/login`
    },
  },
  profile: {
    get root() {
      return `${paths.root}/meu-perfil`
    },
    get home() {
      return `${paths.root}/home`
    },
  },
  // REPRESENTATIVE
  orders: {
    get root() {
      return `${paths.root}/pedidos/:orderType?`
    },
    get show() {
      return `${paths.root}/pedidos/:orderType/:orderId`
    },
    get items() {
      return `${paths.root}/pedidos/:orderType/:orderId/items`
    },
  },
  proformas: {
    get root() {
      return `${paths.root}/proformas/:proformaType?`
    },
    get show() {
      return `${paths.root}/proformas/:proformaType/:proformaId`
    },
    get items() {
      return `${paths.root}/proformas/:proformaType/:proformaId/items`
    },
  },
  customers: {
    get root() {
      return `${paths.root}/clientes`
    },
  },
  bulletins: {
    get root() {
      return `${paths.root}/comunicados`
    },
  },
  customerServices: {
    get root() {
      return `${paths.root}/atendimento-ao-cliente`
    },
  },
  reports: {
    get root() {
      return `${paths.root}/relatorios/:reportId?`
    },
  },
  registers: {
    get root() {
      return `${paths.root}/cadastros`
    },
  },
}

export default paths
