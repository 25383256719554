import { lensPath, split, view, all, sum, prop } from "ramda"
import { formatter } from "./formatter"
import { unmask } from "./masks"
import * as regexp from "../consts/regexp"

export const UNPROCESSABLE_ENTITY = 422

export const extractLens = (path, data, splitter = ".") => {
  const parts = split(splitter, path)
  const fieldLens = lensPath(parts)
  return view(fieldLens, data)
}

export const copyObject = (object) => JSON.parse(JSON.stringify(object))

export const isValidMail = (email) => regexp.email.withUnicode.test(email)

/** Format value using type */
export const format = (type, value) => {
  if (!value) return value

  switch (type) {
    case "date":
      return formatter(value).toSimpleDate()
    case "document":
      return formatter(value).toCNPJorCPF()
    case "phone":
      return formatter(unmask(value)).toTelefone()
    case "integer":
      return typeof value === "string" ? parseInt(value) : value
    case "decimal":
      return formatter(value).toDecimal()
    case "currency":
      return Number(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    default:
      return value
  }
}

/** Group by the specified field */
export const groupAndSortWith = (arr, groupBy) => {
  let index = -1
  let current = null
  let result = []

  // 1. Order the array
  const sorted = arr.sort((a, b) => {
    if (a[groupBy] < b[groupBy]) return -1
    else if (a[groupBy] > b[groupBy]) return 1
    return 0
  })

  // 2. Group the array
  sorted.forEach((item) => {
    if (current !== item[groupBy]) {
      current = item[groupBy]
      index++

      result[index] = { key: item[groupBy], items: [item] }
    } else {
      result[index]["items"].push(item)
    }
  })

  return result
}

export const allTrue = all((item) => item === true)

export const allZero = all((number) => parseInt(number) === 0)

export const strConnective = (str) =>
  str?.slice(-1).toLowerCase() === "a" ? "a" : "ao"

export const handleUnprocessableEntity = (error) => {
  if (error.response.status === UNPROCESSABLE_ENTITY)
    return error.response.data.errors
  return {}
}

/**
 * @description - Returns the quantity in square meters of the item
 *
 * @param {object} product
 * @param {number} amountPallets
 * @param {number} classification
 * @returns {number}
 */
export const calculateItemQuantity = (
  product,
  amountPallets,
  classification
) => {
  try {
    const squareMeters = product.product_format.ctp_m2_caixa
    const piecesInBox = product.product_format.ctp_pecas_caixa
    const amount = product.ctp_caixa_pallet

    if (classification === 3)
      return ((squareMeters / piecesInBox).toFixed(2) * amountPallets).toFixed(
        2
      )
    else return (amount * squareMeters * amountPallets * 100.0).toFixed(2) / 100
  } catch (error) {
    return 0
  }
}

/**
 * Curried Function used to sum prop of object array
 *
 * @param {string} attribute
 * @param {Array<unknown>} list
 * @returns {(number | (list: Array<unknown>) => number)}
 */
export const sumProp = (attribute, list) =>
  list
    ? sum(list.map(prop(attribute)).filter((n) => n && !Number.isNaN(n)))
    : (list) => sumProp(attribute, list)

/**
 * Return last index of array
 *
 * @param {Array} list
 * @returns
 */
export const last = (list) => list[list.length - 1]

export const toPx = (pixels) => `${pixels}px`

export const encrypted = (id) => {
  try {
    return btoa(String(id))
  } catch (error) {
    return id
  }
}

export const decrypt = (id) => {
  try {
    return atob(String(id))
  } catch (error) {
    return id
  }
}

// Functions made for mockup

/**
 * @description - Returns a random Integer number, within the parameters interval; parameters are included.
 *
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const getRandomIntegerNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * @description - Returns a random Float number, within the parameters interval; parameters are included.
 *
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const getRandomFloatNumber = (min, max) => {
  return (Math.random() * (max - min + 1) + min).toFixed(2)
}

/**
 * @description - Returns a random Date instance, within the parameters interval; parameters are included.
 *
 * @param {Date} startDateInterval
 * @param {Date} endDateInterval
 * @returns {Date}
 */
export const getRandomDate = (startDateInterval, endDateInterval) => {
  return new Date(
    startDateInterval.getTime() +
      Math.random() * (endDateInterval.getTime() - startDateInterval.getTime())
  )

  // Example:
  //      getRandomDate(new Date(2015, 0, 1), new Date())
}

/**
 * @description - Returns the formatted array by the function
 *
 * @param {Array<unknown>} arr
 * @param {number} index
 * @param {Array<unknown>} items
 * @returns {Array<unknown>}
 */
export const insertItemInSpecificArrayIndex = (arr, index, ...items) => {
  arr.splice(index, 0, ...items)
  return arr
}

/**
 * @description - Transpose object in array
 *
 * @param {unknown} data
 * @returns {Array<unknown>}
 */
export const transposeToList = (data) => {
  if (!data) return []

  return Object.values(data)
}
