import React from "react"
import { apiEndPoints } from "@/consts/apiEndPoints"
import { useFetch } from "@/hooks"
import { FormCombobox } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const FORMATS_STALE_TIME = 1000 * 60 * 10 // 10 minutes

/** This component can only be used inside a `Form` */
export default function ComboboxFormats(props) {
  const {
    data: formats,
    isFetching,
    isError,
  } = useFetch({
    clientName: "productFormats",
    url: apiEndPoints.miscellaneous.productFormats,
    staleTime: FORMATS_STALE_TIME,
  })

  const options = (formats || []).map((format) => ({
    ...format,
    value: format.dsc_tamanho_produtos,
    label: format.dsc_tamanho_produtos,
  }))

  return (
    <FormCombobox
      options={options}
      loading={isFetching}
      error={isError}
      {...props}
    />
  )
}
