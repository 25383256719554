import React from "react"
import { useQuery } from "react-query"

import api from "../../services/api"
import { apiEndPoints } from "../../consts/apiEndPoints"
import { useNotificationsContext } from "../../contexts/Notifications"

import RepresentativeMenuItems from "./Representative"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const BULLETINS_NOTIFICATIONS_STALE_TIME = 1000 * 60 * 3 // 3 minutes

export default function MenuItemsCommon() {
  const { updateNotification } = useNotificationsContext()

  useQuery(
    "unreaded-bulletins",
    async () => {
      const response = await api.get(apiEndPoints.bulletins.unreadedBulletins)
      const { count } = response.data

      updateNotification("bulletins", () => count)
    },
    {
      staleTime: BULLETINS_NOTIFICATIONS_STALE_TIME,
    }
  )

  return (
    <>
      <RepresentativeMenuItems />
    </>
  )
}
