import React, { useState, useEffect } from "react"
import { Typography, useMediaQuery, useTheme } from "@material-ui/core"
import PropTypes from "prop-types"
import { equals } from "ramda"
import { ORDER_TYPES } from "../../../consts/types"
import { paths } from "../../../consts/paths"
import { translate } from "../../../_i18n"
import { allZero } from "../../../services/utils"
import formatter from "../../../services/formatter"

import { Link } from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"
import DeleteIcon from "@material-ui/icons/Delete"
import PrintIcon from "@material-ui/icons/Print"
import FlashOnIcon from "@material-ui/icons/FlashOn"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ReplyAllIcon from "@material-ui/icons/ReplyAll"
import NewReleasesIcon from "@material-ui/icons/NewReleases"
import EditIcon from "../../Icons/Edit"
import Table from "../../Table"
import Chip from "../../Chip"

export default function TableOrders({
  data,
  orderType,
  onEditOrder,
  onRemoveOrder,
  onPrintOrder,
  onApproveBudget,
  onGenerateOrder,
  onGenerateBudget,
  ...props
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [table, setTable] = useState({
    columns: [],
    actions: [],
    rowStyle: undefined,
  })

  useEffect(() => {
    const isOrderType = equals(orderType)
    const isEmptyOrder = (order) => allZero([order.total_quantity])

    const showGenerateBudgetAction =
      isOrderType(ORDER_TYPES.regular) &&
      data.some((order) => order?.isTransformedBudget)

    const isApproved = (order) => order.ped_aprovado === "approved"

    // =================
    // ==== ACTIONS ====
    // =================

    let actions = [
      {
        title: (order) =>
          order?.editable ? translate("edit") : translate("view"),
        onClick: onEditOrder,
        icon: (order) => (
          <EditIcon isEditable={order.editable} fontSize="small" />
        ),
      },
      {
        title: translate("delete"),
        onClick: onRemoveOrder,
        icon: <DeleteIcon fontSize="small" />,
        getDisabled: (order) => !(order.editable || isEmptyOrder(order)),
      },
      {
        title: translate("print"),
        onClick: onPrintOrder,
        icon: <PrintIcon fontSize="small" />,
        getDisabled: isEmptyOrder,
      },
    ]

    if (showGenerateBudgetAction) {
      actions = actions.concat([
        {
          title: translate("order.hints.canBudget"),
          icon: <ReplyAllIcon className="primary" fontSize="small" />,
          onClick: onGenerateBudget,
          getDisabled: (order) => !order.isTransformedBudget,
        },
      ])
    }

    if (isOrderType(ORDER_TYPES.budget)) {
      actions = actions.concat([
        {
          title: translate("order.hints.canApprove"),
          icon: <CheckCircleIcon className="primary" fontSize="small" />,
          onClick: onApproveBudget,
          getDisabled: (order) => order.ped_orcto_liberado,
        },
        {
          title: translate("order.hints.canOrder"),
          icon: <FlashOnIcon className="primary" fontSize="small" />,
          onClick: onGenerateOrder,
          getDisabled: (order) => !order.ped_orcto_aprovado,
        },
      ])
    }

    // =================
    // ==== COLUMNS ====
    // =================

    const renderOrderLink = ({ rowData: order }) => {
      const today = new Date().toLocaleDateString()
      const issuedDate = new Date(order.ped_data_emissao).toLocaleDateString()
      const isNew = issuedDate === today

      const path = paths.orders.items
        .replace(":orderType", orderType)
        .replace(":orderId", encodeURIComponent(order.id))

      return (
        <div className="d-flex align-items-center gap-2 flex-row text-nowrap">
          <Link to={path}>{order.cod_pedido}</Link>
          {isNew && (
            <Tooltip arrow title="Novo">
              <NewReleasesIcon fontSize="small" style={{ color: "#e91e63" }} />
            </Tooltip>
          )}
        </div>
      )
    }

    const renderApprovedStatus = ({ rowData: order }) => {
      const disabledApprovedChip = !isApproved(order)

      if (isMobile && disabledApprovedChip) {
        return null
      }

      return (
        <Chip
          disabled={disabledApprovedChip}
          background="success"
          label={translate("approved")}
        />
      )
    }

    const renderReservedStatus = ({ rowData: order }) => {
      const quantity = parseFloat(order.total_quantity)
      const reserved = parseFloat(order.total_quantity_reserved)

      const isReserved = reserved > 0
      const isPartiallyReserved = isReserved && reserved !== quantity

      const background = isPartiallyReserved ? "warning" : "info"

      const label = translate(
        isPartiallyReserved ? "partiallyReserved" : "reserved"
      )

      if (isMobile && !isReserved) {
        return null
      }

      return (
        <Chip disabled={!isReserved} background={background} label={label} />
      )
    }

    const renderOrderInfoMobile = ({ rowData: order }) => {
      const path = paths.orders.items
        .replace(":orderType", orderType)
        .replace(":orderId", encodeURIComponent(order.id))

      return (
        <div className="d-flex align-items-center flex-column text-nowrap">
          <Link to={path}>{order.cod_pedido}</Link>
          <Typography color="textSecondary" variant="body2">
            {formatter(order.ped_data_emissao).toSimpleDate()}
          </Typography>
        </div>
      )
    }

    const renderCustomerInfoMobile = ({ rowData: order }) => {
      return (
        <div>
          <Typography variant="body2">
            {order.customer.emp_razao_social}
          </Typography>
          <Typography gutterBottom color="textSecondary" variant="body2">
            {formatter(order.customer.id).toCNPJorCPF()}
          </Typography>

          <div className="d-flex">
            {renderApprovedStatus({ rowData: order })}
            {renderReservedStatus({ rowData: order })}
          </div>
        </div>
      )
    }

    let columns = isMobile
      ? [
          {
            field: "cod_pedido",
            title: translate("order"),
            render: renderOrderInfoMobile,
          },
          {
            field: "customer.emp_razao_social",
            title: translate("customer.name"),
            render: renderCustomerInfoMobile,
            sortable: false,
          },
        ]
      : [
          {
            field: "cod_pedido",
            title: translate("order"),
            render: renderOrderLink,
          },
          {
            field: "customer.emp_razao_social",
            title: translate("customer.name"),
            sortable: false,
          },
          {
            field: "customer.cod_empresa",
            title: translate("customer.document"),
            sortable: false,
            type: "document",
          },
          {
            field: "ped_data_emissao",
            title: translate("issue"),
            type: "date",
          },
          {
            field: "customer.emp_cidade",
            title: translate("city"),
            render: ({ rowData: order }) =>
              `${order.customer?.emp_cidade} - ${order.customer?.dsc_uf}`,
            sortable: false,
          },
          {
            field: "total_quantity",
            title: translate("quantity"),
            type: "decimal",
            align: "right",
            sortable: false,
          },
          {
            field: "total",
            title: translate("total"),
            type: "currency",
            align: "right",
            sortable: false,
          },
          {
            field: "ped_aprovado",
            title: "",
            render: renderApprovedStatus,
            sortable: false,
          },
          {
            field: "total_quantity_reserved",
            title: "",
            render: renderReservedStatus,
            sortable: false,
          },
        ]

    // =================
    // ==== STYLE ====
    // =================

    const rowStyle = (order) => {
      if (!isMobile) return {}
      if (isApproved(order)) {
        return {
          borderLeft: "5px solid green",
        }
      } else {
        return {
          borderLeft: "5px solid tranparent",
        }
      }
    }

    setTable({
      actions,
      columns,
      rowStyle,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType, data, isMobile])

  return (
    <Table
      size="small"
      data={data}
      columns={table.columns}
      actions={table.actions}
      options={{
        paging: true,
        sorting: true,
      }}
      rowStyle={table.rowStyle}
      {...props}
    />
  )
}

TableOrders.propTypes = {
  orderType: PropTypes.string,
  onFutureDeliveryConfig: PropTypes.func,
  onRemoveOrder: PropTypes.func,
  onPrintOrder: PropTypes.func,
  onEditOrder: PropTypes.func,
  onApproveBudget: PropTypes.func,
  onGenerateOrder: PropTypes.func,
  onGenerateBudget: PropTypes.func,
}

TableOrders.defaultProps = {
  data: [],
  count: 0,
}
