import React from "react"

import { TabPanel } from "@/components/Tabs"
import { Box } from "@material-ui/core"

import { Title } from "../components/Title"
import { CorporateStructure } from "../components/CorporateStructure"
import { NetworkCompanies } from "../components/NetworkCompanies"
import { useKeepMounted } from "./useKeepMounted"

export function TabCompany({ currentTab }) {
  const keepMounted = useKeepMounted()

  return (
    <TabPanel value={currentTab} index={2} keepMounted={keepMounted}>
      {/* === QUADRO SOCIETARIO === */}
      <Box>
        <Title>Quadro societário</Title>
        <CorporateStructure />
      </Box>

      {/* === EMPRESAS COLIGADAS === */}
      <Box>
        <Title>Empresas Coligadas</Title>
        <NetworkCompanies />
      </Box>
    </TabPanel>
  )
}
