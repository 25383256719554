import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import formatter from "../../services/formatter"
import ProgressBar from "../ProgressBar"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import { StyledTable } from "./styles"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

export const CELL_TYPES = {
  company: "company",
  decimal: "decimal",
  date: "date",
  progressBar: "progress-bar",
}

// ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
// ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

export const formatCell = (value, column, row) => {
  switch (column.type) {
    case CELL_TYPES.company:
      return formatter(value).toCNPJorCPF()
    case CELL_TYPES.decimal:
      return formatter(Number(value)).toDecimal()
    case CELL_TYPES.date:
      return formatter(value).toSimpleDate()
    case CELL_TYPES.progressBar:
      if (column.progress) {
        const title = formatter(row[column.progress.value]).toDecimal()
        return (
          <ProgressBar
            style={{ height: "0.8rem" }}
            title={title}
            value={value}
          />
        )
      } else return null
    default:
      return value
  }
}

/**
 * Simple Table component.
 *
 * @typedef {({
 *      color: 'primary' | 'info' | 'success' | 'error'
 *      data: any[]
 *      columns: Array<{
 *          field: string
 *          align: 'left' | 'center' | 'right'
 *          title: string
 *          type: 'company' | 'decimal' | 'date' | 'progress-bar'
 *          progress: {
 *              value: number | string
 *              title?: string
 *          }
 *      }>
 * })} CardTableProps
 *
 * @param {CardTableProps} props
 * @returns {JSX.Element}
 */
export default function CardTable(props) {
  const { columns, data, color } = props

  const renderTableHeadCell = (column, columnIndex) => (
    <TableCell key={columnIndex} align={column.align} className={clsx(color)}>
      {column.title}
    </TableCell>
  )

  const renderTableCell = (column, row, columnIndex) => {
    const cellValue = row[column.field]
    const formattedValue = formatCell(cellValue, column, row)

    return (
      <TableCell
        key={columnIndex}
        align={column.align}
        className={clsx({
          [`MuiTableCell-${column.type}`]: !!column.type,
        })}
      >
        {formattedValue}
      </TableCell>
    )
  }

  const renderTableRow = (row, rowIndex) => {
    return (
      <TableRow hover key={rowIndex}>
        {columns.map((column, columnIndex) =>
          renderTableCell(column, row, columnIndex)
        )}
      </TableRow>
    )
  }

  return (
    <StyledTable size="medium">
      <TableHead>
        <TableRow>{columns.map(renderTableHeadCell)}</TableRow>
      </TableHead>
      <TableBody>{data.map(renderTableRow)}</TableBody>
    </StyledTable>
  )
}

CardTable.propTypes = {
  color: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      align: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      type: PropTypes.oneOf([...Object.values(CELL_TYPES)]),
      progress: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
      }),
    })
  ).isRequired,
}

CardTable.defaultProps = {
  color: "primary",
  data: [],
}
