import React from "react"
import paths from "../consts/paths"
import { EXTRACT_URL_PARAMS_REGEXP } from "../consts/regexp"
import { Switch } from "react-router-dom"

import Route from "../components/PrivateRoute"
import IssuedOrdersReport from "../pages/Representative/Reports/PedidosEmitidos"
import BilledOrdersReport from "../pages/Representative/Reports/PedidosFaturados"
// import CancelledOrdersReport from "../pages/Representative/Reports/PedidosCancelados"
import WalletOrdersReport from "../pages/Representative/Reports/PedidosEmCarteira"
import InventoryReport from "../pages/Representative/Reports/Estoque"
import CustomersReport from "../pages/Representative/Reports/Clientes"
// import CommercialConditionReport from "../pages/Representative/Reports/CondicaoComercial"
// import ComissionReport from "../pages/Representative/Reports/Comissao"
// import CurrentAccount from "../pages/Representative/Reports/ContaCorrente"
// import DuplicatesToReceiveReport from "../pages/Representative/Reports/Duplicatas"

export default function ReportRoutes() {
  const createPath = (id) =>
    paths.reports.root.replace(EXTRACT_URL_PARAMS_REGEXP, id)

  return (
    <Switch>
      <Route path={createPath(1)} component={IssuedOrdersReport} exact />
      <Route path={createPath(2)} component={BilledOrdersReport} exact />
      {/* <Route path={createPath(3)} component={CancelledOrdersReport} exact /> */}
      <Route path={createPath(4)} component={WalletOrdersReport} exact />
      <Route path={createPath(5)} component={InventoryReport} exact />
      <Route path={createPath(6)} component={CustomersReport} exact />
      {/* <Route path={createPath(7)} component={CommercialConditionReport} exact /> */}
      {/* <Route path={createPath(8)} component={ComissionReport} exact /> */}
      {/* <Route path={createPath(9)} component={CurrentAccount} exact /> */}
      {/* <Route
        path={createPath(10)}
        component={DuplicatesToReceiveReport}
        exact
      /> */}
    </Switch>
  )
}
