import styled from "styled-components"
import { ListItem, Modal, Avatar } from "@material-ui/core"
import MuiDeleteIcon from "@material-ui/icons/Delete"

export const StyledListItem = styled(ListItem)`
  .MuiListItemText-primary {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const StyledAvatar = styled(Avatar)``

export const DeleteIcon = styled(MuiDeleteIcon)``

export const StyledModal = styled(Modal)`
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
`

export const ModalContent = styled.img`
  max-height: max(600px, 50vh);
  max-width: max(600px, 50vh);

  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 10px;

  transition: 200ms ease-in-out;
  &:hover {
    cursor: zoom-in;
    transition: 200ms ease-in-out;
    transform: scale(1.5);
  }
`
