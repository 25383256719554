import React, { useMemo, useCallback, createContext, useContext } from "react"
import { ThemeProvider as StyledThemeProvider } from "styled-components"
import { CssBaseline, ThemeProvider } from "@material-ui/core"

import { THEME } from "../consts/storage"
import themes from "../theme"
import { usePersistedState } from "../hooks/usePersistedState"

export const AppThemeContext = createContext({
  theme: null,
  themeName: "",
  onChangeTheme: () => {},
})

export const useAppTheme = () => {
  const context = useContext(AppThemeContext)
  return context
}

export function AppThemeProvider({ children }) {
  const [themeName, setThemeName] = usePersistedState(THEME, "topazio")
  const theme = useMemo(() => themes[themeName] || themes.topazio, [themeName])

  const onChangeTheme = useCallback((themeName) => {
    setThemeName(themeName)
  }, [])

  return (
    <AppThemeContext.Provider
      value={{
        theme,
        themeName,
        onChangeTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </ThemeProvider>
    </AppThemeContext.Provider>
  )
}
