import React from "react"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { useFetch } from "../../../hooks"
import { FormCombobox } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const COMPANIES_STALE_TIME = 1000 * 60 * 10 // 10 minutes

/** This component can only be used inside a `Form` */
export default function ComboboxCompanies(props) {
  const {
    data: companies,
    isFetching,
    isError,
  } = useFetch({
    clientName: "filter-companies",
    url: apiEndPoints.miscellaneous.filterCompanies,
    staleTime: COMPANIES_STALE_TIME,
  })

  const companiesOptions = (companies || []).map((company) => ({
    value: company.cod_cia,
    label: company.dsc_cia,
  }))

  return (
    <FormCombobox
      options={companiesOptions}
      loading={isFetching}
      error={isError}
      {...props}
    />
  )
}
