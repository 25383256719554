import styled from "styled-components"
import MuiListItem from "@material-ui/core/ListItem"

export const StyledListItem = styled(MuiListItem)`
  height: 4rem;
  border-left: 5px solid transparent;

  .MuiListItemText-primary,
  .MuiSvgIcon-root {
    transition: 200ms ease-in-out;
  }

  .MuiSvgIcon-root {
    transform: scale(1.1);
  }

  &.shift {
    .MuiListItemText-primary {
      opacity: 0;
    }

    .MuiSvgIcon-root {
      transform: scale(1);
    }
  }

  ${(props) => props.theme.breakpoints.up("sm")} {
    &.Mui-selected,
    &:hover {
      border-left-color: ${(props) => props.theme.palette.secondary.light};
      color: currentColor;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-width: 55vw;
    height: 3rem;

    .MuiListItemIcon-root {
      display: none;
    }

    .MuiIconButton-root {
      padding: 0;
      margin-left: 1rem;
    }
  }
`

export const CollapseListItem = styled(StyledListItem)`
  height: 3rem;
`
