import { LOCALES } from "../locales"

export const enUs = {
  [LOCALES.ENGLISH]: {
    /** LANGUAGES */
    english: "English",
    spanish: "Spanish",
    portuguese: "Portuguese",
    /** HOME */
    "home.dashboard.quotaAchievement": "ORDER QUOTA AVAILABLE",
    "home.dashboard.averagePortifolioOrders":
      "AVERAGE TIME OF PORTFOLIO ORDERS",
    "home.dashboard.goal": "FINANCIAL GOAL",
    "home.dashboard.consumptionQuotaByRepresentative":
      "QUOTA CONSUMPTION BY REPRESENTATIVE",
    "home.dashboard.representativesWithHigherBilling":
      "REPRESENTATIVES WITH HIGHER BILLING",
    "home.dashboard.customersWithHigherBilling":
      "CUSTOMERS WITH HIGHER BILLING",
    "home.dashboard.customersWithMoreDelayedTitles":
      "CUSTOMERS WITH MORE DELAYED TITLES",
    "home.dashboard.lastSixMonthsBilling": "LAST 6 MONTHS BILLING",
    "home.dashboard.loadingText": "loading your informations...",
    "home.dashboard.updatedAt": "Updated at {date}",
    /** ORDERS */
    "order.menu.title": "My Orders",
    "order.yourOrder": "Your Order",
    "order.productType": "Product Type",
    "order.note": "Note",
    "order.futureDelivery": "Future delivery",
    "order.indirectExport": "indirect Export",
    "order.deliveryType": "Transport type",
    "order.deliveryType.withdrawnByCustomer": "Withdrawn By Customer",
    "order.deliveryType.determinedByCustomer": "Determined By Customer",
    "order.new": "New Order",
    "order.item.weight": "Weight",
    "order.item.quantity": "Quantity",
    "order.item.includeItemtext": "Add item to Order",
    order: "Order",
    budget: "Budget",
    "order.item.title": "{order} | Items",
    "order.item.excludeText": "Do you confirm product({product}) deletion ?",
    "order.item.excludeTextLastItemConfirm":
      "Do you confirm the deletion of Item and Order ?",
    "order.item.excludeTextLastItem":
      "As this is the last item, the Order will be deleted as well.",
    "order.report.title": "Sales Order ({order})",
    "order.report.orderData": "Order Data",
    "order.futureDelivery.initials": "FD",
    "order.futureDelivery.title": "Future Delivery",
    "order.futureDelivery.title.missingInfo":
      "Future Delivery - Required to complete the information",
    "order.hints.canApprove": "Release for Evaluation",
    "order.hints.canOrder": "Generate Sales Order",
    "order.budgetConfirm.title": "{previousOrderType} -> {newOrderType}",
    "order.budgetConfirm.text":
      "Do you confirm {orderType} ({order}) generation ?",
    "order.budgetConfirm.textSecondary":
      "Some budget items do not have available balance. Do you want to continue without these items?",
    "order.budgetConfirm.buttonLabel": "Generate {orderType}",
    "order.budgetConfirm.message.success":
      "{orderType} {order} generated successfully!",
    "order.budgetApproval.title": "Release Budget",
    "order.budgetApproval.text": "Confirm the release of budget {order} ?",
    "order.budgetApproval.buttonLabel": "Release Budget",
    "order.budgetApproval.message.success":
      "Budget {order} successfully released!",

    /** PROFORMAS */
    "proforma.page.title": "My Proformas",
    "proforma.codigo": "Proforma Nr.",
    "proforma.emissao": "Issue Date",
    "proforma.codpagto": "Payment Conditions",
    "proforma.paisorigem": "Country of Origin",
    "proforma.importador": "Bill To",
    "proforma.previsaoemb": "Previous Shipment Date",
    "proforma.moeda": "Currency",
    "proforma.marca": "Marks",
    "proforma.viatransporte": "Transportation",
    "proforma.portoembarque": "Shipping Port",
    "proforma.portodescarga": "Port of Discharge",
    "proforma.localentrega": "Place of Delivery",
    "proforma.numpallets": "Qty of Pallets",
    "proforma.declaracao": "Declaration",
    "proforma.numcontainers": "Qty of Containers",
    "proforma.pesobruto": "Gross Weight",
    "proforma.pesoliquido": "Net Weight",
    "proforma.item.pallets": "Pallets",
    "proforma.item.caixas": "Cartons",
    "proforma.item.sku": "SKU",
    "proforma.item.pecas": "Pieces",
    "proforma.item.metros": "Sqm",
    "proforma.item.descricao": "Description of Goods",
    "proforma.item.pesobruto": "Gross Weight",
    "proforma.item.pesoliquido": "Net Weight",
    "proforma.item.precounitario": "Unit.Price",
    "proforma.item.total": "Total",
    "proforma.sendAttachment.title": "Send proforma signed by the customer",
    "proforma.attachments.title": "Attachments ({proforma})",
    "proforma.finish": "Do you finish Proforma ({proforma}) ?",
    "proforma.copy": "Copy Proforma",
    "proforma.sendAttachment.inputLabel": "Click to attach the signed proforma",
    "proforma.visto": "Signature",
    "proforma.subtotal": "Sub-Total Amount {currency}",
    "proforma.new": "New Proforma",
    "proforma.approve": "Approve",
    "proforma.sendApprovalEmail": "Send approval email",
    "proforma.resendApprovalEmail": "Resend approval email",
    "proforma.excludeText": "Confirm deletion of proforma ({proforma})?",
    "proforma.loadingText": "Loading your proforma...",
    "proforma.upload.loadingText": "Uploading files...",
    "proforma.download.loadingText": "Downloading files...",
    proforma: "Proforma",
    "proforma.menu.title": "My Proformas",
    "proforma.item.excludeText": "Do you confirm product({product}) deletion?",
    "proforma.item.excludeTextLastItemConfirm":
      "Do you confirm the deletion of Item and Proforma ?",
    "proforma.item.excludeTextLastItem":
      "As this is the last item, the Proforma will be deleted as well.",
    language: "Language",
    "payment.condition": "Payment Condition",
    "pallets.container": "Pallets per Container",
    freight: "Freight",
    "document.type": "Document",
    "document.kind": "Document Type",
    "document.number": "Document Number",
    "document.date": "Document Date",
    "arrival.place": "Port of Discharge",
    "boarding.place": "Shipping Port",
    "discharge.place": "Place of Delivery",
    transport: "Transport",
    transportation: "Transportation",
    "unit.value.per.box": "Unit Value Per Box",
    "planned.shipment": "Shipment Schedule",
    /** ITEMS */
    items: "Items",
    item: "Item",
    "product.group": "Product Group",
    "product.format": "Format",
    "product.formatText": "FORMAT {format}",
    "product.lineText": "LINE {line}",
    "product.code": "Code",
    "product.ref": "Ref.",
    "product.specs": "Gauge: {gauge} - Hue: {hue}",
    "measure.unit": "Mes.Un.",
    "unit.value": "Unit Value",
    "approved.value": "Approved Value.",
    "new.item": "New Item",
    /** REPORTS */
    "bulletins.menu.title": "Bulletins",
    "bulletins.page.title": "Bulletins",
    "bulletins.comunicado": "Bulletin",
    "bulletins.emissao": "Issue Date",
    "bulletins.remetente": "Sender",
    "bulletins.assunto": "Subject",
    "bulletin.subject": "Subject: {subject}",
    "bulletin.sendedBy": "Sended by {sender} in {date}",
    "bulletin.attachments.label": "Attachments",
    /** TERMS */
    "terms.menu.title": "Terms",
    "terms.page.title": "Terms",
    "terms.comunicado": "Term",
    "terms.emissao": "Issue Date",
    "terms.remetente": "Sender",
    "terms.assunto": "Subject",
    "term.subject": "Subject: {subject}",
    "term.sendedBy": "Sended by {sender} in {date}",
    "term.attachments.label": "Attachments",
    /** RELATORIOS */
    reports: "Reports",
    report: "Report - {report}",
    "generate.report": "Generate Report",
    "reports.paidinstallments": "Paid Installments",
    "calculation.basis": "Calculation Basis",
    "delete.already.paid": "Delete already paid?",
    "report.loadingText": "Loading report {report}...",
    "report.commissions.predicted": "Predicted Commissions by Period",
    "report.commissions.paidOff": "Checks Paid Off Per Period",
    "report.commissions.returned": "Returned Checks by Period",
    /** VERBS */
    cancel: "Cancel",
    canceled: "Canceled",
    changeLanguage: "Change language",
    clear: "Clear",
    close: "Close",
    loadingOptions: "Loading options...",
    loadingrecords: "Loading records",
    print: "Print",
    reload: "Reload",
    save: "Save",
    release: "Release",
    finish: "Finish",
    send: "Send",
    sendTo: "Send to {receiver}",
    search: "Search",
    select: "Select",
    "searching.customers": "Searching Customers...",
    "search.filters": "Search Filters",
    edit: "Edit",
    view: "View",
    delete: "Delete",
    /** FLAGS */
    created: "Created",
    pending: "Pending...",
    accepted: "Accepted",
    finalized: "Finalized",
    reserved: "Reserved",
    partiallyReserved: "Part. Reser.",
    released: "Released",
    approved: "Approved",
    finished: "Fulfilled",
    waiting: "Waiting",
    rejected: "Rejected",
    unfulfilled: "Unfulfilled",
    fulfilled: "Fulfilled",
    cancelled: "Cancelled",
    /** MESSAGES */
    nonReversibleAction: "Warning: This action cannot be undone!",
    "messages.sendMail": "Email successfully sent.",
    /** ERRORS */
    "report.error.competenceIsMissing": "Competence is required",
    "no.value": "Value missing",
    "value.lower": "Value must be lower than {value}",
    "value.higher": "Value must be higher than {value}",
    "blocked.customer": "Customer is blocked, contact the finance department.",
    "inactive.customer":
      "Customer is inactive, upon entering an order it will be activated again.",
    "order.sample.typeProduct.required": "Choose the product type",
    /** PAGINATOR */
    "records.per.page": "Records per page",
    pagedescr: "Page: {number} of {total}",
    norecordsfound: "No records found",
    registers: "Records found: {count}",
    /** TERMS */
    agent: "Agent",
    all: "All",
    back: "Back",
    carrier: "Carrier",
    "carrier.add": "Additional Carrier {num}",
    city: "City",
    bank: "Bank",
    agency: "Ag.",
    account: "Account",
    cheque: "Check",
    commissions: "Commissions",
    commision: "Commission",
    commercial: "Commercial",
    company: "Billing Company",
    competence: "Competence",
    currency: "Currency",
    "customer.name": "Customer",
    "customer.document": "Document",
    days: "days",
    "due.date": "Due Date",
    document: "Document",
    issue: "Issue",
    "issued.date": "Issued",
    "issued.at": "Issued at {date}",
    predicted: "Predicted",
    paidOffs: "Paid Offs",
    paidOff: "Paid Off",
    quantity: "Quantity",
    readed: "Read",
    representative: "Representative",
    returneds: "Returneds",
    returned: "Returned",
    sample: "Sample",
    state: "State",
    total: "Total",
    title: "Title",
    today: "Today",
    unreaded: "Unread",
    value: "Value",
    brand: "Brand",
    group: "Group",
    line: "line",
    product: "Product",
    kit: "Kit",
    parts: "Parts",
    boxes: "Boxes",
    tags: "Tags",
    pallets: "Pallets",
    goal: "Goal {goal}",
    arOrder: "A/R Order",

    _requiredField: "{field} is required",
    defaultDialogMessage: "Do you confirm deletion of {type} ({register}) ?",
    handleAjaxError: "There was an error communicating with the server: {err}",
    handleUnprocessableEntity: "Please fix all errors reported by the server.",
    signout: "Exit",
  },
}

export default enUs
