import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Box, LinearProgress, Typography } from "@material-ui/core"

// ------------------------------------------------

const LoaderContainer = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 100;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`

// ------------------------------------------------

export const LOADER_TYPES = {
  save: "save",
  send: "send",
  upload: "upload",
}

const LOADER_TYPES_LABELS = {
  save: "Salvando dados do cliente...",
  send: "Enviando ficha cadastral...",
  upload: "Baixando arquivos...",
}

export function Loader(props) {
  const { loading, type } = props

  if (!loading) return null

  return (
    <LoaderContainer>
      <Box width="100%" maxWidth={400}>
        <Typography variant="h6">{LOADER_TYPES_LABELS[type]}</Typography>

        <Typography color="textSecondary" style={{ marginBottom: "2rem" }}>
          Aguarde alguns instantes..
        </Typography>

        <LinearProgress variant="indeterminate" />
      </Box>
    </LoaderContainer>
  )
}

Loader.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.string,
}
