import React from "react"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { useFetch } from "../../../hooks"
import { FormCombobox } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const STALE_TIME = 1000 * 60 * 10 // 10 minutes

/** This component can only be used inside a `Form` */
export default function ComboboxDeliveryWeeks(props) {
  const { data, isFetching } = useFetch({
    clientName: "deliveryWeeks",
    url: apiEndPoints.miscellaneous.deliveryWeeks,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
  })

  const options = (data || []).map((item) => ({
    value: item.id,
    label: item.descr,
  }))

  return (
    <FormCombobox
      disableClearable
      options={options}
      loading={isFetching}
      {...props}
    />
  )
}

ComboboxDeliveryWeeks.propTypes = {}

ComboboxDeliveryWeeks.defaultProps = {}
