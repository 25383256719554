import React from "react"
import { equals } from "ramda"
import { StyledKeyboardDatePicker } from "./styles"
import { BASE_DIALOG_Z_INDEX } from "../../BaseDialog"
import TextField from "../../TextField"

export default function DatePickerBase(props) {
  const variantProps = (variant) => {
    const variantIs = equals(variant)
    if (variantIs("dialog"))
      return {
        showTodayButton: true,
        todayLabel: "Hoje",
        cancelLabel: "Cancelar",
      }
    else return {}
  }

  return (
    <StyledKeyboardDatePicker
      className="MuiDatePicker-root"
      autoOk
      format="dd/MM/yyyy"
      margin="none"
      invalidDateMessage="Data inválida"
      minDateMessage="A data não poder ser anterior à data mínima"
      maxDateMessage="A data não poder ser posterior à data máxima"
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      PopoverProps={{
        style: {
          zIndex: BASE_DIALOG_Z_INDEX + 1,
        },
      }}
      disableToolbar
      TextFieldComponent={TextField}
      {...variantProps(props.variant)}
      {...props}
    />
  )
}

DatePickerBase.defaultProps = {
  variant: "inline",
}
