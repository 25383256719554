import React from "react"
import PropTypes from "prop-types"
import {
  FormControlLabel,
  Checkbox as MuiCheckBox,
  FormHelperText,
} from "@material-ui/core"

export default function CheckBox({
  label,
  error,
  helperText,
  onChange,
  value,
  ...props
}) {
  const handleChange = (e) => {
    if (onChange) {
      const checked = e.target.checked
      onChange({ target: { name: props.name, value: checked } })
    }
  }

  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <MuiCheckBox
            onChange={handleChange}
            color="primary"
            checked={Boolean(value)}
            value={Boolean(value)}
            {...props}
          />
        }
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </>
  )
}

CheckBox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  // value: PropTypes.bool,
  name: PropTypes.string,
}
