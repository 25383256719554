import React, { useState, Fragment } from "react"
import { Box, Chip, Typography } from "@material-ui/core"

import api from "../../../../services/api"
import apiEndPoints from "../../../../consts/apiEndPoints"
import { Yup } from "../../../../services/yup"
import { sumProp } from "../../../../services/utils"
import { useReportsContext } from "../../../../contexts/Reports"

import Filter from "../../../../components/Reports/Filter"
import Table from "../../../../components/Table"
import ComboboxCompanies from "../../../../components/Combobox/Companies"
import ComboboxCustomers from "../../../../components/Combobox/Customers"
import ComboboxProductRefs from "../../../../components/Combobox/ProductRefs"

import { Duplicates } from "../../../../components/Reports/Duplicates"
import { SummaryContainer } from "./styles"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const initialState = {
  report: [],
  summary: [],
}

//  ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
//  ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
//  ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const schema = Yup.object().shape({
  di: Yup.string().required("Informe a data inicial").nullable(),
  df: Yup.string().required("Informe a data final").nullable(),
})

export default function BilledOrdersReport() {
  const { setLoadingText, minDate, maxDate } = useReportsContext()
  const [data, setData] = useState({ ...initialState })

  const fetchReport = async (values) => {
    setData({ ...initialState })
    setLoadingText("Montando relatório de pedidos faturados...")

    try {
      const response = await api.post(apiEndPoints.reports.billedOrders, {
        filtros: { ...values },
      })

      const report = response.data.report.map((item) => {
        let duplicateId = item.nf_numero
        duplicateId += item.nf_serie.trim()
        duplicateId += item.id_cia

        return {
          ...item,
          duplicateId,
        }
      })

      const summary = response.data.summary.map((item) => {
        let duplicateId = item.cr_num_duplicata.slice(0, -2)
        duplicateId += item.cr_serie.trim()
        duplicateId += item.id_cia

        return {
          ...item,
          duplicateId,
        }
      })

      setData({ report, summary })
    } catch (error) {
      setData({ ...initialState })
    }

    setLoadingText("")
  }

  const handleSubmit = (values) => {
    const params = { ...values }
    fetchReport(params)
  }

  const getDuplicates = (duplicateId) => {
    const duplicates = data.summary.filter(
      (item) => item.duplicateId === duplicateId
    )

    return duplicates
  }

  const getShowDetailPanel = (row) => {
    const duplicates = getDuplicates(row.duplicateId)
    return duplicates.length > 0
  }

  return (
    <>
      <Filter
        onSubmit={handleSubmit}
        schema={schema}
        fields={[
          {
            name: "di",
            label: "Data Inicial",
            type: "date",
            defaultValue: minDate,
            size: 2,
          },
          {
            name: "df",
            label: "Data Final",
            type: "date",
            defaultValue: maxDate,
            size: 2,
          },
          {
            name: "cod_cia",
            label: "Companhia",
            defaultValue: 1,
            size: 2,
            component: ComboboxCompanies,
            componentProps: {
              disableClearable: true,
            },
          },
          {
            name: "ref",
            label: "Descrição do Produto...",
            defaultValue: "",
            size: 2,
          },
          {
            name: "classif",
            label: "Ref.",
            defaultValue: -1,
            size: 1,
            component: ComboboxProductRefs,
          },
          {
            name: "cod_cliente",
            label: "Cliente",
            defaultValue: "Todos",
            component: ComboboxCustomers,
            componentProps: {
              onLoadOptions: (customer) => ({
                value: customer.id,
                label: customer.emp_razao_social,
              }),
            },
            size: 3,
          },
          {
            name: "gera_fatur",
            label: "Gera Faturamento",
            type: "checkbox",
            defaultValue: 1,
            size: 3,
          },
        ]}
      />
      {data.report.length > 0 && (
        <Fragment>
          {/* ==== PEDIDOS FATURADOS ==== */}
          <Table
            size="small"
            data={data.report}
            options={{ total: true }}
            columns={[
              { title: "Pedido", field: "cod_pedido" },
              { title: "Seu pedido", field: "ped_seupedido" },
              { title: "N. Fiscal", field: "nf_numero" },
              { title: "Emissão", field: "emissao" },
              { title: "Saída", field: "nf_data_saida" },
              { title: "Cliente", field: "cliente" },
              { title: "Produto", field: "dsc_abreviado" },
              { title: "Ref", field: "prd_referencia", align: "center" },
              { title: "Bit", field: "cnf_bitola", align: "center" },
              { title: "Ton", field: "cnf_tonalidade", align: "center" },
              { title: "Lote", field: "cnf_lote", align: "left" },
              { title: "UN", field: "dsc_un_medidas", align: "center" },
              {
                title: "Quantidade",
                field: "qtdade",
                align: "right",
                type: "decimal",
                getTotal: ({ data }) => sumProp("qtdade", data),
              },
              {
                title: "Pr.Unit",
                field: "it_valor",
                align: "right",
                type: "currency",
              },
              {
                title: "Valor",
                field: "valor",
                align: "right",
                type: "currency",
                getTotal: ({ data }) => sumProp("valor", data),
              },
              { title: "Fatur", field: "dsc_tipo" },
            ]}
            getShowDetailPanel={getShowDetailPanel}
            detailPanel={({ row }) => {
              const duplicates = getDuplicates(row.duplicateId)

              if (duplicates.length > 0) {
                return <Duplicates data={duplicates} />
              }

              return null
            }}
          />

          {/* ==== LENGEDA ==== */}
          <Box>
            <Typography variant="body1">Legenda</Typography>

            <SummaryContainer>
              <Chip className="loaded" size="medium" label="BAIXADAS" />
              <Chip className="expirated" size="medium" label="VENCIDAS" />
              <Chip size="medium" label="A VENCER" />
            </SummaryContainer>
          </Box>
        </Fragment>
      )}
    </>
  )
}
