import React, { createContext, useState, useContext, useCallback } from "react"

export const NotificationsContext = createContext({
  notifications: {
    bulletins: 0,
  },
  updateNotification: () => {},
})

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext)
  return context
}

export function NotificationsProvider({ children }) {
  const [notifications, setNotifications] = useState({
    bulletins: 0,
  })

  const updateNotification = useCallback((key, cb) => {
    setNotifications((previousNotifications) => {
      const newValue = cb(previousNotifications[key])
      return {
        ...previousNotifications,
        [key]: newValue >= 0 ? newValue : 0,
      }
    })
  }, [])

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        updateNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
