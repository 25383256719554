import React from "react"
import { ORDER_TYPES } from "../../../consts/types"
import { paths } from "../../../consts/paths"
import { useNotificationsContext } from "../../../contexts/Notifications"
import { translate } from "../../../_i18n"

import Home from "@material-ui/icons/Home"
import BarChart from "@material-ui/icons/BarChart"
import Notifications from "@material-ui/icons/Notifications"
import List from "@material-ui/icons/List"
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined"
import Divider from "@material-ui/core/Divider"
import MenuItem from "../../MenuItem"

export default function RepresentativeMenuItems() {
  const { notifications } = useNotificationsContext()

  return (
    <>
      <MenuItem to={paths.profile.home} label="Home" Icon={Home} />
      <MenuItem
        to={paths.orders.root}
        label="Meus Pedidos"
        Icon={BarChart}
        options={[
          {
            label: translate("commercial"),
            value: ORDER_TYPES.regular,
          },
        ]}
      />
      <MenuItem
        to={paths.registers.root}
        label="Cadastros"
        Icon={AssignmentIcon}
      />
      <MenuItem
        to={paths.bulletins.root}
        label="Comunicados"
        Icon={Notifications}
        badgeContent={notifications.bulletins}
      />
      <Divider />
      <MenuItem
        to={paths.reports.root}
        label="Relatórios"
        Icon={List}
        isActive={(pathname, to) => {
          const splited = to.split("/")
          const root = splited.slice(0, splited.length - 1).join("/")
          return pathname.includes(root)
        }}
      />
    </>
  )
}
