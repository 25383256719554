import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { formatter } from "../../services/formatter"

import LinearProgress from "@material-ui/core/LinearProgress"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import Box from "@material-ui/core/Box"

import { Container } from "./styles"

export default function ProgressBar({ title, ...props }) {
  const value = Number(props.value || 0)
  const roundedValue = Math.round(value)
  const formattedValue = `${formatter(value).toDecimal(2)}%`

  return (
    <Container>
      <Box width="100%" mr={1}>
        <Tooltip arrow title={title ?? formattedValue}>
          <LinearProgress
            {...props}
            value={value}
            variant="determinate"
            className={clsx({
              danger: roundedValue <= 30,
              warn: roundedValue > 30 && roundedValue <= 60,
              success: roundedValue > 60,
            })}
          />
        </Tooltip>
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {formattedValue}
        </Typography>
      </Box>
    </Container>
  )
}

ProgressBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
}
