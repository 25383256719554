import styled from "styled-components"
import { darken } from "polished"
import { Tabs } from "../../../components/Tabs"

export const Toolbar = styled.div`
  background-color: ${(props) =>
    darken(0.06, props.theme.palette.background.default)};

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const StyledTabs = styled(Tabs)`
  a {
    color: currentColor;
    text-decoration: none;
  }

  @media (max-width: 600px) {
    a.MuiTab-root {
      flex-grow: 1;
    }
  }
`
