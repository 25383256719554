import React, { useRef, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import {
  Container,
  Header,
  ControlsContainer,
  ContentContainer,
  SideContainer,
  ChildrenContainer,
} from "./styles"

export default function ContentPage({
  className,
  title,
  showReturn,
  controls,
  children,
  sideComponent,
  headerComponent,
  contentProps,
  useDefaultPaper,
}) {
  const history = useHistory()

  const [isHeaderOver, setIsHeaderOver] = useState(false)
  const contentRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const { top } = contentRef.current.getBoundingClientRect()
        setIsHeaderOver(top < 0)
      }
    }

    window.addEventListener("scroll", handleScroll, true)
    return () => {
      window.removeEventListener("scroll", handleScroll, true)
    }
  }, [])

  const handleReturn = () => history.goBack()

  return (
    <Container className={className}>
      <Header className="header" isOver={isHeaderOver}>
        {headerComponent || (
          <Typography className="header-container" component="div">
            {showReturn && (
              <Tooltip title="Voltar">
                <IconButton color="primary" size="small" onClick={handleReturn}>
                  <ChevronLeft />
                </IconButton>
              </Tooltip>
            )}

            <span className="title">{title}</span>
          </Typography>
        )}
        {controls && (
          <ControlsContainer className="controls">{controls}</ControlsContainer>
        )}
      </Header>
      <ContentContainer ref={contentRef} {...contentProps}>
        {useDefaultPaper ? (
          <ChildrenContainer>{children}</ChildrenContainer>
        ) : (
          children
        )}

        {sideComponent && (
          <SideContainer ref={contentRef}>{sideComponent}</SideContainer>
        )}
      </ContentContainer>
    </Container>
  )
}

ContentPage.propTypes = {
  className: PropTypes.string,
  showReturn: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  controls: PropTypes.element,
  children: PropTypes.element.isRequired,
  headerComponent: PropTypes.element,
  sideComponent: PropTypes.element,
  contentProps: PropTypes.object,
}

ContentPage.defaultProps = {
  showReturn: false,
  className: "",
  title: "",
  controls: null,
  headerComponent: null,
  sideComponent: null,
  useDefaultPaper: true,
}
