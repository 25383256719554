import styled from "styled-components"
import { toPx } from "../../services/utils"

import MuiAppBar from "@material-ui/core/AppBar"
import MuiToolbar from "@material-ui/core/Toolbar"

export const StyledAppBar = styled(MuiAppBar)`
  box-shadow: rgb(0 0 0 / 4%) 0px 2px 6px 0px;
  transition: ${({ theme }) =>
    theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};

  .Logo {
    flex: 1;
    display: flex;
  }

  @media screen and (min-width: 960px) {
    width: ${({ theme }) => `calc(100% - ${toPx(theme.drawerWidthShift)})`};
    margin-left: ${({ theme }) => toPx(theme.drawerWidthShift)};

    &.AppBar-shift {
      width: ${({ theme }) => `calc(100% - ${toPx(theme.drawerWidth)})`};
      margin-left: ${({ theme }) => toPx(theme.drawerWidth)};
      transition: ${({ theme }) =>
        theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })};

      #open-menu-button {
        display: none;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .Logo {
      justify-content: center;

      img {
        max-width: 120px;
      }
    }
  }
`

export const StyledToolbar = styled(MuiToolbar)`
  max-height: 48px;

  #open-menu-button {
    margin-right: ${({ theme }) => toPx(theme.spacing(4))};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    #open-menu-button {
      margin-right: ${({ theme }) => toPx(theme.spacing(2))};
    }
  }

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: auto !important;
    &.MuiToolbar-gutters {
      padding-right: 0;
    }
  }
`
