import React from "react"
import PropTypes from "prop-types"
import { formatFileSize } from "../../services/file"
import { translate } from "../../_i18n"

import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"

import { Container, ListContainer } from "./styles"
import FileInput from "./FileInput"
import Preview from "./Preview"

export default function ImageUploader(props) {
  const {
    files,
    attachable,
    multiple,
    label,
    name,
    onPreview,
    onAdd,
    onDelete,
  } = props

  const totalSize = files.reduce((acc, a) => {
    acc = acc + a.size
    return acc
  }, 0)

  return (
    <Container>
      {attachable && (
        <FileInput
          label={label}
          name={name}
          onChange={onAdd}
          multiple={multiple}
        />
      )}
      {files.length > 0 && (
        <ListContainer>
          <div className="d-flex justify-content-between mb-1">
            <Typography variant="body2" component="div">
              {translate("bulletin.attachments.label")}
            </Typography>
            <Typography variant="body2" component="div">
              {formatFileSize(totalSize)}
            </Typography>
          </div>

          <List>
            {files.map((file, index) => (
              <Preview
                key={index}
                file={file}
                disabledDelete={!attachable}
                onPreviewFile={onPreview}
                onDelete={onDelete}
              />
            ))}
          </List>
        </ListContainer>
      )}
    </Container>
  )
}

ImageUploader.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  attachable: PropTypes.bool,
  multiple: PropTypes.bool,
  onPreview: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
}

ImageUploader.defaultProps = {
  attachable: false,
  multiple: false,
  loading: false,
}
