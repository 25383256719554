import React from "react"
import { Container } from "./styles"

export default function Footer() {
  return (
    <Container>
      <div />

      <small>
        Desenvolvido por Ecosis Equipamentos Consultoria em Sistemas - Copyright
        © 2024 Ecosis. Todos os direitos reservados.
      </small>

      <div>
        <a target="_blank" rel="noreferrer" href="https://www.ecosis.com.br">
          <img src="/assets/logo-ecosis.png" alt=" " />
        </a>
      </div>
    </Container>
  )
}
