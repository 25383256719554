import Button from "@/components/Button"
import Paper from "@/components/Paper"
import styled from "styled-components"

export const AccountPageContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 4rem auto 0;
`

export const AccountPageFormContainer = styled(Paper)`
  padding: 2rem;
`

export const EndSessionButton = styled(Button)`
  border-color: ${(props) => props.theme.palette.error.main};

  .MuiButton-label {
    color: ${(props) => props.theme.palette.error.main};
  }
`
