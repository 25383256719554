import styled from "styled-components"

export const Container = styled.div`
  width: 100%;

  svg {
    g.ct-series,
    g.ct-series-a {
      path.ct-area {
        fill: ${(props) => props.theme.palette.primary.main};
      }
      line,
      path.ct-line {
        stroke: ${(props) => props.theme.palette.primary.main};
      }
    }
  }
`
