import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import api from "../../../services/api"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { FormCombobox } from "../../Form"

/** This component can only be used inside a `Form` */
export default function ComboboxCities({ state, ...props }) {
  const [loading, setLoading] = useState(false)
  const [cities, setCities] = useState([])

  useEffect(() => {
    const fetchCities = async () => {
      setLoading(true)
      try {
        const response = await api.get(apiEndPoints.miscellaneous.cities(state))

        const cities = response.data.map((city) => ({
          value: city.descr,
          label: city.descr,
        }))

        setCities(cities)
      } catch (error) {
        setCities([])
      }

      setLoading(false)
    }

    if (state) fetchCities()

    return () => {
      setCities([])
      setLoading(false)
    }
  }, [state])

  return (
    <FormCombobox
      noOptionsText="Escolha uma cidade"
      loadingText="Buscando as cidades..."
      options={cities}
      loading={loading}
      {...props}
    />
  )
}

ComboboxCities.propTypes = {
  state: PropTypes.string,
}

ComboboxCities.defaultProps = {
  state: "",
}
