import React from "react"
import { Typography } from "@material-ui/core"

import { confirmation } from "@/components/Confirmation"
import { PRODUCT_REFERENCES } from "@/consts/types"

import { currencyFormatter } from "@/services/formatter"

//  ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
//  ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
//  ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

export const calculateQuantity = ({
  product,
  amountPallets = 1,
  amountBoxes = 0,
}) => {
  if (!product) return 0

  const boxesPerPallet = product.ctp_caixa_pallet
  const squareMetersPerBox = product.ctp_m2_caixa
  const addPallet = amountBoxes > 0 && amountBoxes % boxesPerPallet === 0

  const quantityInPallets = amountPallets * boxesPerPallet * squareMetersPerBox
  const quantityInBoxes = amountBoxes * squareMetersPerBox

  const quantity = parseFloat(quantityInPallets + quantityInBoxes).toFixed(2)
  const pallets = addPallet ? amountPallets + 1 : amountPallets
  const boxes = addPallet ? 0 : amountBoxes

  return { quantity, pallets, boxes }
}

export function getProductTableValue(product) {
  if (!product) return 0

  const valuesPerReference = {
    A: product.itped_prtabelaA,
    S: product.itped_prtabelaA,
    B: product.itped_prtabelaB,
    C: product.itped_prtabelaC,
    F: product.itped_prtabelaAFrac,
  }

  const value = valuesPerReference[product.prd_referencia]
  return Number(value || valuesPerReference.F)
}

// =========================================
// ==== VALIDAÇÕES RELATIVAS AO CLIENTE ====
// =========================================

export async function validateSave(form, draft, product) {
  const productTableValue = getProductTableValue(product)

  // Atualizar dados do draft
  draft.itped_prunit = Number(draft.itped_prunit)
  draft.itped_qtd = Number(draft.itped_qtd)
  draft.itped_prtabela = productTableValue

  const unitPrice = draft.itped_prunit
  const amount = draft.itped_qtd * 100
  const squareMetersPerBox = product.ctp_m2_caixa * 100

  // Verificação valor minimo na tabela de preço
  if (unitPrice < productTableValue) {
    const confirmed = await confirmation.open(
      "Confirmar valor",
      <>
        <Typography variant="body1" color="textPrimary">
          Valor informado {currencyFormatter.format(draft.itped_prunit)} menor
          que a tabela {currencyFormatter.format(productTableValue)}
        </Typography>

        <Typography variant="overline" color="textSecondary">
          Sujeito a aprovação de crédito!
        </Typography>
      </>
    )

    if (!confirmed) return false
  }

  // ignorar validações de paletização na referênicia 'S'
  if (product.prd_referencia === PRODUCT_REFERENCES.S) {
    return true
  }

  const amountBoxes = Number(parseFloat(amount / squareMetersPerBox).toFixed(2))

  if (!Number.isInteger(amountBoxes)) {
    form.setFieldError("itped_qtd", "Quantidade insuficiente por caixa")
    return false
  }

  const allowUncompletedPallet = Number(product.prd_pallet_aberto) === 1
  const amountPallets = amountBoxes / product.ctp_caixa_pallet

  const hasUncompletedPallets =
    Math.floor(amountPallets) - Math.ceil(amountPallets) !== 0

  if (hasUncompletedPallets) {
    if (!allowUncompletedPallet) {
      form.setFieldError("itped_qtd", "Produto não permite palete aberto")
      return false
    }

    if (
      unitPrice < product.min_ctp_valora_frac ||
      unitPrice > product.max_ctp_valora_frac
    ) {
      form.setFieldError(
        "itped_prunit",
        "Valor fora do padrão para quantidade fracionada"
      )
      return false
    }

    // Sobrepor valor da tabela
    draft.itped_prtabela = product.itped_prtabela_frac
  }

  return true
}
