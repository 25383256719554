import React from "react"
import PropTypes from "prop-types"
import MuiFormHelperText from "@material-ui/core/FormHelperText"

import { useCurrentForm } from ".."
import { extractLens } from "@/services/utils"

export default function FormHelperText({ name }) {
  const { errors } = useCurrentForm()
  const error = extractLens(name, errors)

  return (
    <MuiFormHelperText
      role="FORM_HELPER_TEXT"
      aria-label={name}
      error={Boolean(error)}
    >
      {error}
    </MuiFormHelperText>
  )
}

FormHelperText.propTypes = {
  name: PropTypes.string.isRequired,
}
