import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import api from "../../../services/api"
import formatter from "../../../services/formatter"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { DELIVERY_TYPES } from "../../../consts/types"

import { FormCombobox, FormTextField, useCurrentForm } from "../../Form"
import { Box, Typography } from "@material-ui/core"
import { NewCarrierContainer } from "./styles"
import { toMask } from "../../../services/masks"
import { telefoneCelular } from "../../../consts/regexp"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const CARRIERS_DELAY_TIME = 1000 * 1 // 1 second

/** This component can only be used inside a `Form` */
export default function ComboboxCarriers({
  deliveryTypeName,
  showNewCarrierContainer,
  search: receivedSearch,
  disabled,
  ...props
}) {
  const form = useCurrentForm()

  const deliveryType =
    form.getFieldValue(deliveryTypeName) || DELIVERY_TYPES.determined

  const disableCarrierCombobox =
    disabled || deliveryType !== DELIVERY_TYPES.determined

  const [search, setSearch] = useState("")
  const [carriers, setCarriers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let timeOut = undefined
    const value = search || receivedSearch

    if (value) {
      setLoading(true)

      timeOut = setTimeout(
        () => {
          api
            .get(apiEndPoints.miscellaneous.carriers, {
              params: { search: value },
            })
            .then((response) => setCarriers(response.data))
            .catch(() => setCarriers([]))
            .finally(() => setLoading(false))
        },
        receivedSearch ? 0 : CARRIERS_DELAY_TIME
      )
    }

    return () => clearTimeout(timeOut)
  }, [search, receivedSearch])

  const handleChange = async (e) => {
    const searchValue = e.target.value
    setSearch(searchValue)
  }

  const carriersOptions = carriers.map((c) => ({
    value: c.id,
    label: [c.descr, formatter(c.id).toCNPJorCPF()].join(" - "),
  }))

  if ([DELIVERY_TYPES.determined].includes(deliveryType)) {
    return (
      <>
        <FormCombobox
          disabled={disableCarrierCombobox}
          options={carriersOptions}
          loading={loading}
          renderInput={(params) => (
            <FormTextField
              value={search}
              onChange={handleChange}
              fullWidth
              variant={props.variant}
              label={props.label}
              name={props.name}
              {...params}
            />
          )}
          noOptionsText="Digite para pesquisar a Transportadora"
          {...props}
        />

        {!disabled && showNewCarrierContainer && (
          <NewCarrierContainer>
            <Typography gutterBottom variant="caption">
              As informações da Transportadora serão incluidas na observação do
              pedido
            </Typography>

            <Box marginTop="0.5rem" display="flex" style={{ gap: "1rem" }}>
              <FormTextField
                fullWidth
                variant="outlined"
                size="small"
                name="carrier.name"
                label="Nome"
                placeholder="Nome da transportadora"
              />
              <FormTextField
                fullWidth
                style={{ maxWidth: 200 }}
                variant="outlined"
                size="small"
                name="carrier.phone"
                label="Telefone"
                textMaskProps={toMask(telefoneCelular.array)}
              />
              <FormTextField
                fullWidth
                style={{ maxWidth: 200 }}
                variant="outlined"
                size="small"
                name="carrier.city"
                label="Cidade"
              />
            </Box>
          </NewCarrierContainer>
        )}
      </>
    )
  }

  return null
}

ComboboxCarriers.propTypes = {
  showNewCarrierContainer: PropTypes.bool,
  deliveryTypeName: PropTypes.string,
  search: PropTypes.string,
}

ComboboxCarriers.defaultProps = {
  showNewCarrierContainer: false,
}
