import { toast } from "react-toastify"
import * as Yup from "yup"

const translation = {
  mixed: {
    default: "valor inválido",
    required: "campo obrigatório",
    oneOf: ({ path, values }) =>
      `${path} deve ser um dos seguintes valores: ${values}`,
    notOneOf: ({ path, values }) =>
      ` ${path} não pode ser um dos seguintes valores: ${values}`,
  },
  string: {
    length: ({ path, length }) =>
      `${path} deve ter exatamente ${length} caracteres`,
    min: ({ path, min }) => `${path} deve ter pelo menos ${min} caracteres`,
    max: ({ path, max }) => `${path} deve ter no máximo ${max} caracteres`,
    email: "deve ser um e-mail válido",
    url: ({ path }) => `${path} deve ter um formato de URL válida`,
    trim: ({ path }) => `${path} não deve conter espaços no início ou no fim.`,
    lowercase: ({ path }) => `${path} deve estar em maiúsculo`,
    uppercase: ({ path }) => `${path} deve estar em minúsculo`,
  },
  number: {
    min: ({ min }) => `Deve ser no mínimo ${min}`,
    max: ({ max }) => `Deve ser no máximo ${max}`,
    lessThan: ({ path, less }) => `${path} deve ser menor que ${less}`,
    moreThan: ({ path, more }) => `${path} deve ser maior que ${more}`,
    notEqual: ({ path, notEqual }) =>
      `${path} não pode ser igual à ${notEqual}`,
    positive: ({ path }) => `${path} deve ser um número positivo`,
    negative: ({ path }) => ` ${path} deve ser um número negativo`,
    integer: ({ path }) => `${path} deve ser um número inteiro`,
  },
  date: {
    min: ({ path, min }) => `${path} deve ser maior que a data ${min}`,
    max: ({ path, max }) => `${path} deve ser menor que a data ${max}`,
  },
  array: {
    min: ({ path, min }) => `${path} deve ter no mínimo ${min} itens`,
    max: ({ path, max }) => `${path} deve ter no máximo ${max} itens`,
  },
}

Yup.setLocale(translation)

const handleValidationError = (form) => (error) => {
  if (error instanceof Yup.ValidationError) {
    toast.error("Existem campos obrigatórios não preenchidos")

    const formErrors = {}
    error.inner.forEach((error) => {
      const path = error.path.replace(/\[(\d+)\]/g, ".$1").replace(/\]\./g, ".")

      formErrors[path] = error.message
    })

    form.setErrors(formErrors)
  } else throw error
}

const validateWithSchema = (schema, values, onValidate, onInvalidate) => {
  schema
    .validate(values, { abortEarly: false })
    .then(onValidate)
    .catch(onInvalidate)
}

export { Yup, handleValidationError, validateWithSchema }
export default Yup
