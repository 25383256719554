import React from "react"
import { Box, Typography, Divider } from "@material-ui/core"

export function Title({ children }) {
  return (
    <Box marginY="1rem">
      <Typography
        style={{
          fontWeight: 500,
          textTransform: "uppercase",
          fontSize: "0.875rem",
        }}
        variant="subtitle1"
        color="textSecondary"
      >
        {children}
      </Typography>
      <Divider />
    </Box>
  )
}
