import ptBR from "date-fns/locale/pt-BR"
import enUs from "date-fns/locale/en-US"
import es from "date-fns/locale/es"

export const LOCALES = {
  ENGLISH: "en-US",
  SPANISH: "es",
  PORTUGUESE_BR: "pt-BR",
}

export const LOCALE_IDS = {
  1: LOCALES.ENGLISH,
  2: LOCALES.SPANISH,
  3: LOCALES.PORTUGUESE_BR,
}

export const LANGUAGE_IDS = {
  [LOCALES.ENGLISH]: 1,
  [LOCALES.SPANISH]: 2,
  [LOCALES.PORTUGUESE_BR]: 3,
}

export const LOCALE_DATE_FNS = {
  [LOCALES.ENGLISH]: enUs,
  [LOCALES.SPANISH]: es,
  [LOCALES.PORTUGUESE_BR]: ptBR,
}

export const LOCALE_ENUM = {
  english: LOCALES.ENGLISH,
  spanish: LOCALES.SPANISH,
  portuguese: LOCALES.PORTUGUESE_BR,
}

export const DEFAULT_LOCALE = {
  id: LOCALES.PORTUGUESE_BR,
  get idLang() {
    return LANGUAGE_IDS[this.id]
  },
}
