export const events = {
  confirmation: {
    open: "@PortalVendas/CONFIRMATION/open",
    close: "@PortalVendas/CONFIRMATION/close",
    confirm: "@PortalVendas/CONFIRMATION/confirm",
    cancel: "@PortalVendas/CONFIRMATION/cancel",
  },

  signout: "@PortalVendas/SIGNOUT/signout",
}

export default events
