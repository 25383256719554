import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { TabProps } from "@material-ui/core/Tab"

import { useCurrentForm } from ".."
import Badge from "./styles"
import { StyledTab } from "../../Tabs/styles"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const INPUT_NODE_NAMES = ["INPUT", "TEXTAREA", "FORM_HELPER_TEXT"]

/**
 * Component based on MuiTab Component, used for show form errors
 *
 * @param {TabProps} props
 * @returns {JSX.Element}
 */
export default function FormTab({ panelId, ...props }) {
  const { errors, setIsSubmitted } = useCurrentForm()
  const [errorCount, setErrorCount] = useState(0)

  useEffect(() => {
    const id = `${panelId}-tabpanel-${props.value}`
    const panel = document.getElementById(id)
    const tabInputs = panel ? findInputsRecursive(panel) : []

    const newErrorCount = Object.entries(errors).filter(
      ([field, value]) =>
        tabInputs.includes(field) &&
        value !== undefined &&
        value !== null &&
        value !== ""
    )

    setErrorCount(newErrorCount.length)

    const timeout = setTimeout(() => {
      setIsSubmitted(false)
    }, 1)

    return () => {
      clearTimeout(timeout)
    }
  }, [errors, setIsSubmitted])

  return (
    <StyledTab
      {...props}
      label={
        <>
          <Badge
            max={9}
            badgeContent={errorCount}
            showZero={false}
            color="error"
          >
            <span className="tab-label">{props.label}</span>
          </Badge>
        </>
      }
    />
  )
}

FormTab.propTypes = {
  panelId: PropTypes.string,
}

FormTab.defaultProps = {
  panelId: "simple",
}

// -----------------------------------------------

function findInputsRecursive(element) {
  if (INPUT_NODE_NAMES.includes(element.nodeName)) {
    return element.name
  }

  if (INPUT_NODE_NAMES.includes(element.role)) {
    return element.ariaLabel
  }

  return [...element.children].flatMap(findInputsRecursive)
}
