import React, { useLayoutEffect } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

import { Typography, useTheme } from "@material-ui/core"
import { ScaleLoader, DotLoader } from "react-spinners"
import { Container } from "./styles"

export default function SplashScreen({
  show = false,
  inRefresh,
  cover,
  loadingText,
}) {
  const theme = useTheme()

  useLayoutEffect(() => {
    const html = document.getElementsByTagName("html")[0]
    html.style.overflow = show ? "hidden" : "initial"
  }, [show])

  if (show)
    return (
      <Container
        className={clsx({
          cover,
        })}
      >
        <div className="gap-4">
          <DotLoader
            color={theme.palette.primary.main}
            height={50}
            width={8}
            margin={3}
          />
          <Typography component="div" variant="body1">
            {loadingText}
          </Typography>
        </div>
      </Container>
    )
  else if (inRefresh) {
    return (
      <Container className="refresh">
        <ScaleLoader color={theme.palette.common.white} size={20} />
      </Container>
    )
  } else return null
}

SplashScreen.propTypes = {
  show: PropTypes.bool,
  cover: PropTypes.bool,
  inRefresh: PropTypes.bool,
  error: PropTypes.bool,
  loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

SplashScreen.defaulProps = {
  cover: false,
}
