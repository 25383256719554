import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { ToastContainer, Slide } from "react-toastify"
import { QueryClientProvider } from "react-query"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"

import DateFnsUtils from "@date-io/date-fns"
import ptBR from "date-fns/locale/pt-BR"

import { queryClient } from "./services/queryClient"
import { I18nProvider } from "./_i18n"
import { GlobalStyle } from "./styles/global"

import GlobalProvider from "./contexts/Global"
import Routes from "./routes/routes"

import "react-toastify/dist/ReactToastify.css"
import "@/lib/date-fns"

export default function App() {
  return (
    <I18nProvider>
      <QueryClientProvider client={queryClient}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <GlobalProvider>
            <GlobalStyle />
            <Router>
              <Routes />
            </Router>
          </GlobalProvider>
        </MuiPickersUtilsProvider>
      </QueryClientProvider>
      <ToastContainer
        autoClose={5000}
        transition={Slide}
        position="top-right"
        theme="colored"
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
      />
    </I18nProvider>
  )
}
