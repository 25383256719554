import React from "react"
import PropTypes from "prop-types"

import CardTable from "../../../../components/CardTable"
import { translate } from "../../../../_i18n"

export default function CustomersDelayedTitlesTable({ data }) {
  return (
    <CardTable
      color="error"
      data={data}
      columns={[
        { field: "rk" },
        {
          title: translate("document"),
          field: "cod_cliente",
          type: "company",
        },
        {
          title: translate("customer.name"),
          field: "emp_razao_social",
        },
        {
          title: translate("value"),
          field: "cr_valor",
          type: "decimal",
          align: "right",
        },
        { title: translate("due.date"), field: "cr_data_vencto" },
      ]}
    />
  )
}

CustomersDelayedTitlesTable.propTypes = {
  data: PropTypes.array,
}
