import React from "react"
import { Grid, IconButton } from "@material-ui/core"
import { Add, Remove } from "@material-ui/icons"

import { SectionContainer, ButtonsContainer } from "./styles"
import {
  FormTextField,
  FormNumberField,
  FormHelperText,
} from "../../../../../components/Form"
import { toMask } from "../../../../../services/masks"
import { cpf } from "../../../../../consts/regexp"
import { useControlledArray } from "../../../../../hooks"

export function CorporateStructure() {
  const { data, add, remove } = useControlledArray([{ id: "0" }])

  function handleAdd(index) {
    const newRegister = {
      id: String(index + 1),
    }

    add(newRegister)
  }

  return (
    <SectionContainer>
      {data.map((register, index) => {
        const disabledAddButton = index !== data.length - 1
        const disabledRemoveButton = data.length === 1 || disabledAddButton

        return (
          <Grid key={register.id} container spacing={2} alignItems="flex-start">
            <Grid item xs={12} sm={3}>
              <FormTextField
                fullWidth
                label="CPF"
                size="small"
                variant="outlined"
                name={`corporate_structure.${index}.soc_cpf`}
                textMaskProps={toMask(cpf.array)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                fullWidth
                label="Nome"
                size="small"
                variant="outlined"
                name={`corporate_structure.${index}.soc_nome`}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormNumberField
                fullWidth
                label="Participação"
                placeholder="%"
                size="small"
                variant="outlined"
                name={`corporate_structure.${index}.soc_quota`}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <ButtonsContainer>
                <IconButton
                  disabled={disabledAddButton}
                  color="primary"
                  title="Adicionar"
                  size="small"
                  onClick={() => handleAdd(index)}
                >
                  <Add />
                </IconButton>

                <IconButton
                  disabled={disabledRemoveButton}
                  className="remove"
                  title="Remover"
                  size="small"
                  onClick={() => remove(register.id)}
                >
                  <Remove />
                </IconButton>
              </ButtonsContainer>
            </Grid>
          </Grid>
        )
      })}

      <FormHelperText name="corporate_structure" />
    </SectionContainer>
  )
}
