import React from "react"
import { Paper, Typography, useTheme } from "@material-ui/core"

import Table from "../../Table"
import { DuplicatesContainer } from "./styles"

export function Duplicates({ data }) {
  const theme = useTheme()

  function getCellStyle({ row: duplicate }) {
    if (duplicate?.diferenca < 0 && duplicate?.cr_data_baixa === "") {
      return { backgroundColor: theme.palette.error.light }
    }

    if (duplicate?.diferenca < 0 && duplicate?.cr_data_baixa !== "") {
      return { backgroundColor: theme.palette.info.light }
    }
  }

  return (
    <DuplicatesContainer>
      <Typography variant="subtitle1" color="textPrimary" gutterBottom>
        Duplicatas
      </Typography>

      <Paper variant="outlined">
        <Table
          size="small"
          data={data}
          columns={[
            { title: "Ordem", field: "cr_ordem" },
            { title: "Vencimento", field: "cr_data_vencto" },
            {
              title: "Valor",
              field: "cr_valor",
              align: "right",
              type: "currency",
            },
          ]}
          cellStyle={getCellStyle}
        />
      </Paper>
    </DuplicatesContainer>
  )
}
