import styled from "styled-components"
import Paper from "../Paper"

export const Container = styled(Paper)`
  padding: 1rem;
  display: flex;
`

export const Color = styled.span`
  cursor: pointer;
  height: 30px;
  width: 30px;

  border: 1px solid #fff;

  display: block;
  background-color: ${(props) => props.color};
  opacity: 0.5;

  &.active {
    opacity: 1;
  }

  &:hover {
    transition: 200ms;
    opacity: 0.8;
  }
`
