import React from "react"
import { Link } from "react-router-dom"
import { Typography } from "@material-ui/core"

import { paths } from "../../consts/paths"

import ContentPage from "../../components/ContentPage"
import { ChevronRight } from "@material-ui/icons"

import { Container, Links } from "./styles"

function ChevronLink(props) {
  return (
    <Typography component="div">
      <ChevronRight />
      <Link {...props}>{props.children}</Link>
    </Typography>
  )
}

export default function NotFoundPage() {
  return (
    <ContentPage showReturn title="Voltar">
      <Container>
        <div className="img">{/* <img src="/assets/404.png" alt="" /> */}</div>

        <div>
          <Typography variant="h5">
            OPA! A PÁGINA QUE VOCÊ PROCURA NÃO PODE SER ENCONTRADA
          </Typography>

          <Typography color="textSecondary" variant="subtitle1" gutterBottom>
            Lamentamos, mas a página que procurava não existe. Aqui estão alguns
            links úteis
          </Typography>

          <Links>
            <ChevronLink to={paths.root}>Home</ChevronLink>
            <ChevronLink to={paths.orders.root.replace(":orderType", "R")}>
              Pedidos
            </ChevronLink>
            <ChevronLink to={paths.registers.root}>Cadastros</ChevronLink>
            <ChevronLink to={paths.bulletins.root}>Comunicados</ChevronLink>
          </Links>
        </div>
      </Container>
    </ContentPage>
  )
}
