import React from "react"
import PropTypes from "prop-types"

import parse from "autosuggest-highlight/parse"
import diacritic from "diacritic"

import Autocomplete from "@material-ui/lab/Autocomplete"
import Popper from "@material-ui/core/Popper"
import useTheme from "@material-ui/styles/useTheme"
import { FormTextField } from "../../Form"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const LIMIT_SIZE_TO_ACTIVE_HIGHLIGHT = 1000

//  ╔╦╗╔═╗╔╦╗╦ ╦╔═╗╔╦╗╔═╗
//  ║║║║╣  ║ ╠═╣║ ║ ║║╚═╗
//  ╩ ╩╚═╝ ╩ ╩ ╩╚═╝═╩╝╚═╝

const customMatch = (text, query) => {
  if (!("string" === typeof query && "string" === typeof text)) {
    return []
  }

  let t = text.toLowerCase()
  let q = query.trim().toLowerCase()
  const ql = q.length

  if (!(0 < ql && 0 < t.length)) {
    return []
  }

  t = diacritic.clean(t)
  q = diacritic.clean(q)

  let r = []

  let i = t.indexOf(q)
  while (i !== -1) {
    r.push([i, i + ql])
    i = t.indexOf(q, i + ql)
  }

  return r
}

const HighlightOption = (props) => {
  const theme = useTheme()

  const { option, inputValue, size } = props
  const effectiveLabel = option.label || String(option.value)

  if (option.optionContent) {
    return <React.Fragment>{option.optionContent}</React.Fragment>
  }

  if (size > LIMIT_SIZE_TO_ACTIVE_HIGHLIGHT) {
    return <React.Fragment>{effectiveLabel}</React.Fragment>
  }

  const matches = customMatch(effectiveLabel, inputValue)
  const parts = parse(effectiveLabel, matches)

  return (
    <div>
      {parts.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: part.highlight ? 700 : 400,
            color: part.highlight
              ? theme.palette.primary.main
              : theme.palette.text.primary,
          }}
        >
          {part.text}
        </span>
      ))}
    </div>
  )
}

export default function BaseCombobox({
  error,
  onChange,
  required,
  helperText,
  value: formValue,
  ...props
}) {
  const theme = useTheme()
  const popperZIndex = theme.zIndex.modal + 10000

  const handleChange = (_, option) => {
    onChange(
      {
        target: {
          name: props.name,
          value: option?.value,
        },
      },
      option
    )
  }

  const value =
    props.options.find((option) => option.value === formValue) || null

  return (
    <Autocomplete
      PopperComponent={(props) => (
        <Popper
          {...props}
          style={{
            ...props.style,
            zIndex: popperZIndex,
          }}
        />
      )}
      autoSelect
      clearOnEscape
      selectOnFocus
      clearOnBlur
      fullWidth
      getOptionDisabled={(option) => option.disabled}
      getOptionLabel={(option) => option.label ?? option.value.toString()}
      getOptionSelected={(option, value) => option.value === value.value}
      onChange={handleChange}
      value={value}
      noOptionsText="Nenhum valor foi encontrado"
      loadingText="Buscando opções..."
      clearText="Limpar"
      renderInput={(params) => (
        <FormTextField
          fullWidth
          required={required}
          variant={props.variant}
          label={props.label}
          placeholder={props.placeholder}
          name={props.name}
          error={error}
          helperText={helperText}
          {...params}
        />
      )}
      renderOption={(option, { inputValue }) => (
        <HighlightOption
          option={option}
          inputValue={inputValue}
          size={props.options.length}
        />
      )}
      {...props}
    />
  )
}

BaseCombobox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      optionContent: PropTypes.any,
    })
  ),
}

BaseCombobox.defaultProps = {
  options: [],
}
