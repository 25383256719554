import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  min-height: ${(props) => props.theme.paperContentMinHeight};

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  gap: 8rem;

  img {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    gap: 0;
    align-items: flex-start;

    .MuiTypography-h5 {
      text-align: center;
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }

    .img {
      display: none;
    }
  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .MuiSvgIcon-root {
    font-size: 1.1rem;
    color: ${(props) => props.theme.palette.action.disabled};
  }

  a {
    color: ${(props) => props.theme.palette.primary.main};
  }
`
