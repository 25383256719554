import { LANGUAGE_IDS, LOCALES } from "./locales"

/** react-flagkit */
export const FLAGS = [
  { country: "US", id: LANGUAGE_IDS[LOCALES.ENGLISH], language: "english" },
  { country: "ES", id: LANGUAGE_IDS[LOCALES.SPANISH], language: "spanish" },
  {
    country: "BR",
    id: LANGUAGE_IDS[LOCALES.PORTUGUESE_BR],
    language: "portuguese",
  },
]
