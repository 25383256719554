import { useState } from "react"
import { extractLens } from "../services/utils"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const ORDINATIONS = {
  ASC: "asc",
  DESC: "desc",
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const comp = (a, b) => {
  if (a < b) return -1
  else if (a > b) return 1
  else return 0
}

const descendingComparator = (a, b, orderBy, groupBy) => {
  const _a = extractLens(orderBy, a)
  const _b = extractLens(orderBy, b)

  if (groupBy) {
    const groupA = extractLens(groupBy, a)
    const groupB = extractLens(groupBy, b)

    if (groupA < groupB) return -1
    else if (groupA > groupB) return 1
    else return comp(_a, _b)
  } else return comp(_a, _b)
}

const getComparator = (order, orderBy, groupBy) => {
  return order === ORDINATIONS.ASC
    ? (a, b) => descendingComparator(a, b, orderBy, groupBy)
    : (a, b) => -descendingComparator(a, b, orderBy, groupBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order
    return Number(a[1]) - Number(b[1])
  })

  return stabilizedThis.map((el) => el[0])
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

/**
 * Custom hook used to sort data.
 *
 * @param {{
 *      initialOrder: 'asc' | 'desc'
 *      initialField: string
 * }} props
 * @returns
 */
export default function useSortData({
  initialOrder = ORDINATIONS.DESC,
  initialField = "",
} = {}) {
  const [currentSort, setCurrentSort] = useState({
    field: initialField,
    order: initialOrder,
    alternativeField: undefined,
  })

  const onSortChange = (newSortField, alternativeField) => {
    const isAsc =
      currentSort.field === newSortField &&
      currentSort.order === ORDINATIONS.ASC

    setCurrentSort({
      field: newSortField,
      order: isAsc ? ORDINATIONS.DESC : ORDINATIONS.ASC,
      alternativeField,
    })
  }

  const sortData = (records, groupBy) => {
    return stableSort(
      records,
      getComparator(currentSort.order, currentSort.field, groupBy)
    )
  }

  const getSortString = () =>
    `${
      currentSort.alternativeField || currentSort.field
    } ${currentSort.order.toLocaleUpperCase()}`

  return { currentSort, onSortChange, getSortString, sortData }
}
