import React from "react"
import { Grid } from "@material-ui/core"

import { FormNumberField, FormRadioGroup } from "@/components/Form"
import { TabPanel } from "@/components/Tabs"
import { Title } from "../components/Title"
import { CommercialRefs } from "../components/CommercialRefs"
import { BanksRefs } from "../components/BanksRefs"
import { useKeepMounted } from "./useKeepMounted"

export function TabSheet({ currentTab }) {
  const keepMounted = useKeepMounted()

  return (
    <TabPanel value={currentTab} index={3} keepMounted={keepMounted}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormRadioGroup
            name="sheet.predio_proprio"
            label="Prédio próprio"
            direction="row"
            options={[
              { value: "1", label: "Sim" },
              { value: "2", label: "Não" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormNumberField
            fullWidth
            name="sheet.metragem"
            label="Metragem"
            variant="filled"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            name="sheet.tempo_ramo"
            label="Tempo de Experiência no Ramo"
            direction="row"
            options={[
              { value: "1", label: "Até 2 Anos" },
              { value: "2", label: "5 Anos" },
              { value: "3", label: "10 Anos" },
              { value: "4", label: "Mais de 10 Anos" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            name="sheet.localizacao"
            label="Localização"
            direction="row"
            options={[
              { value: "1", label: "Ruim" },
              { value: "2", label: "Regular" },
              { value: "3", label: "Excelente" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            name="sheet.outras_linhas"
            label="Outras Linhas"
            direction="row"
            options={[
              { value: "1", label: "Elétrica" },
              { value: "2", label: "Hidráulica" },
              { value: "3", label: "Material Básico" },
              { value: "4", label: "Metais" },
              { value: "5", label: "Ferramentas" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            name="sheet.disp_apres"
            label="Disposição/Apresentação"
            direction="row"
            options={[
              { value: "1", label: "Ruim" },
              { value: "2", label: "Regular" },
              { value: "3", label: "Excelente" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormNumberField
            fullWidth
            name="sheet.limit_credito"
            label="Limite de Crédito"
            variant="filled"
            size="small"
          />
        </Grid>

        <Grid item xs={12}>
          <Title>Referências comerciais</Title>
          <CommercialRefs />
        </Grid>

        <Grid item xs={12}>
          <Title>Referências bancárias</Title>
          <BanksRefs />
        </Grid>
      </Grid>
    </TabPanel>
  )
}
