import React from "react"
import { useLocation } from "react-router-dom"

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function useSearchParams() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default useSearchParams
