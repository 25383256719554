import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
    gap: 1rem;

    > div {
      justify-content: space-between;
    }
  }
`
