import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { darken } from "polished"
import { Form } from "@/components/Form"

export const StyledForm = styled(Form)`
  padding: 1rem 0;
  width: 100%;
  min-height: 320px;
  gap: 3rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    gap: 1rem;
  }
`

export const StyledGrid = styled(Grid)`
  width: 100%;

  &.MuiGrid-container {
    margin-left: 0;
    margin-right: 0;
  }

  &.Paper {
    background: ${(props) => props.theme.palette.background.default};
    border: 1px solid
      ${(props) => darken(0.1, props.theme.palette.background.default)};
    border-radius: 5px;
    padding: 1rem;
  }
`
