import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import apiEndPoints from "../../consts/apiEndPoints"
import api from "../../services/api"
import formatter from "../../services/formatter"
import { downloadFile, previewFile } from "../../services/file"
import { translate } from "../../_i18n"
import { handleAjaxError } from "../../services/errorHandlers"

import InfoIcon from "@material-ui/icons/InfoOutlined"
import PictureAsPdf from "@material-ui/icons/PictureAsPdf"
import Portrait from "@material-ui/icons/Portrait"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import CircularProgress from "@material-ui/core/CircularProgress"

import BaseDialog from "../BaseDialog"
import { Container } from "./styles"
export default function Bulletin({
  id,
  subject,
  content,
  sender,
  date,
  recipient,
  onRead,
  ...props
}) {
  const [attachments, setAttachments] = useState([])
  const [loading, setLoading] = useState(false)

  const title = translate("bulletin.subject", { subject })

  const subtitle = (
    <div className="d-flex align-items-center gap-2">
      <InfoIcon fontSize="small" />
      {translate("bulletin.sendedBy", {
        date: formatter(date).toSimpleDate(),
        sender,
      })}
    </div>
  )

  useEffect(() => {
    async function readBulletin() {
      try {
        setLoading(true)

        if (id) {
          const isUnread = recipient?.vcd_data_leitura === null

          const response = await api.get(apiEndPoints.bulletins.attachments(id))

          const attachments = response.data.bulletin_attachments
          setAttachments(attachments)

          if (isUnread) {
            await api.post(apiEndPoints.bulletins.readBulletin(id))
            onRead()
          }
        }
      } catch (error) {
        handleAjaxError(error)
      } finally {
        setLoading(false)
      }
    }

    readBulletin()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleDownloadFile = async (fileName, attachment) => {
    try {
      const response = await api.get(
        apiEndPoints.bulletins.attachments(id, attachment.id)
      )
      const file = response.data.attach_base64

      if (attachment.vca_extensao === "PDF") {
        previewFile(fileName, file)
      } else {
        downloadFile(attachment.vca_nome, file, attachment.vca_extensao)
      }
    } catch (error) {
      handleAjaxError(error)
    }
  }

  return (
    <BaseDialog
      {...props}
      title={title}
      subtitle={subtitle}
      maxWidth="md"
      agreeLabel={translate("close")}
    >
      <Container className={clsx({ loading })}>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: content }} />

          <Typography
            color="textSecondary"
            variant="caption"
            component="span"
            className="d-block mt-4"
          >
            {translate("bulletin.attachments.label")}
          </Typography>

          <div className="attachments d-flex flex-wrap gap-2">
            {attachments.map((attachment, index) => {
              const fileName = [attachment.vca_nome, attachment.vca_extensao]
                .join(".")
                .toLowerCase()

              return (
                <Chip
                  key={index}
                  label={fileName}
                  onClick={() => handleDownloadFile(fileName, attachment)}
                  icon={
                    attachment.vca_extensao === "PDF" ? (
                      <PictureAsPdf />
                    ) : (
                      <Portrait />
                    )
                  }
                />
              )
            })}
          </div>
        </div>
        {loading && <CircularProgress color="primary" size={40} />}
      </Container>
    </BaseDialog>
  )
}

Bulletin.propTypes = {
  id: PropTypes.number.isRequired,
  subject: PropTypes.string,
  content: PropTypes.string,
  sender: PropTypes.string,
  date: PropTypes.string,
  recipient: PropTypes.object,
  onRead: PropTypes.func,
}
