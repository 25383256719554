import React from "react"
import { apiEndPoints } from "../../../consts/apiEndPoints"
import { FormCombobox } from "../../Form"
import { useFetch } from "../../../hooks"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const GAUGES_STALE_TIME = 1000 * 60 * 10 // 10 minutes

/** This component can only be used inside a `Form` */
export default function ComboboxGauges(props) {
  const {
    data: gauges,
    isFetching,
    isError,
  } = useFetch({
    clientName: "gauges",
    url: apiEndPoints.miscellaneous.gauges,
    staleTime: GAUGES_STALE_TIME,
  })

  const gaugesOptions = (gauges || []).map((gauge) => ({
    ...gauge,
    value: gauge.value,
    label: gauge.value.toString(),
  }))

  return (
    <FormCombobox
      options={gaugesOptions}
      loading={isFetching}
      error={isError}
      {...props}
    />
  )
}
