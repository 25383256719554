import React from "react"
import PropTypes from "prop-types"
import Chart from "../../../../components/Chart"

export default function BillingRankingChart({ data }) {
  const labels = data.map((billing) => `${billing.mes}/${billing.ano}`)
  const series = [data.map((billing) => billing.nf_total_valor) || [0]]

  return (
    <Chart
      color="primary"
      type="Line"
      data={{
        labels,
        series,
      }}
    />
  )
}

BillingRankingChart.propTypes = {
  data: PropTypes.array,
}

BillingRankingChart.defaultProps = {
  data: [],
}
