import { createTheme } from "@material-ui/core/styles"

/** @type {(color:string) => import("@material-ui/core").Theme} */
export const withDefaults = (color) => ({
  drawerWidth: 265,
  drawerWidthShift: 72,
  paperContentMinHeight: "calc(100vh - 249px)",

  toPx: (pixels) => `${pixels}px`,
  palette: {
    primary: {
      main: color,
    },
    secondary: {
      main: "#373435",
    },
    background: {
      default: "#f1f1f1",
    },
  },
  typography: {
    fontFamily: ["-apple-system", "Roboto", "Roboto Slab", "sans-serif"].join(
      ","
    ),
    body1: {
      fontSize: "min(1rem, 3vw)",
    },
    body2: {
      fontSize: "min(0.875rem, 3vw)",
    },
    caption: {
      fontSize: 10,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    h5: {
      fontSize: 24,
      fontWeight: "bold",
    },
    overline: {
      fontWeight: "bold",
    },
    button: {
      outline: 0,
      fontSize: "0.875rem",
      // textTransform: "none",
      // fontWeight: "bold",
    },
  },
})

export const esmeralda = createTheme(withDefaults("#087e65"))
export const safira = createTheme(withDefaults("#0051a0"))
export const cobalto = createTheme(withDefaults("#004d90d4"))
export const rubi = createTheme(withDefaults("#c4161c"))
export const topazio = createTheme(withDefaults("#f96200"))
export const onix = createTheme(withDefaults("#1b1b1b"))

export const themes = {
  esmeralda,
  safira,
  cobalto,
  rubi,
  topazio,
  onix,
}

export default themes
