import React from "react"
import NumberFormat from "react-number-format"
import { TextField } from "@material-ui/core"

export default function NumberField({ name, onChange, ...props }) {
  const handleChange = ({ value }) => {
    if (onChange) onChange({ target: { name, value } })
  }

  return (
    <NumberFormat
      inputProps={{
        inputMode: "numeric",
      }}
      name={name}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale={false}
      customInput={TextField}
      isNumericString={true}
      onValueChange={handleChange}
      {...props}
    />
  )
}
