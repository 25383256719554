import styled from "styled-components"
import { lighten } from "polished"

import Paper from "@/components/Paper"

export const StyledPaper = styled(Paper)`
  .MuiTableRow-root .MuiTableCell-root:first-child {
    padding-left: 2rem;
  }

  @media print {
    margin-top: -60px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  > section {
    background-color: ${(props) =>
      lighten(0.01, props.theme.palette.background.default)};
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem 2rem;
    margin-bottom: 0.5rem;

    > div {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 0.2rem;
    }

    &:nth-child(1) {
      background-color: transparent;
      > div {
        flex: inherit;
      }
    }

    &:nth-child(2) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  @media only screen and (max-width: 899px) {
    > section {
      flex-direction: column;
      gap: 1rem;
    }
  }
`

export const Footer = styled.footer`
  margin-top: 2rem;
  padding: 1rem 2rem;
  border-top: 1px solid ${(props) => props.theme.palette.background.default};
  border-bottom: 1px solid ${(props) => props.theme.palette.background.default};

  display: flex;
  justify-content: space-between;
  gap: 1rem;

  > div {
    flex: 1;
  }

  @media only screen and (max-width: 699px) {
    flex-direction: column;
  }
`

export const Toolbar = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 699px) {
    flex-direction: column;
    gap: 1rem;

    > div {
      justify-content: space-between;
    }
  }
`
