import styled from "styled-components"
import InputLabel from "@material-ui/core/InputLabel"

export const Container = styled.div`
  /* align-self: center; */
  padding: 1rem 0;
`

export const StyledInputLabel = styled(InputLabel)`
  margin: 0;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }
`

export const UploadButton = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
`
